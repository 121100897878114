import { TablePaginationConfig } from 'antd/lib/table'
import { BACKEND_API } from './config'

export const API_VERSION = 'v1'

export const LOCALSTORAGE_PREFIX = 'sv::'

export const ACCESS_TOKEN_KEY = 'Authorization'

export const PAGE_SIZE_DEFAULT = 10

export const LOCAL_STORE_DB_NAME = 'localstore-db'
export const COLLECT_KEY = 'local-collect'

export const AUDIO_LISTED_HISTORY_KEY = 'AUDIO_LISTED_HISTORY_KEY'

export const FORMAT_SLUG = '.html'

export const APP_SETTINGS = 'APP_SETTINGS'
export const SEPARATE = ':,:'

export const HTTP_CODES = {
  success: 200,
  notFound: 404,
  unauthorized: 401,
}

export const HIDDEN_ROLE = '__PLAPI__'
export const MESSAGES = {
  updateSuccessfully: 'Cập nhập thành công',
  createSuccessfully: 'Tạo thành công',
  deleteSuccessfully: 'Xóa thành công',
  recoverSuccessfully: 'Khôi phục thành công',
  approveSucessfully: 'Chấp nhận yêu cầu thành công',
  rejectSucessfully: 'Từ chối yêu cầu thành công',
  deleteFailed: 'Xóa thất bại',
  updateFailed: 'Cập nhập thất bại',
  createFailed: 'Tạo thất bại',
  recoverFailed: 'Khôi phục thất bại',
  approveFailed: 'Chấp nhận yêu cầu thất bại',
  rejectFailed: 'Từ chối yêu cầu thất bại',
  fail: 'Có lỗi xảy ra!!!',
}

export const STATUS_REPORT_TICKET = {
  CLOSED: { value: 0, label: 'Closed' },
  OPEN: { value: 1, label: 'Open' },
  IN_PROGRESS: { value: 2, label: 'InProgress' },
  // RESOLVED: { value: 3, label: 'Resolved' },
  ACCEPTED: { value: 3, label: 'Accepted' },
  REJECTED: { value: 4, label: 'Rejected' },
}
export const TABLE_DROPDOWN_OPTIONS = {
  active: {
    value: 0,
    label: 'Danh sách hiện tại',
  },
  deleted: {
    value: 1,
    label: 'Danh sách đã xóa',
  },
}

export const SEO_ROUTING = 'SEO_ROUTING'

export const DEFAULT_PAGINATION: TablePaginationConfig = {
  pageSize: 10 as number,
  total: 10,
}

export enum RecordStatus {
  DELETE = 0,
  ACTIVE = 1,
  LOCK = 2,
}

export const GOLD_PRICE_STATUS = {
  // DELETE = 0,
  // ACTIVE = 1,
  // LOCK = 2,
  //   custom status
  awaitingApproval: {
    value: 3,
    label: 'Đang chờ xác nhận',
  },
  approved: {
    value: 4,
    label: 'Đã xác nhận',
  },
  rejected: {
    value: 5,
    label: 'Đã từ chối',
  },
  verificated: {
    value: 6,
    label: 'Đã verification',
  },
}
export const ROLE_MASTER = 'all'

export const API_PREFIX_V1 = BACKEND_API.restAPI + `/${API_VERSION}`

export const API_ENDPOINTS = {
  uploadFile: API_PREFIX_V1 + '/file',
}

export const SETUP_SCHEDULE_CRONB = 'crawling:SETUP_SCHEDULE_CRONB'
export const SETUP_NEWS_BLOCK = 'sv:SETUP_NEWS_BLOCK'

export const ROUTE_NAMES = {
  // category
  categoryList: 'category-list',
  categoryAdd: 'category-add',
  categoryEdit: 'category-edit',

  // report ticket
  reportTicketList: 'report-ticket',

  // post
  postList: 'post-list',
  postAdd: 'post-add',
  postEdit: 'post-edit',

  // blog
  blogList: 'blog-list',
  blogAdd: 'blog-add',
  blogEdit: 'blog-edit',

  // listener
  candidateListenerList: 'candidate-listener-list',

  // bank question
  bankQuestionList: 'bank-question-list',
  // tag
  tagList: 'tag-list',
  tagAdd: 'tag-add',
  tagEdit: 'tag-edit',

  // comment
  commentList: 'comment-list',
  commentAdd: 'comment-add',
  // banned user
  bannedUserList: 'banned-user-list',

  // course category
  courseCategoryList: 'course-category-list',
  courseCategoryAdd: 'course-category-add',
  courseCategoryEdit: 'course-category-edit',

  // course
  courseList: 'course-list',
  courseAdd: 'course-add',
  courseEdit: 'course-edit',

  moduleList: 'module-list',
  moduleAdd: 'module-add',
  moduleEdit: 'module-edit',

  // submodule
  submoduleList: 'submodule-list',
  submoduleAdd: 'submodule-add',
  submoduleEdit: 'submodule-edit',

  //  room room invitation
  roomInvitationList: 'room-invitation-list',
  roomInvitationAdd: 'room-invitation-add',
  roomInvitationEdit: 'room-invitation-edit',

  // role
  roleList: 'role-list',
  roleAdd: 'role-add',
  roleEdit: 'role-edit',

  // user
  userList: 'user-list',
  userAdd: 'user-add',
  userEdit: 'user-edit',

  // setting
  settingList: 'setting-list',
  settingAdd: 'setting-add',
  settingEdit: 'setting-edit',
  seoSetting: 'seo-setting-page',
  newsSetting: 'news-setting-page',
  trangChuBlockSetting: 'trang-chu-block-setting',
  scheduleSettingList: 'schedule-page-list',

  // Gold price sjc
  goldPriceList: 'gold-price-list',
  myGoldPriceList: 'my-gold-price-list',
  goldPriceAdd: 'gold-price-add',

  // Tỷ giá tham khảo request
  exchangeRefList: 'exchange-ref-list',
  myExchangeRefList: 'my-exchange-ref-list',
  exchangeRefAdd: 'exchange-ref-add',

  // Homepage
  homepageEdit: 'homepage-edit',

  // Membership
  membershipList: 'membership-list',
  membershipAdd: 'membership-add',
  membershipEdit: 'membership-edit',

  // Counsel
  counselFormRegisterList: 'counsel-form-register-list',
  counselFormRegisterEdit: 'counsel-form-register-edit',
}

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const IMAGE_DEFAULT = 'https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg'
export const LIST_OPITION_SOURCE = [
  { label: 'youtube', value: 'youtube' },
  { label: 'vimeo', value: 'vimeo' },
  { label: 'facebook', value: 'facebook' },
  { label: 'dailymotion', value: 'dailymotion' },
  { label: 'twitch', value: 'twitch' },
  { label: 'wistia', value: 'wistia' },
  { label: 'mp4', value: 'mp4' },
  { label: 'webm', value: 'webm' },
  { label: 'mp3', value: 'mp3' },
  { label: 'ogg', value: 'ogg' },
  { label: 'pdf', value: 'pdf' },
]
export const COMMENT_STATUS = {
  APPROVED: 1,
  SPENDING: 2,
  SPAM: 3,
}

export const BREAKPOINT = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
} as const

export const HEADING_SECTION = 'heading-section'

export const ROLE_CODE = {
  listener: 'counsel:listener',
}
