import { Alert, Button, Col, Form, Input, notification, Row, Spin, Typography } from 'antd'
import React, { useEffect } from 'react'
import { MESSAGES } from 'utils/constants'
import { useGetFormRegistersQuery, useUpdateNoteFormRegisterMutation } from '../../../../graphql/autogenerated'

const { Title } = Typography
export function EditFormRegisterPage(props: any) {
  const id = props.match.params.id

  const [form] = Form.useForm()

  const {
    data: formData,
    loading: formLoading,
    error: formError,
  } = useGetFormRegistersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {},
    },
  })

  const handleOnChange = (values: any) => {}

  const [fecthUpdateNoteForm, { data: updateData, loading: updateLoading, error: updateError, called: updateCalled }] = useUpdateNoteFormRegisterMutation({})

  const handleUpdate = async () => {
    try {
      const data = await form.validateFields()

      fecthUpdateNoteForm({
        variables: {
          input: {
            id: id,
            note: data.note,
          },
        },
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (formLoading || formError) return
    form.setFieldsValue({
      name: formData?.__typename,
    })
  }, [formLoading, formError, formData, form])

  useEffect(() => {
    if (!updateCalled || updateLoading) return

    const notiFnc = updateError ? notification.error : notification.success
    notiFnc({
      message: updateError ? MESSAGES.updateFailed : MESSAGES.updateSuccessfully,
    })
  }, [updateCalled, updateError, updateLoading, updateData])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Cập nhật ghi chú</Title>
      {!updateLoading && !!updateError && <Alert type="error" message={updateError.message} banner />}
      <Spin tip="Loading..." spinning={formLoading}>
        <Form layout="vertical" form={form} onFinish={handleUpdate} onFieldsChange={handleOnChange}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item label="Ghi chú" hasFeedback name={'note'} rules={[{ required: true, message: 'Vui lòng nhập ghi chú' }]}>
                <Input placeholder="Thêm ghi chú" autoFocus={true} />
              </Form.Item>
            </Col>
          </Row>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Cập nhật
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </div>
  )
}
