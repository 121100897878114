import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Decimal: any
  JSON: any
  UUID: any
}

export enum AdsSource {
  Linkedin = 'LINKEDIN',
  MobileApp = 'MOBILE_APP',
  PeerNetwork = 'PEER_NETWORK',
  SocialMedia = 'SOCIAL_MEDIA',
  Website = 'WEBSITE',
  WebOrInternetSearch = 'WEB_OR_INTERNET_SEARCH',
}

export type Answer = {
  __typename?: 'Answer'
  id: Scalars['ID']
  isCorrect: Scalars['Boolean']
  question: Question
  questionId: Scalars['String']
  title: Scalars['String']
}

export type BannedUser = {
  __typename?: 'BannedUser'
  banEndDate: Scalars['DateTime']
  banStartDate?: Maybe<Scalars['DateTime']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<User>
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<User>
  deletedByUserId?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  id: Scalars['ID']
  ipAddress?: Maybe<Scalars['String']>
  reason: Scalars['String']
  unbanReason?: Maybe<Scalars['String']>
  unbanRequestDate?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
  userId?: Maybe<Scalars['String']>
}

export type BannedUserEntity = {
  __typename?: 'BannedUserEntity'
  banEndDate: Scalars['DateTime']
  banStartDate?: Maybe<Scalars['DateTime']>
  banned: UserEntity
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  reason: Scalars['String']
  unbanReason?: Maybe<Scalars['String']>
  unbanRequestDate?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BaseCounselHistoryOutput = {
  __typename?: 'BaseCounselHistoryOutput'
  _count: CounselHistoryCount
  client: User
  clientId: Scalars['String']
  counselor: User
  counselorId: Scalars['String']
  createdAt: Scalars['DateTime']
  duration: Scalars['Int']
  endTime: Scalars['DateTime']
  feelings?: Maybe<Array<Scalars['String']>>
  id: Scalars['ID']
  roomId: Scalars['String']
  sessionFeeback?: Maybe<Array<Feedback>>
  startTime: Scalars['DateTime']
  status: CounselHistoryStatus
  updatedAt: Scalars['DateTime']
}

export type Blog = {
  __typename?: 'Blog'
  _count: BlogCount
  blogFavorites?: Maybe<Array<BlogFavorite>>
  blogSeo?: Maybe<BlogMetadata>
  blogViews?: Maybe<BlogView>
  categories?: Maybe<Array<BlogCategory>>
  comments?: Maybe<Array<Comment>>
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<User>
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  publishDate?: Maybe<Scalars['DateTime']>
  slug: Scalars['String']
  status: Scalars['Int']
  tags?: Maybe<Array<Tag>>
  thumbnail?: Maybe<Scalars['String']>
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
  userId?: Maybe<Scalars['String']>
}

export type BlogCategory = {
  __typename?: 'BlogCategory'
  _count: BlogCategoryCount
  blogs?: Maybe<Array<Blog>>
  children?: Maybe<Array<BlogCategory>>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  parent?: Maybe<BlogCategory>
  parentId?: Maybe<Scalars['String']>
  slug: Scalars['String']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

export type BlogCategoryCount = {
  __typename?: 'BlogCategoryCount'
  blogs: Scalars['Int']
  children: Scalars['Int']
}

export type BlogCount = {
  __typename?: 'BlogCount'
  blogFavorites: Scalars['Int']
  categories: Scalars['Int']
  comments: Scalars['Int']
  tags: Scalars['Int']
}

export type BlogEntity = {
  __typename?: 'BlogEntity'
  categories?: Maybe<Array<CategoryEntity>>
  comments: CommentEntity
  content: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  publishDate?: Maybe<Scalars['DateTime']>
  slug: Scalars['String']
  status: Scalars['Float']
  tags?: Maybe<Array<TagEntity>>
  thumbnail?: Maybe<Scalars['String']>
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BlogFavorite = {
  __typename?: 'BlogFavorite'
  blogId: Scalars['String']
  blogs: Blog
  createdAt: Scalars['DateTime']
  createdBy: User
  id: Scalars['ID']
  userId: Scalars['String']
}

export type BlogMetadata = {
  __typename?: 'BlogMetadata'
  blog: Blog
  blogId: Scalars['String']
  createdAt: Scalars['DateTime']
  facebookDescription?: Maybe<Scalars['String']>
  facebookThumbnailImage?: Maybe<Scalars['String']>
  facebookTitle?: Maybe<Scalars['String']>
  id: Scalars['ID']
  keyword?: Maybe<Array<Scalars['String']>>
  seoDescription?: Maybe<Scalars['String']>
  seoThumbnailImage?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  twitterDescription?: Maybe<Scalars['String']>
  twitterThumbnailImage?: Maybe<Scalars['String']>
  twitterTitle?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type BlogToCategory = {
  __typename?: 'BlogToCategory'
  blogs: Array<Blog>
  category?: Maybe<BlogCategory>
  recommendBlogs: Array<Blog>
  tags: Array<Tag>
}

export type BlogView = {
  __typename?: 'BlogView'
  blog?: Maybe<Blog>
  blogId?: Maybe<Scalars['String']>
  count: Scalars['Int']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['DateTime']
}

export type BotMessage = {
  __typename?: 'BotMessage'
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  sender?: Maybe<Scalars['String']>
  thread: Thread
  threadId: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CategoryEntity = {
  __typename?: 'CategoryEntity'
  blogs?: Maybe<Array<BlogEntity>>
  children?: Maybe<Array<CategoryEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
  parentId?: Maybe<Scalars['String']>
  slug: Scalars['String']
  status?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']
  password: Scalars['String']
}

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput'
  success: Scalars['Boolean']
}

export type ChatCompletionOutput = {
  __typename?: 'ChatCompletionOutput'
  choices: Array<ChoiceOutput>
  id: Scalars['String']
  usage: UsageOutPut
}

export type ChoiceOutput = {
  __typename?: 'ChoiceOutput'
  index: Scalars['Float']
  message: MessagesOutput
}

export type Comment = {
  __typename?: 'Comment'
  blog?: Maybe<Blog>
  blogId?: Maybe<Scalars['String']>
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

export type CommentEntity = {
  __typename?: 'CommentEntity'
  blogs: BlogEntity
  content: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  status: Scalars['Float']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type Conversation = {
  __typename?: 'Conversation'
  _count: ConversationCount
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  messages?: Maybe<Array<Message>>
  name?: Maybe<Scalars['String']>
  participants?: Maybe<Array<Participant>>
  updatedAt: Scalars['DateTime']
}

export type ConversationCount = {
  __typename?: 'ConversationCount'
  messages: Scalars['Int']
  participants: Scalars['Int']
}

export type CounselHistory = {
  __typename?: 'CounselHistory'
  _count: CounselHistoryCount
  client: User
  clientId: Scalars['String']
  counselor: User
  counselorId: Scalars['String']
  createdAt: Scalars['DateTime']
  duration: Scalars['Int']
  endTime: Scalars['DateTime']
  feelings?: Maybe<Array<Scalars['String']>>
  id: Scalars['ID']
  roomId: Scalars['String']
  sessionFeeback?: Maybe<Array<Feedback>>
  startTime: Scalars['DateTime']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
}

export type CounselHistoryCount = {
  __typename?: 'CounselHistoryCount'
  sessionFeeback: Scalars['Int']
}

export enum CounselHistoryStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
}

export enum CounselRole {
  Client = 'client',
  Counselor = 'counselor',
}

export type Course = {
  __typename?: 'Course'
  _count: CourseCount
  categories?: Maybe<Array<CourseCategory>>
  comments?: Maybe<Array<CourseComment>>
  courseEnrollments?: Maybe<Array<CourseEnrollment>>
  createdAt: Scalars['DateTime']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<User>
  deletedByUserId?: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  instructor: User
  instructorId: Scalars['String']
  level?: Maybe<LevelCourse>
  modules?: Maybe<Array<Module>>
  overview?: Maybe<Scalars['String']>
  price: Scalars['Decimal']
  publishDate?: Maybe<Scalars['DateTime']>
  slug: Scalars['String']
  thumbnail?: Maybe<Scalars['String']>
  timeLearn?: Maybe<Scalars['Int']>
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CourseCategory = {
  __typename?: 'CourseCategory'
  _count: CourseCategoryCount
  courses?: Maybe<Array<Course>>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  image: Scalars['String']
  name: Scalars['String']
  slug: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CourseCategoryCount = {
  __typename?: 'CourseCategoryCount'
  courses: Scalars['Int']
}

export type CourseCategoryEntity = {
  __typename?: 'CourseCategoryEntity'
  courses?: Maybe<Array<CourseEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description: Scalars['String']
  id?: Maybe<Scalars['String']>
  image: Scalars['String']
  name: Scalars['String']
  slug: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CourseComment = {
  __typename?: 'CourseComment'
  _count: CourseCommentCount
  children?: Maybe<Array<CourseComment>>
  content: Scalars['String']
  course?: Maybe<Course>
  courseId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  parent?: Maybe<CourseComment>
  parentId?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
}

export type CourseCommentCount = {
  __typename?: 'CourseCommentCount'
  children: Scalars['Int']
}

export type CourseCount = {
  __typename?: 'CourseCount'
  categories: Scalars['Int']
  comments: Scalars['Int']
  courseEnrollments: Scalars['Int']
  modules: Scalars['Int']
}

export type CourseEnrollment = {
  __typename?: 'CourseEnrollment'
  completedAt?: Maybe<Scalars['DateTime']>
  course: Course
  courseId: Scalars['String']
  createdAt: Scalars['DateTime']
  deletedBy?: Maybe<User>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  rating?: Maybe<Scalars['Int']>
  status?: Maybe<EnrollmentStatus>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type CourseEntity = {
  __typename?: 'CourseEntity'
  categories?: Maybe<Array<CourseCategoryEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  enrollments: Array<EnrollmentEntity>
  id?: Maybe<Scalars['String']>
  instructor: UserEntity
  level?: Maybe<LevelCourse>
  modules: Array<Module>
  overview?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  publishDate?: Maybe<Scalars['DateTime']>
  slug?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  timeLearn?: Maybe<Scalars['Float']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CreateAnswerItemInput = {
  isCorrect?: Maybe<Scalars['Boolean']>
  title: Scalars['String']
}

export type CreateBannedUserInput = {
  banEndDate: Scalars['String']
  banStartDate?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  reason: Scalars['String']
  unbanReason?: Maybe<Scalars['String']>
  unbanRequestDate?: Maybe<Scalars['DateTime']>
  userId: Scalars['String']
}

export type CreateBannedUserOutput = {
  __typename?: 'CreateBannedUserOutput'
  id: Scalars['String']
}

export type CreateBlogInput = {
  categories?: Maybe<Array<RelationshipInput>>
  content: Scalars['String']
  description?: Maybe<Scalars['String']>
  facebookDescription?: Maybe<Scalars['String']>
  facebookThumbnailImage?: Maybe<Scalars['String']>
  facebookTitle?: Maybe<Scalars['String']>
  keyword?: Maybe<Array<Scalars['String']>>
  publishDate?: Maybe<Scalars['DateTime']>
  seoDescription?: Maybe<Scalars['String']>
  seoThumbnailImage?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Float']>
  tags?: Maybe<Array<CreateBlogTagInput>>
  thumbnail?: Maybe<Scalars['String']>
  title: Scalars['String']
  twitterDescription?: Maybe<Scalars['String']>
  twitterThumbnailImage?: Maybe<Scalars['String']>
  twitterTitle?: Maybe<Scalars['String']>
}

export type CreateBlogOutput = {
  __typename?: 'CreateBlogOutput'
  id: Scalars['String']
}

export type CreateBlogTagInput = {
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type CreateCategoryInput = {
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
  parentId?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type CreateCategoryOutput = {
  __typename?: 'CreateCategoryOutput'
  id: Scalars['String']
}

export type CreateCommentInput = {
  blogId: Scalars['String']
  content: Scalars['String']
  status?: Maybe<Scalars['Float']>
}

export type CreateCommentOutput = {
  __typename?: 'CreateCommentOutput'
  id: Scalars['String']
}

export type CreateCourseCategoryInput = {
  description?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug?: Maybe<Scalars['String']>
}

export type CreateCourseCategoryOutput = {
  __typename?: 'CreateCourseCategoryOutput'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CreateCourseCommentInput = {
  content: Scalars['String']
  courseId: Scalars['String']
  parentId?: Maybe<Scalars['String']>
}

export type CreateCourseCommentOutput = {
  __typename?: 'CreateCourseCommentOutput'
  id: Scalars['String']
}

export type CreateCourseEnrollmentInput = {
  courseId: Scalars['UUID']
}

export type CreateCourseEnrollmentOutput = {
  __typename?: 'CreateCourseEnrollmentOutput'
  data: CourseEnrollment
}

export type CreateCourseInput = {
  categories?: Maybe<Array<RelationshipInput>>
  description?: Maybe<Scalars['String']>
  level?: Maybe<LevelCourse>
  overview?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  publishDate?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  timeLearn?: Maybe<Scalars['Float']>
  title: Scalars['String']
}

export type CreateCourseOutput = {
  __typename?: 'CreateCourseOutput'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type CreateFormRegisterInput = {
  ethnicity: Scalars['String']
  expectResult: Scalars['String']
  expectedTime: Scalars['String']
  familyPosition: Scalars['String']
  issueDescription: Scalars['String']
  relativePhoneNumber: Scalars['String']
  religion: Scalars['String']
}

export type CreateFormRegisterOutput = {
  __typename?: 'CreateFormRegisterOutput'
  data?: Maybe<FormCounsel>
}

export type CreateListenerInput = {
  userListenerId: Scalars['UUID']
}

export type CreateListenerOutput = {
  __typename?: 'CreateListenerOutput'
  data: Listener
}

export type CreateListenerRegisterInput = {
  adsSource: AdsSource
  country: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  reason: Scalars['String']
  status?: Maybe<ListenerRegisterStatus>
}

export type CreateListenerRegisterOutput = {
  __typename?: 'CreateListenerRegisterOutput'
  id: Scalars['String']
}

export type CreateModuleInput = {
  courseId: Scalars['UUID']
  description: Scalars['String']
  order?: Maybe<Scalars['Float']>
  slug: Scalars['String']
  title: Scalars['String']
}

export type CreateModuleOutput = {
  __typename?: 'CreateModuleOutput'
  data: Module
}

export type CreateOrDeleteBlogFavoriteInput = {
  blogId: Scalars['String']
}

export type CreateOrDeleteBlogFavoriteOutput = {
  __typename?: 'CreateOrDeleteBlogFavoriteOutput'
  id: Scalars['String']
}

export type CreateQuestionInput = {
  answers: Array<CreateAnswerItemInput>
  moduleId: Scalars['UUID']
  title: Scalars['String']
  type: QuestionType
}

export type CreateQuestionOutput = {
  __typename?: 'CreateQuestionOutput'
  data: Question
}

export type CreateQuizInput = {
  moduleId: Scalars['UUID']
  passGrade: Scalars['Float']
  questionsId: Array<Scalars['UUID']>
  status?: Maybe<QuizStatus>
  title: Scalars['String']
}

export type CreateQuizOutput = {
  __typename?: 'CreateQuizOutput'
  data: Quiz
}

export type CreateReportTicketInput = {
  description: Scalars['String']
  priority?: Maybe<Scalars['Float']>
  reportedUserId?: Maybe<Scalars['String']>
  status?: Maybe<ReportTicketStatus>
  subject: Scalars['String']
}

export type CreateReportTicketOutput = {
  __typename?: 'CreateReportTicketOutput'
  id: Scalars['String']
}

export type CreateRoleInput = {
  code?: Maybe<Scalars['String']>
  isDefault?: Maybe<Scalars['Boolean']>
  scp: Array<Scalars['String']>
  title: Scalars['String']
}

export type CreateRoleOutput = {
  __typename?: 'CreateRoleOutput'
  id: Scalars['String']
}

export type CreateRoomInput = {
  capacity: Scalars['Float']
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isAnonymous?: Maybe<Scalars['Boolean']>
  language: Scalars['String']
  lifeProblems: Array<Scalars['String']>
  moods?: Maybe<Array<Scalars['String']>>
  roomType: Scalars['Float']
  tags: Array<Scalars['String']>
  topic: Scalars['String']
}

export type CreateRoomInvitationInput = {
  ownerRoomId: Scalars['UUID']
  participants: Array<RelationshipInput>
  roomType: RoomType
  slug: Scalars['String']
  startTime: Scalars['DateTime']
  title: Scalars['String']
}

export type CreateRoomInvitationOutput = {
  __typename?: 'CreateRoomInvitationOutput'
  data: RoomInvitation
}

export type CreateRoomOutput = {
  __typename?: 'CreateRoomOutput'
  id: Scalars['String']
}

export type CreateSubModuleInput = {
  description?: Maybe<Scalars['String']>
  moduleId: Scalars['UUID']
  order?: Maybe<Scalars['Float']>
  richText?: Maybe<CreateSubModuleRichTextInput>
  slug: Scalars['String']
  title: Scalars['String']
  type: SubModuleType
  video?: Maybe<CreateSubModuleVideoInput>
}

export type CreateSubModuleOutput = {
  __typename?: 'CreateSubModuleOutput'
  data: SubModule
}

export type CreateSubModuleRichTextInput = {
  content: Scalars['String']
  title: Scalars['String']
}

export type CreateSubModuleVideoInput = {
  duration?: Maybe<Scalars['Float']>
  source: UrlSource
  title: Scalars['String']
  url: Scalars['String']
}

export type CreateTagInput = {
  name: Scalars['String']
  slug?: Maybe<Scalars['String']>
}

export type CreateTagOutput = {
  __typename?: 'CreateTagOutput'
  id: Scalars['String']
}

export type DeleteBannedUserInput = {
  id: Scalars['UUID']
}

export type DeleteBannedUserOutput = {
  __typename?: 'DeleteBannedUserOutput'
  id: Scalars['String']
}

export type DeleteBlogInput = {
  id: Scalars['UUID']
}

export type DeleteBlogOutput = {
  __typename?: 'DeleteBlogOutput'
  id: Scalars['String']
}

export type DeleteCategoryInput = {
  id: Scalars['UUID']
}

export type DeleteCategoryOutput = {
  __typename?: 'DeleteCategoryOutput'
  id: Scalars['String']
}

export type DeleteCommentInput = {
  id: Scalars['UUID']
}

export type DeleteCommentOutput = {
  __typename?: 'DeleteCommentOutput'
  id: Scalars['String']
}

export type DeleteCourseCategoryInput = {
  id: Scalars['UUID']
}

export type DeleteCourseCategoryOutput = {
  __typename?: 'DeleteCourseCategoryOutput'
  id: Scalars['String']
}

export type DeleteCourseCommentInput = {
  id: Scalars['UUID']
}

export type DeleteCourseCommentOutput = {
  __typename?: 'DeleteCourseCommentOutput'
  id: Scalars['String']
}

export type DeleteCourseEnrollmentInput = {
  __typename?: 'DeleteCourseEnrollmentInput'
  data: CourseEnrollment
}

export type DeleteCourseEnrollmentOutput = {
  id: Scalars['UUID']
}

export type DeleteCourseInput = {
  id: Scalars['UUID']
}

export type DeleteCourseOutput = {
  __typename?: 'DeleteCourseOutput'
  id: Scalars['String']
}

export type DeleteFollowerInput = {
  userId: Scalars['UUID']
}

export type DeleteFollowerOutput = {
  __typename?: 'DeleteFollowerOutput'
  data?: Maybe<Friendship>
}

export type DeleteListenerInput = {
  id: Scalars['UUID']
}

export type DeleteListenerOutput = {
  __typename?: 'DeleteListenerOutput'
  data: Listener
}

export type DeleteListenerRegisterInput = {
  id: Scalars['UUID']
}

export type DeleteListenerRegisterOutput = {
  __typename?: 'DeleteListenerRegisterOutput'
  id: Scalars['String']
}

export type DeleteModuleInput = {
  id?: Maybe<Scalars['UUID']>
}

export type DeleteModuleOutput = {
  __typename?: 'DeleteModuleOutput'
  data: Module
}

export type DeleteQuestionInput = {
  id: Scalars['UUID']
}

export type DeleteQuestionOutput = {
  __typename?: 'DeleteQuestionOutput'
  data: Question
}

export type DeleteQuestionsInput = {
  ids: Array<Scalars['UUID']>
}

export type DeleteQuestionsOutput = {
  __typename?: 'DeleteQuestionsOutput'
  data: Array<Question>
}

export type DeleteQuizInput = {
  id: Scalars['UUID']
}

export type DeleteQuizOutput = {
  __typename?: 'DeleteQuizOutput'
  data: Quiz
}

export type DeleteReportTicketInput = {
  id: Scalars['UUID']
}

export type DeleteReportTicketOutput = {
  __typename?: 'DeleteReportTicketOutput'
  id: Scalars['String']
}

export type DeleteRoleInput = {
  id: Scalars['UUID']
}

export type DeleteRoleOutput = {
  __typename?: 'DeleteRoleOutput'
  id: Scalars['String']
}

export type DeleteRoomInput = {
  id: Scalars['String']
}

export type DeleteRoomInvitationInput = {
  id: Scalars['UUID']
}

export type DeleteRoomInvitationOutput = {
  __typename?: 'DeleteRoomInvitationOutput'
  data: RoomInvitation
}

export type DeleteRoomOutput = {
  __typename?: 'DeleteRoomOutput'
  id: Scalars['String']
}

export type DeleteSubModuleInput = {
  id: Scalars['UUID']
}

export type DeleteSubModuleOutput = {
  __typename?: 'DeleteSubModuleOutput'
  data: SubModule
}

export type DeleteTagInput = {
  id: Scalars['UUID']
}

export type DeleteTagOutput = {
  __typename?: 'DeleteTagOutput'
  id: Scalars['String']
}

export type DeleteThreadInput = {
  id: Scalars['UUID']
}

export type DeleteThreadOutput = {
  __typename?: 'DeleteThreadOutput'
  data?: Maybe<Thread>
}

export type DeleteUserInput = {
  id: Scalars['UUID']
}

export type DeleteUserOutput = {
  __typename?: 'DeleteUserOutput'
  id: Scalars['String']
}

export type DestroyRoomInvitationInput = {
  slug: Scalars['UUID']
}

export type DestroyRoomInvitationOutput = {
  __typename?: 'DestroyRoomInvitationOutput'
  data: RoomInvitation
}

export type EnrollmentEntity = {
  __typename?: 'EnrollmentEntity'
  course: CourseEntity
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
  user: UserEntity
}

export enum EnrollmentStatus {
  Complete = 'COMPLETE',
  InComplete = 'IN_COMPLETE',
}

export type Feedback = {
  __typename?: 'Feedback'
  callHistoryId: Scalars['String']
  counselHistory: CounselHistory
  createdAt: Scalars['DateTime']
  feedbackOrNote: Scalars['String']
  id: Scalars['ID']
  improvementSuggestions?: Maybe<Scalars['String']>
  rating: Scalars['Int']
  sessionFeedbackTags?: Maybe<Array<Scalars['String']>>
  sessionReferenceSatisfaction?: Maybe<Array<Scalars['Int']>>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
  waitTimeForSessionEntry: Scalars['Int']
}

export type FieldSortInput = {
  direction?: Maybe<Scalars['String']>
  field?: Maybe<Scalars['String']>
  nulls?: Maybe<SortNulls>
}

export type File = {
  __typename?: 'File'
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<User>
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  key: Scalars['String']
  updatedAt: Scalars['DateTime']
  url: Scalars['String']
}

export type FileEntity = {
  __typename?: 'FileEntity'
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  key: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
  url: Scalars['String']
}

export type FindOrCreateTagInput = {
  name: Scalars['String']
  slug?: Maybe<Scalars['String']>
}

export type FindOrCreateTagOutput = {
  __typename?: 'FindOrCreateTagOutput'
  id: Scalars['String']
}

export type FollowFriendData = {
  __typename?: 'FollowFriendData'
  id: Scalars['ID']
}

export type FollowFriendInput = {
  userId: Scalars['UUID']
}

export type FollowFriendOutput = {
  __typename?: 'FollowFriendOutput'
  data: FollowFriendData
}

export type ForgotPasswordInput = {
  email: Scalars['String']
}

export type ForgotPasswordOutput = {
  __typename?: 'ForgotPasswordOutput'
  success: Scalars['Boolean']
}

export type FormCounsel = {
  __typename?: 'FormCounsel'
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deletedBy?: Maybe<Scalars['String']>
  ethnicity: Scalars['String']
  expectResult: Scalars['String']
  expectedTime: Scalars['String']
  familyPosition: Scalars['String']
  id: Scalars['ID']
  issueDescription: Scalars['String']
  note?: Maybe<Scalars['String']>
  relativePhoneNumber: Scalars['String']
  religion: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type Friendship = {
  __typename?: 'Friendship'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['DateTime']
  user1: User
  user1Accepted: Scalars['Boolean']
  user1Id: Scalars['String']
  user2: User
  user2Accepted: Scalars['Boolean']
  user2Id: Scalars['String']
}

export type GetBannedUserInput = {
  id: Scalars['String']
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetBannedUserOutput = {
  __typename?: 'GetBannedUserOutput'
  banEndDate: Scalars['DateTime']
  banStartDate?: Maybe<Scalars['DateTime']>
  banned: UserEntity
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  ipAddress?: Maybe<Scalars['String']>
  reason: Scalars['String']
  unbanReason?: Maybe<Scalars['String']>
  unbanRequestDate?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GetBannedUsersInput = {
  filter?: Maybe<FieldSortInput>
  pagination: PaginationInput
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetBannedUsersOutput = {
  __typename?: 'GetBannedUsersOutput'
  data: Array<BannedUserEntity>
  pagination: Pagination
}

export type GetBlogFavoritesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetBlogFavoritesOutput = {
  __typename?: 'GetBlogFavoritesOutput'
  data: Array<BlogFavorite>
  pagination: Pagination
}

export type GetBlogHomePageInput = {
  data?: Maybe<Scalars['String']>
  pagination?: Maybe<PaginationInput>
}

export type GetBlogHomePageOutput = {
  __typename?: 'GetBlogHomePageOutput'
  blogs: Scalars['JSON']
  categories: Scalars['JSON']
  pagination: Pagination
  recommendBlogs: Scalars['JSON']
  tags: Scalars['JSON']
}

export type GetBlogInput = {
  id?: Maybe<Scalars['UUID']>
  slug?: Maybe<Scalars['String']>
}

export type GetBlogOutput = {
  __typename?: 'GetBlogOutput'
  data: Blog
}

export type GetBlogsInput = {
  categoryIds?: Maybe<Array<Scalars['String']>>
  categorySlugs?: Maybe<Array<Scalars['String']>>
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetBlogsOutput = {
  __typename?: 'GetBlogsOutput'
  data: Array<Blog>
  pagination: Pagination
}

export type GetBySlugInput = {
  slug: Scalars['String']
}

export type GetCategoriesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetCategoriesOutput = {
  __typename?: 'GetCategoriesOutput'
  data: Array<CategoryEntity>
  pagination: Pagination
}

export type GetCategoryBlogInput = {
  pagination?: Maybe<PaginationInput>
  slug?: Maybe<Scalars['String']>
}

export type GetCategoryBlogOutput = {
  __typename?: 'GetCategoryBlogOutput'
  data: BlogToCategory
  pagination: Pagination
}

export type GetCategoryInput = {
  id?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type GetCategoryOutput = {
  __typename?: 'GetCategoryOutput'
  blogs?: Maybe<Array<BlogEntity>>
  children?: Maybe<Array<CategoryEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
  parentId?: Maybe<Scalars['String']>
  slug: Scalars['String']
  status?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GetCommentInput = {
  id: Scalars['String']
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetCommentItem = {
  __typename?: 'GetCommentItem'
  blog?: Maybe<Blog>
  blogId?: Maybe<Scalars['String']>
  content: Scalars['String']
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
}

export type GetCommentOutput = {
  __typename?: 'GetCommentOutput'
  blogs: BlogEntity
  content: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  status: Scalars['Float']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GetCommentsInput = {
  blogId?: Maybe<Scalars['String']>
  filter?: Maybe<FieldSortInput>
  pagination: PaginationInput
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetCommentsOutput = {
  __typename?: 'GetCommentsOutput'
  data: Array<GetCommentItem>
  pagination: Pagination
}

export type GetConversationIdInput = {
  userId: Scalars['UUID']
}

export type GetConversationIdOutput = {
  __typename?: 'GetConversationIdOutput'
  conversationId: Scalars['UUID']
}

export type GetConversationMessagesInput = {
  conversationId: Scalars['UUID']
  pagination?: Maybe<PaginationInput>
}

export type GetConversationMessagesOutput = {
  __typename?: 'GetConversationMessagesOutput'
  data: Array<Message>
}

export type GetConversationsInput = {
  isPreview?: Maybe<Scalars['Boolean']>
  pagination?: Maybe<PaginationInput>
}

export type GetConversationsOutput = {
  __typename?: 'GetConversationsOutput'
  data?: Maybe<Array<Conversation>>
}

export type GetCounselHistoriesInput = {
  dataFor?: Maybe<CounselRole>
  filter?: Maybe<Scalars['JSON']>
  isMyHistory?: Maybe<Scalars['Boolean']>
}

export type GetCounselHistoriesOutput = {
  __typename?: 'GetCounselHistoriesOutput'
  data: Array<BaseCounselHistoryOutput>
}

export type GetCounselHistoryInput = {
  id?: Maybe<Scalars['UUID']>
  roomId?: Maybe<Scalars['UUID']>
}

export type GetCounselHistoryOutput = {
  __typename?: 'GetCounselHistoryOutput'
  data: BaseCounselHistoryOutput
}

export type GetCourseCategoriesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetCourseCategoriesOutput = {
  __typename?: 'GetCourseCategoriesOutput'
  data: Array<CourseCategoryEntity>
  pagination: Pagination
}

export type GetCourseCategoryInput = {
  id?: Maybe<Scalars['UUID']>
  slug?: Maybe<Scalars['String']>
}

export type GetCourseCategoryOutput = {
  __typename?: 'GetCourseCategoryOutput'
  data: CourseCategoryEntity
}

export type GetCourseCommentInput = {
  id: Scalars['String']
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetCourseCommentOutput = {
  __typename?: 'GetCourseCommentOutput'
  _count: CourseCommentCount
  children?: Maybe<Array<CourseComment>>
  content: Scalars['String']
  course?: Maybe<Course>
  courseId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  parent?: Maybe<CourseComment>
  parentId?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
}

export type GetCourseCommentsInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetCourseCommentsOutput = {
  __typename?: 'GetCourseCommentsOutput'
  data: Array<CourseComment>
  pagination: Pagination
}

export type GetCourseEnrollmentInput = {
  id: Scalars['UUID']
}

export type GetCourseEnrollmentOutput = {
  __typename?: 'GetCourseEnrollmentOutput'
  data: CourseEnrollment
}

export type GetCourseEnrollmentsInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  status?: Maybe<Array<EnrollmentStatus>>
}

export type GetCourseEnrollmentsOutput = {
  __typename?: 'GetCourseEnrollmentsOutput'
  data: Array<CourseEnrollment>
  pagination: Pagination
}

export type GetCourseInput = {
  id?: Maybe<Scalars['UUID']>
  slug?: Maybe<Scalars['String']>
}

export type GetCourseOutput = {
  __typename?: 'GetCourseOutput'
  data: Course
}

export type GetCoursesInput = {
  categoryIds?: Maybe<Array<Scalars['String']>>
  categorySlugs?: Maybe<Array<Scalars['String']>>
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetCoursesOutput = {
  __typename?: 'GetCoursesOutput'
  data: Array<CourseEntity>
  pagination: Pagination
}

export type GetDeletedListenersRegisterInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetDeletedListenersRegisterOutput = {
  __typename?: 'GetDeletedListenersRegisterOutput'
  data: Array<GetListenerRegisterOutput>
  pagination: Pagination
}

export type GetDeletedRolesOutput = {
  __typename?: 'GetDeletedRolesOutput'
  code?: Maybe<Scalars['String']>
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  scp: Array<Scalars['String']>
  title: Scalars['String']
}

export type GetFeedbacksInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetFeedbacksOutput = {
  __typename?: 'GetFeedbacksOutput'
  data: Array<Feedback>
  pagination: Pagination
}

export type GetFilesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetFilesOutput = {
  __typename?: 'GetFilesOutput'
  data: FileEntity
}

export type GetFormRegisterInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetFormRegisterOutput = {
  __typename?: 'GetFormRegisterOutput'
  data?: Maybe<Array<FormCounsel>>
}

export type GetListenerInput = {
  id: Scalars['UUID']
}

export type GetListenerOutput = {
  __typename?: 'GetListenerOutput'
  data: Listener
}

export type GetListenerRegisterInput = {
  id: Scalars['UUID']
}

export type GetListenerRegisterOutput = {
  __typename?: 'GetListenerRegisterOutput'
  adsSource: AdsSource
  createdAt: Scalars['DateTime']
  createdBy: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  reason: Scalars['String']
  status: ListenerRegisterStatus
  updatedAt: Scalars['DateTime']
  user: User
}

export type GetListenersInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetListenersOutput = {
  __typename?: 'GetListenersOutput'
  data: Array<Listener>
  pagination: Pagination
}

export type GetListenersRegisterInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetListenersRegisterOutput = {
  __typename?: 'GetListenersRegisterOutput'
  data: Array<GetListenerRegisterOutput>
  pagination: Pagination
}

export type GetModuleInput = {
  id: Scalars['UUID']
}

export type GetModuleOutput = {
  __typename?: 'GetModuleOutput'
  data: Module
}

export type GetModulesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetModulesOutput = {
  __typename?: 'GetModulesOutput'
  data: Array<Module>
  pagination: Pagination
}

export type GetMyFollowersInput = {
  pagination?: Maybe<PaginationInput>
}

export type GetMyFollowersOutput = {
  __typename?: 'GetMyFollowersOutput'
  data: Array<User>
  pagination: Pagination
}

export type GetMyFollowingsOutput = {
  __typename?: 'GetMyFollowingsOutput'
  data: Array<User>
  pagination: Pagination
}

export type GetMyFriendsInput = {
  pagination?: Maybe<PaginationInput>
}

export type GetMyFriendsOutput = {
  __typename?: 'GetMyFriendsOutput'
  data: Array<User>
  pagination: Pagination
}

export type GetQuestionInput = {
  id: Scalars['UUID']
}

export type GetQuestionOutput = {
  __typename?: 'GetQuestionOutput'
  data: Question
}

export type GetQuestionsInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetQuestionsOutput = {
  __typename?: 'GetQuestionsOutput'
  data: Array<Question>
  pagination: Pagination
}

export type GetQuizInput = {
  id: Scalars['UUID']
}

export type GetQuizOutput = {
  __typename?: 'GetQuizOutput'
  data: Quiz
}

export type GetQuizzesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetQuizzesOutput = {
  __typename?: 'GetQuizzesOutput'
  data: Array<Quiz>
  pagination: Pagination
}

export type GetReportTicketInput = {
  id: Scalars['String']
}

export type GetReportTicketOutput = {
  __typename?: 'GetReportTicketOutput'
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  description: Scalars['String']
  id?: Maybe<Scalars['String']>
  priority: Scalars['Float']
  reportedUser?: Maybe<UserEntity>
  reporter?: Maybe<UserEntity>
  status: ReportTicketStatus
  subject: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GetReportTicketsInput = {
  filter?: Maybe<FieldSortInput>
  pagination: PaginationInput
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetReportTicketsOutput = {
  __typename?: 'GetReportTicketsOutput'
  data: Array<ReportTicket>
  pagination: Pagination
}

export type GetRoleInput = {
  id: Scalars['String']
}

export type GetRoleOutput = {
  __typename?: 'GetRoleOutput'
  code?: Maybe<Scalars['String']>
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  scp: Array<Scalars['String']>
  title: Scalars['String']
}

export type GetRolesOutput = {
  __typename?: 'GetRolesOutput'
  code?: Maybe<Scalars['String']>
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  scp: Array<Scalars['String']>
  title: Scalars['String']
}

export type GetRoomInput = {
  id: Scalars['String']
}

export type GetRoomInvitationInput = {
  id: Scalars['UUID']
}

export type GetRoomInvitationOutput = {
  __typename?: 'GetRoomInvitationOutput'
  data: RoomInvitation
}

export type GetRoomInvitationsInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetRoomInvitationsOutput = {
  __typename?: 'GetRoomInvitationsOutput'
  data: Array<RoomInvitation>
  pagination: Pagination
}

export type GetRoomOutput = {
  __typename?: 'GetRoomOutput'
  data: RoomOutput
}

export type GetRoomsInput = {
  pagination: PaginationInput
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetRoomsOutput = {
  __typename?: 'GetRoomsOutput'
  data: Array<RoomOutput>
  pagination: Pagination
}

export type GetSubModuleInput = {
  id: Scalars['UUID']
}

export type GetSubModuleOutput = {
  __typename?: 'GetSubModuleOutput'
  data: SubModule
}

export type GetSubModulesInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
}

export type GetSubModulesOutput = {
  __typename?: 'GetSubModulesOutput'
  data: Array<SubModule>
  pagination: Pagination
}

export type GetTagInput = {
  id?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type GetTagOutput = {
  __typename?: 'GetTagOutput'
  blogs?: Maybe<Array<BlogEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GetTagsInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetTagsOutput = {
  __typename?: 'GetTagsOutput'
  data: Array<TagEntity>
  pagination: Pagination
}

export type GetThreadInput = {
  id: Scalars['UUID']
  userId?: Maybe<Scalars['UUID']>
}

export type GetThreadOutput = {
  __typename?: 'GetThreadOutput'
  data?: Maybe<Thread>
}

export type GetUserInput = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  phone?: Maybe<Scalars['String']>
}

export type GetUserOutput = {
  __typename?: 'GetUserOutput'
  data: User
}

export type GetUserProfileOutput = {
  __typename?: 'GetUserProfileOutput'
  Thread?: Maybe<Array<Thread>>
  UserOtp?: Maybe<UserOtp>
  _count: UserCount
  address?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  bannedMe?: Maybe<Array<BannedUser>>
  blogFavorites?: Maybe<Array<BlogFavorite>>
  blogs?: Maybe<Array<Blog>>
  city?: Maybe<Scalars['String']>
  clientSessions?: Maybe<Array<CounselHistory>>
  conversations?: Maybe<Array<Participant>>
  counselorSessions?: Maybe<Array<CounselHistory>>
  country?: Maybe<Scalars['String']>
  countryIsoCode?: Maybe<Scalars['String']>
  courseComment?: Maybe<Array<CourseComment>>
  courseEnrollments?: Maybe<Array<CourseEnrollment>>
  courses?: Maybe<Array<Course>>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  createdTickets?: Maybe<Array<ReportTicket>>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBanned?: Maybe<Array<BannedUser>>
  deletedByUserId?: Maybe<Scalars['String']>
  deletedCourseEnrollments?: Maybe<Array<CourseEnrollment>>
  deletedCourses?: Maybe<Array<Course>>
  email: Scalars['String']
  emailVerified?: Maybe<Scalars['Boolean']>
  feedbacks?: Maybe<Array<Feedback>>
  files?: Maybe<Array<File>>
  firstName?: Maybe<Scalars['String']>
  formCounsel?: Maybe<Array<FormCounsel>>
  fullName?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isComplete?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  liscensed?: Maybe<Scalars['String']>
  listener?: Maybe<Array<Listener>>
  listenerRegister?: Maybe<Array<ListenerRegister>>
  messagesSent?: Maybe<Array<Message>>
  myRequestedFriends?: Maybe<Array<Friendship>>
  ownerRooms?: Maybe<Array<RoomInvitation>>
  participantRooms?: Maybe<Array<RoomInvitation>>
  password: Scalars['String']
  phone?: Maybe<Scalars['String']>
  privilege?: Maybe<Array<Privilege>>
  questions?: Maybe<Array<Question>>
  quizs?: Maybe<Array<Quiz>>
  reportedTickets?: Maybe<Array<ReportTicket>>
  requestBanned?: Maybe<Array<BannedUser>>
  requestedFriends?: Maybe<Array<Friendship>>
  roles?: Maybe<Array<Role>>
  rooms?: Maybe<Array<RoomInvitation>>
  state?: Maybe<Scalars['String']>
  stateIsoCode?: Maybe<Scalars['String']>
  status: Scalars['Int']
  subModule?: Maybe<Array<SubModule>>
  updatedAt: Scalars['DateTime']
  userListener?: Maybe<Array<Listener>>
  userProfile?: Maybe<UserProfile>
}

export type GetUsersInput = {
  filter?: Maybe<FieldSortInput>
  pagination?: Maybe<PaginationInput>
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetUsersOutput = {
  __typename?: 'GetUsersOutput'
  data: Array<UserEntity>
  pagination: Pagination
}

export enum LevelCourse {
  Advance = 'Advance',
  AllLevel = 'AllLevel',
  Begin = 'Begin',
  Medium = 'Medium',
}

export type Listener = {
  __typename?: 'Listener'
  createdAt: Scalars['DateTime']
  createdBy: User
  createdByUserId: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
  userListener: User
  userListenerId: Scalars['String']
}

export type ListenerRegister = {
  __typename?: 'ListenerRegister'
  adsSource: Scalars['String']
  createdAt: Scalars['DateTime']
  createdBy: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  reason: Scalars['String']
  status: Scalars['Int']
  updatedAt: Scalars['DateTime']
  user: User
}

export enum ListenerRegisterStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  PendingConfirmation = 'PendingConfirmation',
  Processing = 'Processing',
  Reject = 'Reject',
}

export enum ListenerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type MeOutput = {
  __typename?: 'MeOutput'
  avatar: Scalars['String']
  email: Scalars['String']
  exp: Scalars['Float']
  id: Scalars['String']
  name: Scalars['String']
  privilege: Array<Privilege>
  roles: Array<Scalars['String']>
  scp: Array<Scalars['String']>
  sub: Scalars['String']
  username: Scalars['String']
}

export type Message = {
  __typename?: 'Message'
  content: Scalars['JSON']
  conversation: Conversation
  conversationId: Scalars['String']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  ref?: Maybe<Scalars['String']>
  sender: User
  senderId: Scalars['String']
  status: Scalars['Int']
  type: Scalars['Int']
  updatedAt: Scalars['DateTime']
  viewers?: Maybe<Scalars['JSON']>
}

export type MessageInput = {
  content: Scalars['String']
  role: Scalars['String']
}

export type MessagesOutput = {
  __typename?: 'MessagesOutput'
  content: Scalars['String']
  role: Scalars['String']
}

export type Module = {
  __typename?: 'Module'
  _count: ModuleCount
  course?: Maybe<Course>
  courseId?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  order?: Maybe<Scalars['Int']>
  questions?: Maybe<Array<Question>>
  quizzes?: Maybe<Array<Quiz>>
  slug: Scalars['String']
  status?: Maybe<StatusModule>
  subModules?: Maybe<Array<SubModule>>
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type ModuleCount = {
  __typename?: 'ModuleCount'
  questions: Scalars['Int']
  quizzes: Scalars['Int']
  subModules: Scalars['Int']
}

export type Mutation = {
  __typename?: 'Mutation'
  changePassword: ChangePasswordOutput
  createBannedUser: CreateBannedUserOutput
  createBlog: CreateBlogOutput
  createCategory: CreateCategoryOutput
  createComment: CreateCommentOutput
  createCourse: CreateCourseOutput
  createCourseCategory: CreateCourseCategoryOutput
  createCourseComment: CreateCourseCommentOutput
  createCourseEnrollment: CreateCourseEnrollmentOutput
  createFormRegister: CreateFormRegisterOutput
  createListener: CreateListenerOutput
  createListenerRegister: CreateListenerRegisterOutput
  createModule: CreateModuleOutput
  createOrDeleteBlogFavorite: CreateOrDeleteBlogFavoriteOutput
  createQuestion: CreateQuestionOutput
  createQuiz: CreateQuizOutput
  createReportTicket: CreateReportTicketOutput
  createRole: CreateRoleOutput
  createRoom: CreateRoomOutput
  createRoomInvitation: CreateRoomInvitationOutput
  createSubModule: CreateSubModuleOutput
  createTag: CreateTagOutput
  deleteBannedUser: DeleteBannedUserOutput
  deleteBlog: DeleteBlogOutput
  deleteCategory: DeleteCategoryOutput
  deleteComment: DeleteCommentOutput
  deleteCourse: DeleteCourseOutput
  deleteCourseCategory: DeleteCourseCategoryOutput
  deleteCourseComment: DeleteCourseCommentOutput
  deleteCourseEnrollment: DeleteCourseEnrollmentInput
  deleteFollower: DeleteFollowerOutput
  deleteListener: DeleteListenerOutput
  deleteListenerRegister: DeleteListenerRegisterOutput
  deleteModule: DeleteModuleOutput
  deleteQuestion: DeleteQuestionOutput
  deleteQuestions: DeleteQuestionsOutput
  deleteQuiz: DeleteQuizOutput
  deleteReportTicket: DeleteReportTicketOutput
  deleteRole: DeleteRoleOutput
  deleteRoom: DeleteRoomOutput
  deleteRoomInvitation: DeleteRoomInvitationOutput
  deleteSubModule: DeleteSubModuleOutput
  deleteTag: DeleteTagOutput
  deleteThread: DeleteThreadOutput
  deleteUser: DeleteUserOutput
  endCounselSession: GetCounselHistoryOutput
  findOrCreateTag: FindOrCreateTagOutput
  followFriend: FollowFriendOutput
  forgotPassword: ForgotPasswordOutput
  recoverBannedUser: RecoverBannedUserOutput
  recoverBlog: RecoverBlogOutput
  recoverCategory: RecoverCategoryOutput
  recoverComment: RecoverCommentOutput
  recoverCourse: RecoverCourseOutput
  recoverCourseCategory: RecoverCourseCategoryOutput
  recoverCourseComment: RecoverCourseCommentOutput
  recoverListenerRegister: RecoverListenerRegisterOutput
  recoverQuestion: RecoverQuestionOutput
  recoverReportTicket: RecoverReportTicketOutput
  recoverRole: RecoverRoleOutput
  recoverRoomInvitation: RecoverRoomInvitationOutput
  recoverSubModule: RecoverSubModuleOutput
  recoverTag: RecoverTagOutput
  recoverUser: RecoverUserOutput
  refreshDataToken: RefreshDataTokenOutput
  resetPassword: ResetPasswordOutput
  sendMessageSlack: SendMessageSlackOutput
  sendMessageToConversation: SendMessageToConversationOutput
  sendOtp: SendOtpOutput
  signin: SigninOutput
  signinByGoogle: SigninOutput
  signup: SignupOutput
  unfollowFriend: FollowFriendOutput
  updateBannedUser: UpdateBannedUserOutput
  updateBlog: UpdateBlogOutput
  updateCategory: UpdateCategoryOutput
  updateComment: UpdateCommentOutput
  updateCourse: UpdateCourseOutput
  updateCourseCategory: UpdateCourseCategoryOutput
  updateCourseComment: UpdateCourseCommentOutput
  updateCourseEnrollment: UpdateCourseEnrollmentOutput
  updateListener: UpdateListenerOutput
  updateListenerRegister: UpdateListenerRegisterOutput
  updateModule: UpdateModuleOutput
  updateNoteFormRegister: UpdateNoteFormRegisterOutput
  updateOrderSubModules: UpdateOrderSubModulesOutput
  updateQuestion: UpdateQuestionOutput
  updateQuiz: UpdateQuizOutput
  updateReportTicket: UpdateReportTicketOutput
  updateRole: UpdateRoleOutput
  updateRoom: UpdateRoomOutput
  updateRoomInvitation: UpdateRoomInvitationOutput
  updateSubModule: UpdateSubModuleOutput
  updateTag: UpdateTagOutput
  updateUser: UpdateUserOutput
  updateUserByAdmin: UpdateUserByAdminOutput
  upsertFeedback: UpsertFeedbackOutput
}

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MutationCreateBannedUserArgs = {
  input: CreateBannedUserInput
}

export type MutationCreateBlogArgs = {
  input: CreateBlogInput
}

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput
}

export type MutationCreateCommentArgs = {
  input: CreateCommentInput
}

export type MutationCreateCourseArgs = {
  input: CreateCourseInput
}

export type MutationCreateCourseCategoryArgs = {
  input: CreateCourseCategoryInput
}

export type MutationCreateCourseCommentArgs = {
  input: CreateCourseCommentInput
}

export type MutationCreateCourseEnrollmentArgs = {
  input: CreateCourseEnrollmentInput
}

export type MutationCreateFormRegisterArgs = {
  input: CreateFormRegisterInput
}

export type MutationCreateListenerArgs = {
  input: CreateListenerInput
}

export type MutationCreateListenerRegisterArgs = {
  input: CreateListenerRegisterInput
}

export type MutationCreateModuleArgs = {
  input: CreateModuleInput
}

export type MutationCreateOrDeleteBlogFavoriteArgs = {
  input: CreateOrDeleteBlogFavoriteInput
}

export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput
}

export type MutationCreateQuizArgs = {
  input: CreateQuizInput
}

export type MutationCreateReportTicketArgs = {
  input: CreateReportTicketInput
}

export type MutationCreateRoleArgs = {
  input: CreateRoleInput
}

export type MutationCreateRoomArgs = {
  input: CreateRoomInput
}

export type MutationCreateRoomInvitationArgs = {
  input: CreateRoomInvitationInput
}

export type MutationCreateSubModuleArgs = {
  input: CreateSubModuleInput
}

export type MutationCreateTagArgs = {
  input: CreateTagInput
}

export type MutationDeleteBannedUserArgs = {
  input: DeleteBannedUserInput
}

export type MutationDeleteBlogArgs = {
  input: DeleteBlogInput
}

export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput
}

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput
}

export type MutationDeleteCourseArgs = {
  input: DeleteCourseInput
}

export type MutationDeleteCourseCategoryArgs = {
  input: DeleteCourseCategoryInput
}

export type MutationDeleteCourseCommentArgs = {
  input: DeleteCourseCommentInput
}

export type MutationDeleteCourseEnrollmentArgs = {
  input: DeleteCourseEnrollmentOutput
}

export type MutationDeleteFollowerArgs = {
  input: DeleteFollowerInput
}

export type MutationDeleteListenerArgs = {
  input: DeleteListenerInput
}

export type MutationDeleteListenerRegisterArgs = {
  input: DeleteListenerRegisterInput
}

export type MutationDeleteModuleArgs = {
  input: DeleteModuleInput
}

export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput
}

export type MutationDeleteQuestionsArgs = {
  input: DeleteQuestionsInput
}

export type MutationDeleteQuizArgs = {
  input: DeleteQuizInput
}

export type MutationDeleteReportTicketArgs = {
  input: DeleteReportTicketInput
}

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput
}

export type MutationDeleteRoomArgs = {
  input: DeleteRoomInput
}

export type MutationDeleteRoomInvitationArgs = {
  input: DeleteRoomInvitationInput
}

export type MutationDeleteSubModuleArgs = {
  input: DeleteSubModuleInput
}

export type MutationDeleteTagArgs = {
  input: DeleteTagInput
}

export type MutationDeleteThreadArgs = {
  input: DeleteThreadInput
}

export type MutationDeleteUserArgs = {
  input: DeleteUserInput
}

export type MutationEndCounselSessionArgs = {
  input: EndCounselSessionInput
}

export type MutationFindOrCreateTagArgs = {
  input: FindOrCreateTagInput
}

export type MutationFollowFriendArgs = {
  input: FollowFriendInput
}

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput
}

export type MutationRecoverBannedUserArgs = {
  input: RecoverBannedUserInput
}

export type MutationRecoverBlogArgs = {
  input: RecoverBlogInput
}

export type MutationRecoverCategoryArgs = {
  input: RecoverCategoryInput
}

export type MutationRecoverCommentArgs = {
  input: RecoverCommentInput
}

export type MutationRecoverCourseArgs = {
  input: RecoverCourseInput
}

export type MutationRecoverCourseCategoryArgs = {
  input: RecoverCourseCategoryInput
}

export type MutationRecoverCourseCommentArgs = {
  input: RecoverCourseCommentInput
}

export type MutationRecoverListenerRegisterArgs = {
  input: RecoverListenerRegisterInput
}

export type MutationRecoverQuestionArgs = {
  input: RecoverQuestionInput
}

export type MutationRecoverReportTicketArgs = {
  input: RecoverReportTicketInput
}

export type MutationRecoverRoleArgs = {
  input: RecoverRoleInput
}

export type MutationRecoverRoomInvitationArgs = {
  input: RecoverRoomInvitationInput
}

export type MutationRecoverSubModuleArgs = {
  input: RecoverSubModuleInput
}

export type MutationRecoverTagArgs = {
  input: RecoverTagInput
}

export type MutationRecoverUserArgs = {
  input: RecoverUserInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationSendMessageSlackArgs = {
  input: SendMessageSlackInput
}

export type MutationSendMessageToConversationArgs = {
  input: SendMessageToConversationInput
}

export type MutationSendOtpArgs = {
  input: SendOtpInput
}

export type MutationSigninArgs = {
  input: SigninInput
}

export type MutationSigninByGoogleArgs = {
  input: SigninByGoogleInput
}

export type MutationSignupArgs = {
  input: SignupInput
}

export type MutationUnfollowFriendArgs = {
  input: FollowFriendInput
}

export type MutationUpdateBannedUserArgs = {
  input: UpdateBannedUserInput
}

export type MutationUpdateBlogArgs = {
  input: UpdateBlogInput
}

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
}

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput
}

export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput
}

export type MutationUpdateCourseCategoryArgs = {
  input: UpdateCourseCategoryInput
}

export type MutationUpdateCourseCommentArgs = {
  input: UpdateCourseCommentInput
}

export type MutationUpdateCourseEnrollmentArgs = {
  input: UpdateCourseEnrollmentInput
}

export type MutationUpdateListenerArgs = {
  input: UpdateListenerInput
}

export type MutationUpdateListenerRegisterArgs = {
  input: UpdateListenerRegisterInput
}

export type MutationUpdateModuleArgs = {
  input: UpdateModuleInput
}

export type MutationUpdateNoteFormRegisterArgs = {
  input: UpdateNoteFormRegisterInput
}

export type MutationUpdateOrderSubModulesArgs = {
  input: UpdateOrderSubModulesInput
}

export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput
}

export type MutationUpdateQuizArgs = {
  input: UpdateQuizInput
}

export type MutationUpdateReportTicketArgs = {
  input: UpdateReportTicketInput
}

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput
}

export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput
}

export type MutationUpdateRoomInvitationArgs = {
  input: UpdateRoomInvitationInput
}

export type MutationUpdateSubModuleArgs = {
  input: UpdateSubModuleInput
}

export type MutationUpdateTagArgs = {
  input: UpdateTagInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserByAdminArgs = {
  input: UpdateUserByAdminInput
}

export type MutationUpsertFeedbackArgs = {
  input: UpsertFeedbackInput
}

export type OnConversationMessageSyncedInput = {
  conversationIds: Array<Scalars['UUID']>
}

export type OnConversationMessageSyncedOutput = {
  __typename?: 'OnConversationMessageSyncedOutput'
  content: Scalars['JSON']
  conversation: Conversation
  conversationId: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['ID']
  ref?: Maybe<Scalars['String']>
  sender: User
  senderId: Scalars['String']
  status: Scalars['Int']
  type: Scalars['Int']
  updatedAt: Scalars['DateTime']
  viewers?: Maybe<Scalars['JSON']>
}

export type OnFriendSyncedInput = {
  id?: Maybe<Scalars['String']>
}

export type OnFriendSyncedOutput = {
  __typename?: 'OnFriendSyncedOutput'
  Thread?: Maybe<Array<Thread>>
  UserOtp?: Maybe<UserOtp>
  _count: UserCount
  address?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  bannedMe?: Maybe<Array<BannedUser>>
  blogFavorites?: Maybe<Array<BlogFavorite>>
  blogs?: Maybe<Array<Blog>>
  city?: Maybe<Scalars['String']>
  clientSessions?: Maybe<Array<CounselHistory>>
  conversations?: Maybe<Array<Participant>>
  counselorSessions?: Maybe<Array<CounselHistory>>
  country?: Maybe<Scalars['String']>
  countryIsoCode?: Maybe<Scalars['String']>
  courseComment?: Maybe<Array<CourseComment>>
  courseEnrollments?: Maybe<Array<CourseEnrollment>>
  courses?: Maybe<Array<Course>>
  createdAt: Scalars['String']
  createdByUserId?: Maybe<Scalars['String']>
  createdTickets?: Maybe<Array<ReportTicket>>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBanned?: Maybe<Array<BannedUser>>
  deletedByUserId?: Maybe<Scalars['String']>
  deletedCourseEnrollments?: Maybe<Array<CourseEnrollment>>
  deletedCourses?: Maybe<Array<Course>>
  email: Scalars['String']
  emailVerified?: Maybe<Scalars['Boolean']>
  feedbacks?: Maybe<Array<Feedback>>
  files?: Maybe<Array<File>>
  firstName?: Maybe<Scalars['String']>
  formCounsel?: Maybe<Array<FormCounsel>>
  fullName?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isComplete?: Maybe<Scalars['Boolean']>
  isFollower: Scalars['Boolean']
  isFollowing: Scalars['Boolean']
  isFriend: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  listener?: Maybe<Array<Listener>>
  listenerRegister?: Maybe<Array<ListenerRegister>>
  messagesSent?: Maybe<Array<Message>>
  myRequestedFriends?: Maybe<Array<Friendship>>
  ownerRooms?: Maybe<Array<RoomInvitation>>
  participantRooms?: Maybe<Array<RoomInvitation>>
  password: Scalars['String']
  phone?: Maybe<Scalars['String']>
  privilege?: Maybe<Array<Privilege>>
  questions?: Maybe<Array<Question>>
  quizs?: Maybe<Array<Quiz>>
  reportedTickets?: Maybe<Array<ReportTicket>>
  requestBanned?: Maybe<Array<BannedUser>>
  requestedFriends?: Maybe<Array<Friendship>>
  roles?: Maybe<Array<Role>>
  rooms?: Maybe<Array<RoomInvitation>>
  state?: Maybe<Scalars['String']>
  stateIsoCode?: Maybe<Scalars['String']>
  status: Scalars['Int']
  subModule?: Maybe<Array<SubModule>>
  updatedAt: Scalars['String']
  userListener?: Maybe<Array<Listener>>
  userProfile?: Maybe<UserProfile>
}

export type OnRoomSyncedOutPut = {
  __typename?: 'OnRoomSyncedOutPut'
  action: Scalars['Float']
  room: RoomOutput
}

export type Pagination = {
  __typename?: 'Pagination'
  limit: Scalars['Float']
  page: Scalars['Float']
  totalCount: Scalars['Float']
}

export type PaginationInput = {
  limit?: Maybe<Scalars['Float']>
  page?: Maybe<Scalars['Float']>
  totalCount?: Maybe<Scalars['Float']>
}

export type Participant = {
  __typename?: 'Participant'
  conversation: Conversation
  conversationId: Scalars['String']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export enum Privilege {
  Listener = 'Listener',
  SuperAdmin = 'SuperAdmin',
}

export type Query = {
  __typename?: 'Query'
  destroyRoomInvitation: DestroyRoomInvitationOutput
  getAPIVersion: Scalars['String']
  getBannedUser: GetBannedUserOutput
  getBannedUsers: GetBannedUsersOutput
  getBannedUsersDeleted: GetBannedUsersOutput
  getBlog: GetBlogOutput
  getBlogFavorites: GetBlogFavoritesOutput
  getBlogHomepage: GetBlogHomePageOutput
  getBlogs: GetBlogsOutput
  getBlogsPublish: GetBlogsOutput
  getCategories: GetCategoriesOutput
  getCategory: GetCategoryOutput
  getCategoryBlog: GetCategoryBlogOutput
  getComment: GetCommentOutput
  getComments: GetCommentsOutput
  getConversationId: GetConversationIdOutput
  getConversationMessages: GetConversationMessagesOutput
  getConversations: GetConversationsOutput
  getCounselHistories: GetCounselHistoriesOutput
  getCounselHistory: GetCounselHistoryOutput
  getCourse: GetCourseOutput
  getCourseCategories: GetCourseCategoriesOutput
  getCourseCategory: GetCourseCategoryOutput
  getCourseComment: GetCourseCommentOutput
  getCourseComments: GetCourseCommentsOutput
  getCourseEnrollment: GetCourseEnrollmentOutput
  getCourseEnrollments: GetCourseEnrollmentsOutput
  getCourses: GetCoursesOutput
  getDeletedBlogs: GetBlogsOutput
  getDeletedCategories: GetCategoriesOutput
  getDeletedComments: GetCommentsOutput
  getDeletedCourseCategories: GetCourseCategoriesOutput
  getDeletedCourses: GetCoursesOutput
  getDeletedListeners: GetListenersOutput
  getDeletedListenersRegister: GetDeletedListenersRegisterOutput
  getDeletedReportTickets: GetReportTicketsOutput
  getDeletedRoles: Array<GetDeletedRolesOutput>
  getDeletedRoomInvitations: GetRoomInvitationsOutput
  getDeletedTags: GetTagsOutput
  getDeletedUsers: GetUsersOutput
  getEnrolledCourses: GetCoursesOutput
  getFeedback: GetFeedbackOutput
  getFeedbacks: GetFeedbacksOutput
  getFiles: GetFilesOutput
  getFormRegisters: GetFormRegisterOutput
  getListener: GetListenerOutput
  getListenerRegister: GetListenerRegisterOutput
  getListeners: GetListenersOutput
  getListenersRegister: GetListenersRegisterOutput
  getModule: GetModuleOutput
  getModules: GetModulesOutput
  getMyCourses: GetCoursesOutput
  getMyFavoriteBlogs: GetBlogFavoritesOutput
  getMyFollowers: GetMyFollowersOutput
  getMyFollowings: GetMyFollowingsOutput
  getMyFriends: GetMyFriendsOutput
  getMyProfile: GetUserProfileOutput
  getQuestion: GetQuestionOutput
  getQuestions: GetQuestionsOutput
  getQuiz: GetQuizOutput
  getQuizzes: GetQuizzesOutput
  getReportTicket: GetReportTicketOutput
  getReportTickets: GetReportTicketsOutput
  getRole: GetRoleOutput
  getRoles: Array<GetRolesOutput>
  getRoom: GetRoomOutput
  getRoomInvitation: GetRoomInvitationOutput
  getRoomInvitations: GetRoomInvitationsOutput
  getRooms: GetRoomsOutput
  getSubModule: GetSubModuleOutput
  getSubModules: GetSubModulesOutput
  getTag: GetTagOutput
  getTags: GetTagsOutput
  getThread: GetThreadOutput
  getUser: GetUserOutput
  getUsers: GetUsersOutput
  me: MeOutput
  searchBlogs: SearchBlogsOutput
  upsertThread: UpsertThreadOutPut
}

export type QueryDestroyRoomInvitationArgs = {
  input: DestroyRoomInvitationInput
}

export type QueryGetBannedUserArgs = {
  input: GetBannedUserInput
}

export type QueryGetBannedUsersArgs = {
  input: GetBannedUsersInput
}

export type QueryGetBannedUsersDeletedArgs = {
  input: GetBannedUsersInput
}

export type QueryGetBlogArgs = {
  input: GetBlogInput
}

export type QueryGetBlogFavoritesArgs = {
  input: GetBlogFavoritesInput
}

export type QueryGetBlogHomepageArgs = {
  input: GetBlogHomePageInput
}

export type QueryGetBlogsArgs = {
  input: GetBlogsInput
}

export type QueryGetBlogsPublishArgs = {
  input: GetBlogsInput
}

export type QueryGetCategoriesArgs = {
  input: GetCategoriesInput
}

export type QueryGetCategoryArgs = {
  input: GetCategoryInput
}

export type QueryGetCategoryBlogArgs = {
  input: GetCategoryBlogInput
}

export type QueryGetCommentArgs = {
  input: GetCommentInput
}

export type QueryGetCommentsArgs = {
  input: GetCommentsInput
}

export type QueryGetConversationIdArgs = {
  input: GetConversationIdInput
}

export type QueryGetConversationMessagesArgs = {
  input: GetConversationMessagesInput
}

export type QueryGetConversationsArgs = {
  input: GetConversationsInput
}

export type QueryGetCounselHistoriesArgs = {
  input: GetCounselHistoriesInput
}

export type QueryGetCounselHistoryArgs = {
  input: GetCounselHistoryInput
}

export type QueryGetCourseArgs = {
  input: GetCourseInput
}

export type QueryGetCourseCategoriesArgs = {
  input: GetCourseCategoriesInput
}

export type QueryGetCourseCategoryArgs = {
  input: GetCourseCategoryInput
}

export type QueryGetCourseCommentArgs = {
  input: GetCourseCommentInput
}

export type QueryGetCourseCommentsArgs = {
  input: GetCourseCommentsInput
}

export type QueryGetCourseEnrollmentArgs = {
  input: GetCourseEnrollmentInput
}

export type QueryGetCourseEnrollmentsArgs = {
  input: GetCourseEnrollmentsInput
}

export type QueryGetCoursesArgs = {
  input: GetCoursesInput
}

export type QueryGetDeletedBlogsArgs = {
  input: GetBlogsInput
}

export type QueryGetDeletedCategoriesArgs = {
  input: GetCategoriesInput
}

export type QueryGetDeletedCommentsArgs = {
  input: GetCommentsInput
}

export type QueryGetDeletedCourseCategoriesArgs = {
  input: GetCourseCategoriesInput
}

export type QueryGetDeletedCoursesArgs = {
  input: GetCoursesInput
}

export type QueryGetDeletedListenersArgs = {
  input: GetListenersInput
}

export type QueryGetDeletedListenersRegisterArgs = {
  input: GetDeletedListenersRegisterInput
}

export type QueryGetDeletedReportTicketsArgs = {
  input: GetReportTicketsInput
}

export type QueryGetDeletedRoomInvitationsArgs = {
  input: GetRoomInvitationsInput
}

export type QueryGetDeletedTagsArgs = {
  input: GetTagsInput
}

export type QueryGetDeletedUsersArgs = {
  input: GetUsersInput
}

export type QueryGetEnrolledCoursesArgs = {
  input: GetCoursesInput
}

export type QueryGetFeedbackArgs = {
  input: GetFeedbackInput
}

export type QueryGetFeedbacksArgs = {
  input: GetFeedbacksInput
}

export type QueryGetFilesArgs = {
  input: GetFilesInput
}

export type QueryGetFormRegistersArgs = {
  input: GetFormRegisterInput
}

export type QueryGetListenerArgs = {
  input: GetListenerInput
}

export type QueryGetListenerRegisterArgs = {
  input: GetListenerRegisterInput
}

export type QueryGetListenersArgs = {
  input: GetListenersInput
}

export type QueryGetListenersRegisterArgs = {
  input: GetListenersRegisterInput
}

export type QueryGetModuleArgs = {
  input: GetModuleInput
}

export type QueryGetModulesArgs = {
  input: GetModulesInput
}

export type QueryGetMyCoursesArgs = {
  input: GetCoursesInput
}

export type QueryGetMyFavoriteBlogsArgs = {
  input: GetBlogFavoritesInput
}

export type QueryGetMyFollowersArgs = {
  input: GetMyFollowersInput
}

export type QueryGetMyFollowingsArgs = {
  input: GetMyFriendsInput
}

export type QueryGetMyFriendsArgs = {
  input: GetMyFriendsInput
}

export type QueryGetQuestionArgs = {
  input: GetQuestionInput
}

export type QueryGetQuestionsArgs = {
  input: GetQuestionsInput
}

export type QueryGetQuizArgs = {
  input: GetQuizInput
}

export type QueryGetQuizzesArgs = {
  input: GetQuizzesInput
}

export type QueryGetReportTicketArgs = {
  input: GetReportTicketInput
}

export type QueryGetReportTicketsArgs = {
  input: GetReportTicketsInput
}

export type QueryGetRoleArgs = {
  input: GetRoleInput
}

export type QueryGetRoomArgs = {
  input: GetRoomInput
}

export type QueryGetRoomInvitationArgs = {
  input: GetRoomInvitationInput
}

export type QueryGetRoomInvitationsArgs = {
  input: GetRoomInvitationsInput
}

export type QueryGetRoomsArgs = {
  input: GetRoomsInput
}

export type QueryGetSubModuleArgs = {
  input: GetSubModuleInput
}

export type QueryGetSubModulesArgs = {
  input: GetSubModulesInput
}

export type QueryGetTagArgs = {
  input: GetTagInput
}

export type QueryGetTagsArgs = {
  input: GetTagsInput
}

export type QueryGetThreadArgs = {
  input: GetThreadInput
}

export type QueryGetUserArgs = {
  input: GetUserInput
}

export type QueryGetUsersArgs = {
  input: GetUsersInput
}

export type QuerySearchBlogsArgs = {
  input: SearchBlogsInput
}

export type QueryUpsertThreadArgs = {
  input: UpsertThreadInput
}

export type Question = {
  __typename?: 'Question'
  _count: QuestionCount
  answer?: Maybe<Array<Answer>>
  createdAt: Scalars['DateTime']
  createdBy: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  module: Module
  moduleId: Scalars['String']
  quizes?: Maybe<Array<Quiz>>
  title: Scalars['String']
  type: Scalars['Int']
  updatedAt: Scalars['DateTime']
  user: User
}

export type QuestionCount = {
  __typename?: 'QuestionCount'
  answer: Scalars['Int']
  quizes: Scalars['Int']
}

export enum QuestionType {
  MultipleChoice = 'MultipleChoice',
  SingleChoice = 'SingleChoice',
}

export type Quiz = {
  __typename?: 'Quiz'
  _count: QuizCount
  createdAt: Scalars['DateTime']
  createdBy: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  module: Module
  moduleId: Scalars['String']
  passGrade: Scalars['Float']
  questions?: Maybe<Array<Question>>
  status: Scalars['Int']
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
}

export type QuizCount = {
  __typename?: 'QuizCount'
  questions: Scalars['Int']
}

export enum QuizStatus {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

export type RecoverBannedUserInput = {
  id: Scalars['UUID']
}

export type RecoverBannedUserOutput = {
  __typename?: 'RecoverBannedUserOutput'
  id: Scalars['String']
}

export type RecoverBlogInput = {
  id: Scalars['UUID']
}

export type RecoverBlogOutput = {
  __typename?: 'RecoverBlogOutput'
  id: Scalars['String']
}

export type RecoverCategoryInput = {
  id: Scalars['UUID']
}

export type RecoverCategoryOutput = {
  __typename?: 'RecoverCategoryOutput'
  id: Scalars['String']
}

export type RecoverCommentInput = {
  id: Scalars['UUID']
}

export type RecoverCommentOutput = {
  __typename?: 'RecoverCommentOutput'
  id: Scalars['String']
}

export type RecoverCourseCategoryInput = {
  id: Scalars['UUID']
}

export type RecoverCourseCategoryOutput = {
  __typename?: 'RecoverCourseCategoryOutput'
  id: Scalars['String']
}

export type RecoverCourseCommentInput = {
  id: Scalars['UUID']
}

export type RecoverCourseCommentOutput = {
  __typename?: 'RecoverCourseCommentOutput'
  id: Scalars['String']
}

export type RecoverCourseInput = {
  id: Scalars['UUID']
}

export type RecoverCourseOutput = {
  __typename?: 'RecoverCourseOutput'
  id: Scalars['String']
}

export type RecoverListenerRegisterInput = {
  id: Scalars['UUID']
}

export type RecoverListenerRegisterOutput = {
  __typename?: 'RecoverListenerRegisterOutput'
  id: Scalars['String']
}

export type RecoverQuestionInput = {
  id: Scalars['UUID']
}

export type RecoverQuestionOutput = {
  __typename?: 'RecoverQuestionOutput'
  data: Question
}

export type RecoverReportTicketInput = {
  id: Scalars['UUID']
}

export type RecoverReportTicketOutput = {
  __typename?: 'RecoverReportTicketOutput'
  id: Scalars['String']
}

export type RecoverRoleInput = {
  id: Scalars['UUID']
}

export type RecoverRoleOutput = {
  __typename?: 'RecoverRoleOutput'
  id: Scalars['String']
}

export type RecoverRoomInvitationInput = {
  id: Scalars['UUID']
}

export type RecoverRoomInvitationOutput = {
  __typename?: 'RecoverRoomInvitationOutput'
  data: RoomInvitation
}

export type RecoverSubModuleInput = {
  id: Scalars['UUID']
}

export type RecoverSubModuleOutput = {
  __typename?: 'RecoverSubModuleOutput'
  data: SubModule
}

export type RecoverTagInput = {
  id: Scalars['UUID']
}

export type RecoverTagOutput = {
  __typename?: 'RecoverTagOutput'
  id: Scalars['String']
}

export type RecoverUserInput = {
  id: Scalars['UUID']
}

export type RecoverUserOutput = {
  __typename?: 'RecoverUserOutput'
  id: Scalars['String']
}

export type RefreshDataTokenOutput = {
  __typename?: 'RefreshDataTokenOutput'
  accessToken: Scalars['String']
  user: User
}

export type RelationshipInput = {
  id: Scalars['UUID']
}

export type ReportTicket = {
  __typename?: 'ReportTicket'
  createdAt: Scalars['DateTime']
  createdBy: User
  createdByUserId: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description: Scalars['String']
  id: Scalars['ID']
  priority: Scalars['Float']
  reportedUser?: Maybe<User>
  reportedUserId?: Maybe<Scalars['String']>
  status: Scalars['Int']
  subject: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export enum ReportTicketStatus {
  Accepted = 'Accepted',
  Closed = 'Closed',
  InProgress = 'InProgress',
  Open = 'Open',
  Rejected = 'Rejected',
}

export type ResetPasswordInput = {
  email: Scalars['String']
  otp: Scalars['String']
  password: Scalars['String']
}

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput'
  success: Scalars['Boolean']
}

export type Role = {
  __typename?: 'Role'
  _count: RoleCount
  code?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isDefault?: Maybe<Scalars['Boolean']>
  scp?: Maybe<Array<Scalars['String']>>
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
  users?: Maybe<Array<User>>
}

export type RoleCount = {
  __typename?: 'RoleCount'
  users: Scalars['Int']
}

export type RoleEntity = {
  __typename?: 'RoleEntity'
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  isDefault?: Maybe<Scalars['Boolean']>
  scp?: Maybe<Array<Scalars['String']>>
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type RoomInvitation = {
  __typename?: 'RoomInvitation'
  _count: RoomInvitationCount
  createdAt: Scalars['DateTime']
  createdBy: User
  createdByUserId: Scalars['String']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  ownerRoom: User
  ownerRoomId: Scalars['String']
  participants?: Maybe<Array<User>>
  roomStatus: Scalars['Int']
  roomType: Scalars['Int']
  slug: Scalars['String']
  startTime: Scalars['DateTime']
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type RoomInvitationCount = {
  __typename?: 'RoomInvitationCount'
  participants: Scalars['Int']
}

export type RoomOutput = {
  __typename?: 'RoomOutput'
  capacity: Scalars['Float']
  clients: Array<RoomUserOutPut>
  createdAt: Scalars['Float']
  creator: RoomUserOutPut
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  isAnonymous?: Maybe<Scalars['Boolean']>
  language: Scalars['String']
  lifeProblems?: Maybe<Array<Scalars['String']>>
  moods?: Maybe<Array<Scalars['String']>>
  roomType?: Maybe<Scalars['Float']>
  tags?: Maybe<Array<Scalars['String']>>
  topic?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Float']>
}

export enum RoomType {
  Counseling = 'counseling',
  Meeting = 'meeting',
  Webinars = 'webinars',
}

export type RoomUserOutPut = {
  __typename?: 'RoomUserOutPut'
  avatar: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
}

export type SearchBlogsInput = {
  categories?: Maybe<Array<GetBySlugInput>>
  search?: Maybe<Scalars['String']>
  tags?: Maybe<Array<GetBySlugInput>>
}

export type SearchBlogsOutput = {
  __typename?: 'SearchBlogsOutput'
  data: Array<BlogEntity>
  pagination: Pagination
}

export type SendMessageSlackInput = {
  email: Scalars['String']
  message: Scalars['String']
}

export type SendMessageSlackOutput = {
  __typename?: 'SendMessageSlackOutput'
  success: Scalars['Boolean']
}

export type SendMessageToConversationInput = {
  conversationId: Scalars['UUID']
  message: Scalars['JSON']
}

export type SendMessageToConversationOutput = {
  __typename?: 'SendMessageToConversationOutput'
  data: Message
}

export type SendOtpInput = {
  email: Scalars['String']
}

export type SendOtpOutput = {
  __typename?: 'SendOtpOutput'
  success: Scalars['Boolean']
}

export enum SessionFeedback {
  Calm = 'CALM',
  Comfortable = 'COMFORTABLE',
  Professional = 'PROFESSIONAL',
  Relax = 'RELAX',
  Wonderful = 'WONDERFUL',
}

export enum SessionReferenceSatisfaction {
  GoodSession = 'GoodSession',
  NotSoHelpful = 'NotSoHelpful',
  Satisfied = 'Satisfied',
  WellMatched = 'WellMatched',
}

export type SigninByGoogleInput = {
  token: Scalars['String']
}

export type SigninInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type SigninOutput = {
  __typename?: 'SigninOutput'
  email: Scalars['String']
  id: Scalars['String']
  refreshToken?: Maybe<Scalars['String']>
  scp: Array<Scalars['String']>
  token: Scalars['String']
}

export type SignupInput = {
  email: Scalars['String']
  fullName: Scalars['String']
  otp: Scalars['String']
  password: Scalars['String']
}

export type SignupOutput = {
  __typename?: 'SignupOutput'
  email: Scalars['String']
  id: Scalars['String']
  refreshToken?: Maybe<Scalars['String']>
  scp: Array<Scalars['String']>
  token: Scalars['String']
}

export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export enum StatusModule {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type SubModule = {
  __typename?: 'SubModule'
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<User>
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  module?: Maybe<Module>
  moduleId?: Maybe<Scalars['String']>
  order: Scalars['Int']
  richText?: Maybe<SubModuleRichText>
  slug: Scalars['String']
  title: Scalars['String']
  type?: Maybe<SubModuleType>
  updatedAt: Scalars['DateTime']
  video?: Maybe<SubModuleVideo>
}

export type SubModuleRichText = {
  __typename?: 'SubModuleRichText'
  content: Scalars['String']
  id: Scalars['ID']
  subModule: SubModule
  subModuleId: Scalars['String']
  title: Scalars['String']
}

export enum SubModuleType {
  RichText = 'RICH_TEXT',
  Video = 'VIDEO',
}

export type SubModuleVideo = {
  __typename?: 'SubModuleVideo'
  duration: Scalars['Int']
  id: Scalars['ID']
  source: UrlSource
  subModule?: Maybe<SubModule>
  subModuleId: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  onConversationMessageSynced: OnConversationMessageSyncedOutput
  onFriendSynced: OnFriendSyncedOutput
  onRoomSynced: OnRoomSyncedOutPut
}

export type SubscriptionOnConversationMessageSyncedArgs = {
  input: OnConversationMessageSyncedInput
}

export type SubscriptionOnFriendSyncedArgs = {
  input: OnFriendSyncedInput
}

export type Tag = {
  __typename?: 'Tag'
  _count: TagCount
  blogs?: Maybe<Array<Blog>>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedByUserId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type TagCount = {
  __typename?: 'TagCount'
  blogs: Scalars['Int']
}

export type TagEntity = {
  __typename?: 'TagEntity'
  blogs?: Maybe<Array<BlogEntity>>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy: UserEntity
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type Thread = {
  __typename?: 'Thread'
  _count: ThreadCount
  boxMessage?: Maybe<Array<BotMessage>>
  createdAt: Scalars['DateTime']
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  id: Scalars['ID']
  latestMessage?: Maybe<Scalars['String']>
  threadId: Scalars['String']
  updatedAt: Scalars['DateTime']
  user?: Maybe<User>
  userId?: Maybe<Scalars['String']>
}

export type ThreadCount = {
  __typename?: 'ThreadCount'
  boxMessage: Scalars['Int']
}

export type UpdateAnswerItemInput = {
  id?: Maybe<Scalars['UUID']>
  isCorrect?: Maybe<Scalars['Boolean']>
  isDeleted?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type UpdateBannedUserInput = {
  banEndDate?: Maybe<Scalars['DateTime']>
  banStartDate?: Maybe<Scalars['DateTime']>
  deviceId?: Maybe<Scalars['String']>
  deviceType?: Maybe<Scalars['String']>
  id: Scalars['String']
  ipAddress?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  unbanReason?: Maybe<Scalars['String']>
  unbanRequestDate?: Maybe<Scalars['DateTime']>
}

export type UpdateBannedUserOutput = {
  __typename?: 'UpdateBannedUserOutput'
  id: Scalars['String']
}

export type UpdateBlogInput = {
  categories?: Maybe<Array<RelationshipInput>>
  content?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  facebookDescription?: Maybe<Scalars['String']>
  facebookThumbnailImage?: Maybe<Scalars['String']>
  facebookTitle?: Maybe<Scalars['String']>
  id: Scalars['String']
  keyword?: Maybe<Array<Scalars['String']>>
  publishDate?: Maybe<Scalars['DateTime']>
  seoDescription?: Maybe<Scalars['String']>
  seoThumbnailImage?: Maybe<Scalars['String']>
  seoTitle?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['Float']>
  tags?: Maybe<Array<CreateBlogTagInput>>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  twitterDescription?: Maybe<Scalars['String']>
  twitterThumbnailImage?: Maybe<Scalars['String']>
  twitterTitle?: Maybe<Scalars['String']>
}

export type UpdateBlogOutput = {
  __typename?: 'UpdateBlogOutput'
  id: Scalars['String']
}

export type UpdateCategoryInput = {
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  parentId?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type UpdateCategoryOutput = {
  __typename?: 'UpdateCategoryOutput'
  id: Scalars['String']
}

export type UpdateCommentInput = {
  content?: Maybe<Scalars['String']>
  id: Scalars['String']
  status?: Maybe<Scalars['Float']>
}

export type UpdateCommentOutput = {
  __typename?: 'UpdateCommentOutput'
  id: Scalars['String']
}

export type UpdateCourseCategoryInput = {
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  image?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
}

export type UpdateCourseCategoryOutput = {
  __typename?: 'UpdateCourseCategoryOutput'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type UpdateCourseCommentInput = {
  content: Scalars['String']
  id: Scalars['String']
}

export type UpdateCourseCommentOutput = {
  __typename?: 'UpdateCourseCommentOutput'
  id: Scalars['String']
}

export type UpdateCourseEnrollmentInput = {
  id: Scalars['UUID']
  rating?: Maybe<Scalars['Float']>
  status?: Maybe<EnrollmentStatus>
}

export type UpdateCourseEnrollmentOutput = {
  __typename?: 'UpdateCourseEnrollmentOutput'
  data: CourseEnrollment
}

export type UpdateCourseInput = {
  categories?: Maybe<Array<RelationshipInput>>
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  level?: Maybe<LevelCourse>
  overview?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  publishDate?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  timeLearn?: Maybe<Scalars['Float']>
  title?: Maybe<Scalars['String']>
}

export type UpdateCourseOutput = {
  __typename?: 'UpdateCourseOutput'
  createdAt: Scalars['DateTime']
  id: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type UpdateListenerInput = {
  id: Scalars['UUID']
  status?: Maybe<ListenerStatus>
  userListenerId?: Maybe<Scalars['UUID']>
}

export type UpdateListenerOutput = {
  __typename?: 'UpdateListenerOutput'
  data: Listener
}

export type UpdateListenerRegisterInput = {
  adsSource?: Maybe<AdsSource>
  id: Scalars['UUID']
  reason?: Maybe<Scalars['String']>
  status?: Maybe<ListenerRegisterStatus>
}

export type UpdateListenerRegisterOutput = {
  __typename?: 'UpdateListenerRegisterOutput'
  id: Scalars['String']
}

export type UpdateModuleInput = {
  description?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  order?: Maybe<Scalars['Float']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<StatusModule>
  title?: Maybe<Scalars['String']>
}

export type UpdateModuleOutput = {
  __typename?: 'UpdateModuleOutput'
  data: Module
}

export type UpdateNoteFormRegisterInput = {
  id: Scalars['UUID']
  note: Scalars['String']
}

export type UpdateNoteFormRegisterOutput = {
  __typename?: 'UpdateNoteFormRegisterOutput'
  data: FormCounsel
}

export type UpdateOrderSubModuleInput = {
  id: Scalars['UUID']
  order: Scalars['Float']
}

export type UpdateOrderSubModulesInput = {
  subModules: Array<UpdateOrderSubModuleInput>
}

export type UpdateOrderSubModulesOutput = {
  __typename?: 'UpdateOrderSubModulesOutput'
  data: Array<SubModule>
}

export type UpdateQuestionInput = {
  answers?: Maybe<Array<UpdateAnswerItemInput>>
  id: Scalars['UUID']
  moduleId?: Maybe<Scalars['UUID']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<QuestionType>
}

export type UpdateQuestionOutput = {
  __typename?: 'UpdateQuestionOutput'
  data: Question
}

export type UpdateQuizInput = {
  id: Scalars['UUID']
  moduleId?: Maybe<Scalars['UUID']>
  passGrade?: Maybe<Scalars['Float']>
  questionsId?: Maybe<Array<Scalars['UUID']>>
  status?: Maybe<QuizStatus>
  title?: Maybe<Scalars['String']>
}

export type UpdateQuizOutput = {
  __typename?: 'UpdateQuizOutput'
  data: Quiz
}

export type UpdateReportTicketInput = {
  durationBanned?: Maybe<Scalars['Float']>
  id: Scalars['String']
  priority?: Maybe<Scalars['Float']>
  status?: Maybe<ReportTicketStatus>
}

export type UpdateReportTicketOutput = {
  __typename?: 'UpdateReportTicketOutput'
  id: Scalars['String']
}

export type UpdateRoleInput = {
  code?: Maybe<Scalars['String']>
  id: Scalars['String']
  isDefault?: Maybe<Scalars['Boolean']>
  scp?: Maybe<Array<Scalars['String']>>
  title?: Maybe<Scalars['String']>
}

export type UpdateRoleOutput = {
  __typename?: 'UpdateRoleOutput'
  id: Scalars['String']
}

export type UpdateRoomInput = {
  capacity?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  isOccupied?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  lifeProblems?: Maybe<Array<Scalars['String']>>
  moods?: Maybe<Array<Scalars['String']>>
  roomType?: Maybe<Scalars['Float']>
  tags?: Maybe<Array<Scalars['String']>>
  topic?: Maybe<Scalars['String']>
}

export type UpdateRoomInvitationInput = {
  id: Scalars['UUID']
  ownerRoomId?: Maybe<Scalars['UUID']>
  participants?: Maybe<Array<RelationshipInput>>
  roomType?: Maybe<RoomType>
  slug?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['DateTime']>
  title?: Maybe<Scalars['String']>
}

export type UpdateRoomInvitationOutput = {
  __typename?: 'UpdateRoomInvitationOutput'
  data: RoomInvitation
}

export type UpdateRoomOutput = {
  __typename?: 'UpdateRoomOutput'
  id: Scalars['String']
}

export type UpdateSubModuleInput = {
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  order?: Maybe<Scalars['Float']>
  richText?: Maybe<UpsertSubModuleRichTextItem>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<SubModuleType>
  video?: Maybe<UpsertSubModuleVideoItem>
}

export type UpdateSubModuleOutput = {
  __typename?: 'UpdateSubModuleOutput'
  data: SubModule
}

export type UpdateTagInput = {
  id: Scalars['String']
  name: Scalars['String']
  slug?: Maybe<Scalars['String']>
}

export type UpdateTagOutput = {
  __typename?: 'UpdateTagOutput'
  id: Scalars['String']
}

export type UpdateUserByAdminInput = {
  id: Scalars['String']
  roles?: Maybe<Array<RelationshipInput>>
}

export type UpdateUserByAdminOutput = {
  __typename?: 'UpdateUserByAdminOutput'
  id: Scalars['String']
}

export type UpdateUserInput = {
  aboutMe?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  countryIsoCode?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  experience?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['String']
  password?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  stateIsoCode?: Maybe<Scalars['String']>
}

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput'
  id: Scalars['String']
}

export type UpsertFeedbackInput = {
  callHistoryId: Scalars['String']
  feedbackOrNote?: Maybe<Scalars['String']>
  improvementSuggestions: Scalars['String']
  rating: Scalars['Float']
  sessionFeedbackTags?: Maybe<Array<SessionFeedback>>
  sessionReferenceSatisfaction: Array<SessionReferenceSatisfaction>
  waitTimeForSessionEntry: WaitTimeForSessionEntry
}

export type UpsertFeedbackOutput = {
  __typename?: 'UpsertFeedbackOutput'
  id: Scalars['UUID']
}

export type UpsertSubModuleRichTextItem = {
  content?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  subModuleId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type UpsertSubModuleVideoItem = {
  duration?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  source?: Maybe<UrlSource>
  subModuleId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type UpsertThreadInput = {
  botMessageId?: Maybe<Scalars['UUID']>
  messages: Array<MessageInput>
  threadId: Scalars['String']
  userId?: Maybe<Scalars['UUID']>
  userMessageId?: Maybe<Scalars['UUID']>
}

export type UpsertThreadOutPut = {
  __typename?: 'UpsertThreadOutPut'
  data: ChatCompletionOutput
}

export enum UrlSource {
  Dailymotion = 'dailymotion',
  Facebook = 'facebook',
  Mp3 = 'mp3',
  Mp4 = 'mp4',
  Ogg = 'ogg',
  Pdf = 'pdf',
  Twitch = 'twitch',
  Vimeo = 'vimeo',
  Webm = 'webm',
  Wistia = 'wistia',
  Youtube = 'youtube',
}

export type UsageOutPut = {
  __typename?: 'UsageOutPut'
  completion_tokens: Scalars['Float']
  prompt_tokens: Scalars['Float']
  total_tokens: Scalars['Float']
}

export type User = {
  __typename?: 'User'
  Thread?: Maybe<Array<Thread>>
  UserOtp?: Maybe<UserOtp>
  _count: UserCount
  address?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['String']>
  bannedMe?: Maybe<Array<BannedUser>>
  blogFavorites?: Maybe<Array<BlogFavorite>>
  blogs?: Maybe<Array<Blog>>
  city?: Maybe<Scalars['String']>
  clientSessions?: Maybe<Array<CounselHistory>>
  conversations?: Maybe<Array<Participant>>
  counselorSessions?: Maybe<Array<CounselHistory>>
  country?: Maybe<Scalars['String']>
  countryIsoCode?: Maybe<Scalars['String']>
  courseComment?: Maybe<Array<CourseComment>>
  courseEnrollments?: Maybe<Array<CourseEnrollment>>
  courses?: Maybe<Array<Course>>
  createdAt: Scalars['DateTime']
  createdByUserId?: Maybe<Scalars['String']>
  createdTickets?: Maybe<Array<ReportTicket>>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBanned?: Maybe<Array<BannedUser>>
  deletedByUserId?: Maybe<Scalars['String']>
  deletedCourseEnrollments?: Maybe<Array<CourseEnrollment>>
  deletedCourses?: Maybe<Array<Course>>
  email: Scalars['String']
  emailVerified?: Maybe<Scalars['Boolean']>
  feedbacks?: Maybe<Array<Feedback>>
  files?: Maybe<Array<File>>
  firstName?: Maybe<Scalars['String']>
  formCounsel?: Maybe<Array<FormCounsel>>
  fullName?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isComplete?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  listener?: Maybe<Array<Listener>>
  listenerRegister?: Maybe<Array<ListenerRegister>>
  messagesSent?: Maybe<Array<Message>>
  myRequestedFriends?: Maybe<Array<Friendship>>
  ownerRooms?: Maybe<Array<RoomInvitation>>
  participantRooms?: Maybe<Array<RoomInvitation>>
  password: Scalars['String']
  phone?: Maybe<Scalars['String']>
  privilege?: Maybe<Array<Privilege>>
  questions?: Maybe<Array<Question>>
  quizs?: Maybe<Array<Quiz>>
  reportedTickets?: Maybe<Array<ReportTicket>>
  requestBanned?: Maybe<Array<BannedUser>>
  requestedFriends?: Maybe<Array<Friendship>>
  roles?: Maybe<Array<Role>>
  rooms?: Maybe<Array<RoomInvitation>>
  state?: Maybe<Scalars['String']>
  stateIsoCode?: Maybe<Scalars['String']>
  status: Scalars['Int']
  subModule?: Maybe<Array<SubModule>>
  updatedAt: Scalars['DateTime']
  userListener?: Maybe<Array<Listener>>
  userProfile?: Maybe<UserProfile>
}

export type UserCount = {
  __typename?: 'UserCount'
  Thread: Scalars['Int']
  bannedMe: Scalars['Int']
  blogFavorites: Scalars['Int']
  blogs: Scalars['Int']
  clientSessions: Scalars['Int']
  conversations: Scalars['Int']
  counselorSessions: Scalars['Int']
  courseComment: Scalars['Int']
  courseEnrollments: Scalars['Int']
  courses: Scalars['Int']
  createdTickets: Scalars['Int']
  deletedBanned: Scalars['Int']
  deletedCourseEnrollments: Scalars['Int']
  deletedCourses: Scalars['Int']
  feedbacks: Scalars['Int']
  files: Scalars['Int']
  formCounsel: Scalars['Int']
  listener: Scalars['Int']
  listenerRegister: Scalars['Int']
  messagesSent: Scalars['Int']
  myRequestedFriends: Scalars['Int']
  ownerRooms: Scalars['Int']
  participantRooms: Scalars['Int']
  questions: Scalars['Int']
  quizs: Scalars['Int']
  reportedTickets: Scalars['Int']
  requestBanned: Scalars['Int']
  requestedFriends: Scalars['Int']
  roles: Scalars['Int']
  rooms: Scalars['Int']
  subModule: Scalars['Int']
  userListener: Scalars['Int']
}

export type UserEntity = {
  __typename?: 'UserEntity'
  avatar?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  deleted?: Maybe<Scalars['DateTime']>
  deletedBy?: Maybe<Scalars['String']>
  email: Scalars['String']
  emailVerified?: Maybe<Scalars['Boolean']>
  fullName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  privilege?: Maybe<Array<Privilege>>
  roles?: Maybe<Array<RoleEntity>>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UserOtp = {
  __typename?: 'UserOtp'
  createdAt: Scalars['DateTime']
  expiredAt: Scalars['DateTime']
  id: Scalars['ID']
  otp: Scalars['String']
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
}

export type UserProfile = {
  __typename?: 'UserProfile'
  aboutMe?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  experience?: Maybe<Scalars['String']>
  id: Scalars['ID']
  user: User
  userId: Scalars['String']
}

export enum WaitTimeForSessionEntry {
  Normal = 'Normal',
  Quick = 'Quick',
  TooLong = 'TooLong',
}

export type EndCounselSessionInput = {
  roomId: Scalars['UUID']
}

export type GetFeedbackInput = {
  id: Scalars['String']
  relations?: Maybe<Array<Scalars['String']>>
}

export type GetFeedbackOutput = {
  __typename?: 'getFeedbackOutput'
  callHistoryId: Scalars['String']
  counselHistory: CounselHistory
  createdAt: Scalars['DateTime']
  feedbackOrNote: Scalars['String']
  id: Scalars['ID']
  improvementSuggestions?: Maybe<Scalars['String']>
  rating: Scalars['Int']
  sessionFeedbackTags?: Maybe<Array<Scalars['String']>>
  sessionReferenceSatisfaction?: Maybe<Array<Scalars['Int']>>
  updatedAt: Scalars['DateTime']
  user: User
  userId: Scalars['String']
  waitTimeForSessionEntry: Scalars['Int']
}

/** A GraphQL Schema defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, as well as the entry points for query, mutation, and subscription operations. */
export type __Schema = {
  __typename?: '__Schema'
  description?: Maybe<Scalars['String']>
  /** A list of all types supported by this server. */
  types: Array<__Type>
  /** The type that query operations will be rooted at. */
  queryType: __Type
  /** If this server supports mutation, the type that mutation operations will be rooted at. */
  mutationType?: Maybe<__Type>
  /** If this server support subscription, the type that subscription operations will be rooted at. */
  subscriptionType?: Maybe<__Type>
  /** A list of all directives supported by this server. */
  directives: Array<__Directive>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type'
  kind: __TypeKind
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  specifiedByUrl?: Maybe<Scalars['String']>
  fields?: Maybe<Array<__Field>>
  interfaces?: Maybe<Array<__Type>>
  possibleTypes?: Maybe<Array<__Type>>
  enumValues?: Maybe<Array<__EnumValue>>
  inputFields?: Maybe<Array<__InputValue>>
  ofType?: Maybe<__Type>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL',
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  args: Array<__InputValue>
  type: __Type
  isDeprecated: Scalars['Boolean']
  deprecationReason?: Maybe<Scalars['String']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  type: __Type
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason?: Maybe<Scalars['String']>
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason?: Maybe<Scalars['String']>
}

/**
 * A Directive provides a way to describe alternate runtime execution and type validation behavior in a GraphQL document.
 *
 * In some cases, you need to provide options to alter GraphQL's execution behavior in ways field arguments will not suffice, such as conditionally including or skipping a field. Directives provide this by describing additional information to the executor.
 */
export type __Directive = {
  __typename?: '__Directive'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  isRepeatable: Scalars['Boolean']
  locations: Array<__DirectiveLocation>
  args: Array<__InputValue>
}

/** A Directive can be adjacent to many parts of the GraphQL language, a __DirectiveLocation describes one such possible adjacencies. */
export enum __DirectiveLocation {
  /** Location adjacent to a query operation. */
  Query = 'QUERY',
  /** Location adjacent to a mutation operation. */
  Mutation = 'MUTATION',
  /** Location adjacent to a subscription operation. */
  Subscription = 'SUBSCRIPTION',
  /** Location adjacent to a field. */
  Field = 'FIELD',
  /** Location adjacent to a fragment definition. */
  FragmentDefinition = 'FRAGMENT_DEFINITION',
  /** Location adjacent to a fragment spread. */
  FragmentSpread = 'FRAGMENT_SPREAD',
  /** Location adjacent to an inline fragment. */
  InlineFragment = 'INLINE_FRAGMENT',
  /** Location adjacent to a variable definition. */
  VariableDefinition = 'VARIABLE_DEFINITION',
  /** Location adjacent to a schema definition. */
  Schema = 'SCHEMA',
  /** Location adjacent to a scalar definition. */
  Scalar = 'SCALAR',
  /** Location adjacent to an object type definition. */
  Object = 'OBJECT',
  /** Location adjacent to a field definition. */
  FieldDefinition = 'FIELD_DEFINITION',
  /** Location adjacent to an argument definition. */
  ArgumentDefinition = 'ARGUMENT_DEFINITION',
  /** Location adjacent to an interface definition. */
  Interface = 'INTERFACE',
  /** Location adjacent to a union definition. */
  Union = 'UNION',
  /** Location adjacent to an enum definition. */
  Enum = 'ENUM',
  /** Location adjacent to an enum value definition. */
  EnumValue = 'ENUM_VALUE',
  /** Location adjacent to an input object type definition. */
  InputObject = 'INPUT_OBJECT',
  /** Location adjacent to an input object field definition. */
  InputFieldDefinition = 'INPUT_FIELD_DEFINITION',
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & { me: { __typename?: 'MeOutput' } & Pick<MeOutput, 'id' | 'username' | 'scp' | 'sub'> }

export type SigninMutationVariables = Exact<{
  input: SigninInput
}>

export type SigninMutation = { __typename?: 'Mutation' } & { signin: { __typename?: 'SigninOutput' } & Pick<SigninOutput, 'id' | 'token' | 'refreshToken' | 'email' | 'scp'> }

export type SignupMutationVariables = Exact<{
  input: SignupInput
}>

export type SignupMutation = { __typename?: 'Mutation' } & { signup: { __typename?: 'SignupOutput' } & Pick<SignupOutput, 'id' | 'token' | 'refreshToken' | 'email' | 'scp'> }

export type CreateQuestionMutationVariables = Exact<{
  input: CreateQuestionInput
}>

export type CreateQuestionMutation = { __typename?: 'Mutation' } & {
  createQuestion: { __typename?: 'CreateQuestionOutput' } & {
    data: { __typename?: 'Question' } & Pick<Question, 'id' | 'title' | 'type' | 'createdAt'> & {
        answer?: Maybe<Array<{ __typename?: 'Answer' } & Pick<Answer, 'id' | 'isCorrect' | 'title' | 'questionId'> & { question: { __typename?: 'Question' } & Pick<Question, 'id'> }>>
        module: { __typename?: 'Module' } & Pick<Module, 'id' | 'title'>
      }
  }
}

export type DeleteQuestionMutationVariables = Exact<{
  input: DeleteQuestionInput
}>

export type DeleteQuestionMutation = { __typename?: 'Mutation' } & { deleteQuestion: { __typename?: 'DeleteQuestionOutput' } & { data: { __typename?: 'Question' } & Pick<Question, 'id'> } }

export type GetQuestionQueryVariables = Exact<{
  input: GetQuestionInput
}>

export type GetQuestionQuery = { __typename?: 'Query' } & {
  getQuestion: { __typename?: 'GetQuestionOutput' } & {
    data: { __typename?: 'Question' } & Pick<Question, 'id' | 'type' | 'createdAt'> & {
        module: { __typename?: 'Module' } & Pick<Module, 'id' | 'title'>
        answer?: Maybe<Array<{ __typename?: 'Answer' } & Pick<Answer, 'id' | 'isCorrect' | 'title'> & { question: { __typename?: 'Question' } & Pick<Question, 'id' | 'title'> }>>
      }
  }
}

export type GetQuestionsQueryVariables = Exact<{
  input: GetQuestionsInput
}>

export type GetQuestionsQuery = { __typename?: 'Query' } & {
  getQuestions: { __typename?: 'GetQuestionsOutput' } & {
    data: Array<
      { __typename?: 'Question' } & Pick<Question, 'id' | 'type' | 'createdAt'> & {
          module: { __typename?: 'Module' } & Pick<Module, 'id' | 'title'>
          answer?: Maybe<Array<{ __typename?: 'Answer' } & Pick<Answer, 'id' | 'isCorrect' | 'title'> & { question: { __typename?: 'Question' } & Pick<Question, 'id' | 'title'> }>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type UpdateQuestionMutationVariables = Exact<{
  input: UpdateQuestionInput
}>

export type UpdateQuestionMutation = { __typename?: 'Mutation' } & {
  updateQuestion: { __typename?: 'UpdateQuestionOutput' } & {
    data: { __typename?: 'Question' } & Pick<Question, 'id' | 'title' | 'type' | 'updatedAt'> & {
        answer?: Maybe<Array<{ __typename?: 'Answer' } & Pick<Answer, 'id' | 'isCorrect' | 'title' | 'questionId'> & { question: { __typename?: 'Question' } & Pick<Question, 'id'> }>>
      }
  }
}

export type GetBannedUsersQueryVariables = Exact<{
  input: GetBannedUsersInput
}>

export type GetBannedUsersQuery = { __typename?: 'Query' } & {
  getBannedUsers: { __typename?: 'GetBannedUsersOutput' } & {
    data: Array<
      { __typename?: 'BannedUserEntity' } & Pick<BannedUserEntity, 'id' | 'banStartDate' | 'banEndDate' | 'reason' | 'createdAt'> & {
          banned: { __typename?: 'UserEntity' } & Pick<UserEntity, 'id' | 'avatar' | 'email'>
          createdBy: { __typename?: 'UserEntity' } & Pick<UserEntity, 'id' | 'avatar' | 'email'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type CreateBlogMutationVariables = Exact<{
  input: CreateBlogInput
}>

export type CreateBlogMutation = { __typename?: 'Mutation' } & { createBlog: { __typename?: 'CreateBlogOutput' } & Pick<CreateBlogOutput, 'id'> }

export type DeleteBlogMutationVariables = Exact<{
  input: DeleteBlogInput
}>

export type DeleteBlogMutation = { __typename?: 'Mutation' } & { deleteBlog: { __typename?: 'DeleteBlogOutput' } & Pick<DeleteBlogOutput, 'id'> }

export type GetBlogQueryVariables = Exact<{
  input: GetBlogInput
}>

export type GetBlogQuery = { __typename?: 'Query' } & {
  getBlog: { __typename?: 'GetBlogOutput' } & {
    data: { __typename?: 'Blog' } & Pick<Blog, 'id' | 'title' | 'content' | 'createdAt' | 'publishDate' | 'description' | 'slug' | 'thumbnail' | 'status'> & {
        blogSeo?: Maybe<
          { __typename?: 'BlogMetadata' } & Pick<
            BlogMetadata,
            | 'seoTitle'
            | 'seoDescription'
            | 'seoThumbnailImage'
            | 'keyword'
            | 'facebookTitle'
            | 'facebookDescription'
            | 'facebookThumbnailImage'
            | 'twitterTitle'
            | 'twitterDescription'
            | 'twitterThumbnailImage'
          >
        >
        tags?: Maybe<Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>>
        categories?: Maybe<Array<{ __typename?: 'BlogCategory' } & Pick<BlogCategory, 'id' | 'name'>>>
      }
  }
}

export type GetBlogsQueryVariables = Exact<{
  input: GetBlogsInput
}>

export type GetBlogsQuery = { __typename?: 'Query' } & {
  getBlogs: { __typename?: 'GetBlogsOutput' } & {
    data: Array<
      { __typename?: 'Blog' } & Pick<Blog, 'id' | 'title' | 'description' | 'content' | 'slug' | 'createdAt' | 'publishDate'> & {
          tags?: Maybe<Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>>
          categories?: Maybe<Array<{ __typename?: 'BlogCategory' } & Pick<BlogCategory, 'id' | 'name'>>>
          createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'avatar' | 'fullName' | 'email'>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetDeletedBlogsQueryVariables = Exact<{
  input: GetBlogsInput
}>

export type GetDeletedBlogsQuery = { __typename?: 'Query' } & {
  getDeletedBlogs: { __typename?: 'GetBlogsOutput' } & {
    data: Array<
      { __typename?: 'Blog' } & Pick<Blog, 'id' | 'title' | 'content' | 'createdAt' | 'publishDate' | 'slug'> & {
          createdBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'avatar' | 'fullName' | 'email'>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverBlogMutationVariables = Exact<{
  input: RecoverBlogInput
}>

export type RecoverBlogMutation = { __typename?: 'Mutation' } & { recoverBlog: { __typename?: 'RecoverBlogOutput' } & Pick<RecoverBlogOutput, 'id'> }

export type UpdateBlogMutationVariables = Exact<{
  input: UpdateBlogInput
}>

export type UpdateBlogMutation = { __typename?: 'Mutation' } & { updateBlog: { __typename?: 'UpdateBlogOutput' } & Pick<UpdateBlogOutput, 'id'> }

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput
}>

export type CreateCategoryMutation = { __typename?: 'Mutation' } & { createCategory: { __typename?: 'CreateCategoryOutput' } & Pick<CreateCategoryOutput, 'id'> }

export type DeleteCategoryMutationVariables = Exact<{
  input: DeleteCategoryInput
}>

export type DeleteCategoryMutation = { __typename?: 'Mutation' } & { deleteCategory: { __typename?: 'DeleteCategoryOutput' } & Pick<DeleteCategoryOutput, 'id'> }

export type GetCategoryQueryVariables = Exact<{
  input: GetCategoryInput
}>

export type GetCategoryQuery = { __typename?: 'Query' } & {
  getCategory: { __typename?: 'GetCategoryOutput' } & Pick<GetCategoryOutput, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt' | 'parentId'>
}

export type GetDeletedCategoriesQueryVariables = Exact<{
  input: GetCategoriesInput
}>

export type GetDeletedCategoriesQuery = { __typename?: 'Query' } & {
  getDeletedCategories: { __typename?: 'GetCategoriesOutput' } & {
    data: Array<
      { __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'> & {
          children?: Maybe<
            Array<
              { __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'> & {
                  children?: Maybe<Array<{ __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'>>>
                }
            >
          >
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetCategoriesQueryVariables = Exact<{
  input: GetCategoriesInput
}>

export type GetCategoriesQuery = { __typename?: 'Query' } & {
  getCategories: { __typename?: 'GetCategoriesOutput' } & {
    data: Array<
      { __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'> & {
          children?: Maybe<
            Array<
              { __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'> & {
                  children?: Maybe<Array<{ __typename?: 'CategoryEntity' } & Pick<CategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'createdAt' | 'updatedAt'>>>
                }
            >
          >
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverCategoryMutationVariables = Exact<{
  input: RecoverCategoryInput
}>

export type RecoverCategoryMutation = { __typename?: 'Mutation' } & { recoverCategory: { __typename?: 'RecoverCategoryOutput' } & Pick<RecoverCategoryOutput, 'id'> }

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput
}>

export type UpdateCategoryMutation = { __typename?: 'Mutation' } & { updateCategory: { __typename?: 'UpdateCategoryOutput' } & Pick<UpdateCategoryOutput, 'id'> }

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput
}>

export type DeleteCommentMutation = { __typename?: 'Mutation' } & { deleteComment: { __typename?: 'DeleteCommentOutput' } & Pick<DeleteCommentOutput, 'id'> }

export type GetCommentsQueryVariables = Exact<{
  input: GetCommentsInput
}>

export type GetCommentsQuery = { __typename?: 'Query' } & {
  getComments: { __typename?: 'GetCommentsOutput' } & {
    data: Array<
      { __typename?: 'GetCommentItem' } & Pick<GetCommentItem, 'id' | 'content' | 'status'> & {
          blog?: Maybe<{ __typename?: 'Blog' } & Pick<Blog, 'title' | 'slug'>>
          user?: Maybe<{ __typename?: 'User' } & Pick<User, 'avatar' | 'email' | 'fullName'>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetDeletedCommentsQueryVariables = Exact<{
  input: GetCommentsInput
}>

export type GetDeletedCommentsQuery = { __typename?: 'Query' } & {
  getDeletedComments: { __typename?: 'GetCommentsOutput' } & {
    data: Array<
      { __typename?: 'GetCommentItem' } & Pick<GetCommentItem, 'id' | 'content' | 'status'> & {
          blog?: Maybe<{ __typename?: 'Blog' } & Pick<Blog, 'title' | 'slug'>>
          user?: Maybe<{ __typename?: 'User' } & Pick<User, 'avatar' | 'email' | 'fullName'>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverCommentMutationVariables = Exact<{
  input: RecoverCommentInput
}>

export type RecoverCommentMutation = { __typename?: 'Mutation' } & { recoverComment: { __typename?: 'RecoverCommentOutput' } & Pick<RecoverCommentOutput, 'id'> }

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput
}>

export type UpdateCommentMutation = { __typename?: 'Mutation' } & { updateComment: { __typename?: 'UpdateCommentOutput' } & Pick<UpdateCommentOutput, 'id'> }

export type GetFormRegistersQueryVariables = Exact<{
  input: GetFormRegisterInput
}>

export type GetFormRegistersQuery = { __typename?: 'Query' } & {
  getFormRegisters: { __typename?: 'GetFormRegisterOutput' } & {
    data?: Maybe<
      Array<
        { __typename?: 'FormCounsel' } & Pick<
          FormCounsel,
          'id' | 'religion' | 'ethnicity' | 'familyPosition' | 'relativePhoneNumber' | 'issueDescription' | 'expectResult' | 'expectedTime' | 'note'
        > & { user: { __typename?: 'User' } & Pick<User, 'fullName' | 'gender' | 'email' | 'city' | 'phone'> }
      >
    >
  }
}

export type UpdateNoteFormRegisterMutationVariables = Exact<{
  input: UpdateNoteFormRegisterInput
}>

export type UpdateNoteFormRegisterMutation = { __typename?: 'Mutation' } & {
  updateNoteFormRegister: { __typename?: 'UpdateNoteFormRegisterOutput' } & { data: { __typename?: 'FormCounsel' } & Pick<FormCounsel, 'id'> }
}

export type CreateCourseCategoryMutationVariables = Exact<{
  input: CreateCourseCategoryInput
}>

export type CreateCourseCategoryMutation = { __typename?: 'Mutation' } & { createCourseCategory: { __typename?: 'CreateCourseCategoryOutput' } & Pick<CreateCourseCategoryOutput, 'id'> }

export type DeleteCourseCategoryMutationVariables = Exact<{
  input: DeleteCourseCategoryInput
}>

export type DeleteCourseCategoryMutation = { __typename?: 'Mutation' } & { deleteCourseCategory: { __typename?: 'DeleteCourseCategoryOutput' } & Pick<DeleteCourseCategoryOutput, 'id'> }

export type GetCourseCategoriesQueryVariables = Exact<{
  input: GetCourseCategoriesInput
}>

export type GetCourseCategoriesQuery = { __typename?: 'Query' } & {
  getCourseCategories: { __typename?: 'GetCourseCategoriesOutput' } & {
    data: Array<{ __typename?: 'CourseCategoryEntity' } & Pick<CourseCategoryEntity, 'id' | 'name' | 'description' | 'slug' | 'image' | 'createdAt' | 'updatedAt'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetCourseCategoryQueryVariables = Exact<{
  input: GetCourseCategoryInput
}>

export type GetCourseCategoryQuery = { __typename?: 'Query' } & {
  getCourseCategory: { __typename?: 'GetCourseCategoryOutput' } & { data: { __typename?: 'CourseCategoryEntity' } & Pick<CourseCategoryEntity, 'id' | 'name' | 'slug' | 'description' | 'image'> }
}

export type GetDeletedCourseCategoriesQueryVariables = Exact<{
  input: GetCourseCategoriesInput
}>

export type GetDeletedCourseCategoriesQuery = { __typename?: 'Query' } & {
  getDeletedCourseCategories: { __typename?: 'GetCourseCategoriesOutput' } & {
    data: Array<{ __typename?: 'CourseCategoryEntity' } & Pick<CourseCategoryEntity, 'id' | 'name' | 'description' | 'slug' | 'image' | 'createdAt' | 'updatedAt'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverCourseCategoryMutationVariables = Exact<{
  input: RecoverCourseCategoryInput
}>

export type RecoverCourseCategoryMutation = { __typename?: 'Mutation' } & { recoverCourseCategory: { __typename?: 'RecoverCourseCategoryOutput' } & Pick<RecoverCourseCategoryOutput, 'id'> }

export type UpdateCourseCategoryMutationVariables = Exact<{
  input: UpdateCourseCategoryInput
}>

export type UpdateCourseCategoryMutation = { __typename?: 'Mutation' } & { updateCourseCategory: { __typename?: 'UpdateCourseCategoryOutput' } & Pick<UpdateCourseCategoryOutput, 'id'> }

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseInput
}>

export type CreateCourseMutation = { __typename?: 'Mutation' } & { createCourse: { __typename?: 'CreateCourseOutput' } & Pick<CreateCourseOutput, 'id'> }

export type DeleteCourseMutationVariables = Exact<{
  input: DeleteCourseInput
}>

export type DeleteCourseMutation = { __typename?: 'Mutation' } & { deleteCourse: { __typename?: 'DeleteCourseOutput' } & Pick<DeleteCourseOutput, 'id'> }

export type GetCourseQueryVariables = Exact<{
  input: GetCourseInput
}>

export type GetCourseQuery = { __typename?: 'Query' } & {
  getCourse: { __typename?: 'GetCourseOutput' } & {
    data: { __typename?: 'Course' } & Pick<Course, 'id' | 'title' | 'description' | 'thumbnail'> & {
        modules?: Maybe<Array<{ __typename?: 'Module' } & Pick<Module, 'id' | 'title' | 'description' | 'order' | 'createdAt' | 'updatedAt'>>>
      }
  }
}

export type GetCourseDetailQueryVariables = Exact<{
  input: GetCourseInput
}>

export type GetCourseDetailQuery = { __typename?: 'Query' } & {
  getCourse: { __typename?: 'GetCourseOutput' } & {
    data: { __typename?: 'Course' } & Pick<Course, 'id' | 'title' | 'slug' | 'description' | 'thumbnail' | 'price' | 'publishDate'> & {
        categories?: Maybe<Array<{ __typename?: 'CourseCategory' } & Pick<CourseCategory, 'id'>>>
      }
  }
}

export type GetCoursesQueryVariables = Exact<{
  input: GetCoursesInput
}>

export type GetCoursesQuery = { __typename?: 'Query' } & {
  getCourses: { __typename?: 'GetCoursesOutput' } & {
    data: Array<{ __typename?: 'CourseEntity' } & Pick<CourseEntity, 'id' | 'title' | 'slug' | 'description' | 'thumbnail' | 'publishDate' | 'price' | 'createdAt' | 'updatedAt'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetDeletedCoursesQueryVariables = Exact<{
  input: GetCoursesInput
}>

export type GetDeletedCoursesQuery = { __typename?: 'Query' } & {
  getDeletedCourses: { __typename?: 'GetCoursesOutput' } & {
    data: Array<{ __typename?: 'CourseEntity' } & Pick<CourseEntity, 'id' | 'title' | 'slug' | 'description' | 'thumbnail' | 'publishDate' | 'price' | 'createdAt' | 'updatedAt'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverCourseMutationVariables = Exact<{
  input: RecoverCourseInput
}>

export type RecoverCourseMutation = { __typename?: 'Mutation' } & { recoverCourse: { __typename?: 'RecoverCourseOutput' } & Pick<RecoverCourseOutput, 'id'> }

export type UpdateCourseMutationVariables = Exact<{
  input: UpdateCourseInput
}>

export type UpdateCourseMutation = { __typename?: 'Mutation' } & { updateCourse: { __typename?: 'UpdateCourseOutput' } & Pick<UpdateCourseOutput, 'id'> }

export type CreateModuleMutationVariables = Exact<{
  input: CreateModuleInput
}>

export type CreateModuleMutation = { __typename?: 'Mutation' } & { createModule: { __typename?: 'CreateModuleOutput' } & { data: { __typename?: 'Module' } & Pick<Module, 'id'> } }

export type DeleteModuleMutationVariables = Exact<{
  input: DeleteModuleInput
}>

export type DeleteModuleMutation = { __typename?: 'Mutation' } & { deleteModule: { __typename?: 'DeleteModuleOutput' } & { data: { __typename?: 'Module' } & Pick<Module, 'id'> } }

export type GetModuleQueryVariables = Exact<{
  input: GetModuleInput
}>

export type GetModuleQuery = { __typename?: 'Query' } & {
  getModule: { __typename?: 'GetModuleOutput' } & { data: { __typename?: 'Module' } & Pick<Module, 'id' | 'title' | 'description' | 'slug' | 'status'> }
}

export type GetModuleDetailQueryVariables = Exact<{
  input: GetModuleInput
}>

export type GetModuleDetailQuery = { __typename?: 'Query' } & {
  getModule: { __typename?: 'GetModuleOutput' } & {
    data: { __typename?: 'Module' } & Pick<Module, 'id' | 'title' | 'description'> & {
        subModules?: Maybe<
          Array<
            { __typename?: 'SubModule' } & Pick<SubModule, 'id' | 'type' | 'title' | 'description' | 'order' | 'createdAt' | 'updatedAt'> & {
                module?: Maybe<{ __typename?: 'Module' } & Pick<Module, 'title'>>
                video?: Maybe<{ __typename?: 'SubModuleVideo' } & Pick<SubModuleVideo, 'id' | 'title' | 'duration' | 'source' | 'url'>>
                richText?: Maybe<{ __typename?: 'SubModuleRichText' } & Pick<SubModuleRichText, 'id' | 'title' | 'content'>>
              }
          >
        >
      }
  }
}

export type GetModulesQueryVariables = Exact<{
  input: GetModulesInput
}>

export type GetModulesQuery = { __typename?: 'Query' } & {
  getModules: { __typename?: 'GetModulesOutput' } & { data: Array<{ __typename?: 'Module' } & Pick<Module, 'id' | 'title' | 'description' | 'slug' | 'status'>> }
}

export type UpdateModuleMutationVariables = Exact<{
  input: UpdateModuleInput
}>

export type UpdateModuleMutation = { __typename?: 'Mutation' } & { updateModule: { __typename?: 'UpdateModuleOutput' } & { data: { __typename?: 'Module' } & Pick<Module, 'id'> } }

export type CreateSubModuleMutationVariables = Exact<{
  input: CreateSubModuleInput
}>

export type CreateSubModuleMutation = { __typename?: 'Mutation' } & { createSubModule: { __typename?: 'CreateSubModuleOutput' } & { data: { __typename?: 'SubModule' } & Pick<SubModule, 'id'> } }

export type DeleteSubModuleMutationVariables = Exact<{
  input: DeleteSubModuleInput
}>

export type DeleteSubModuleMutation = { __typename?: 'Mutation' } & { deleteSubModule: { __typename?: 'DeleteSubModuleOutput' } & { data: { __typename?: 'SubModule' } & Pick<SubModule, 'id'> } }

export type GetSubModuleQueryVariables = Exact<{
  input: GetSubModuleInput
}>

export type GetSubModuleQuery = { __typename?: 'Query' } & {
  getSubModule: { __typename?: 'GetSubModuleOutput' } & {
    data: { __typename?: 'SubModule' } & Pick<SubModule, 'id' | 'title' | 'slug' | 'description' | 'type' | 'order' | 'moduleId'> & {
        richText?: Maybe<{ __typename?: 'SubModuleRichText' } & Pick<SubModuleRichText, 'id' | 'title' | 'content'>>
        video?: Maybe<{ __typename?: 'SubModuleVideo' } & Pick<SubModuleVideo, 'id' | 'title' | 'duration' | 'source' | 'url'>>
      }
  }
}

export type RecoverSubModuleMutationVariables = Exact<{
  input: RecoverSubModuleInput
}>

export type RecoverSubModuleMutation = { __typename?: 'Mutation' } & { recoverSubModule: { __typename?: 'RecoverSubModuleOutput' } & { data: { __typename?: 'SubModule' } & Pick<SubModule, 'id'> } }

export type UpdateSubModuleMutationVariables = Exact<{
  input: UpdateSubModuleInput
}>

export type UpdateSubModuleMutation = { __typename?: 'Mutation' } & { updateSubModule: { __typename?: 'UpdateSubModuleOutput' } & { data: { __typename?: 'SubModule' } & Pick<SubModule, 'id'> } }

export type DeleteListenerRegisterMutationVariables = Exact<{
  input: DeleteListenerRegisterInput
}>

export type DeleteListenerRegisterMutation = { __typename?: 'Mutation' } & { deleteListenerRegister: { __typename?: 'DeleteListenerRegisterOutput' } & Pick<DeleteListenerRegisterOutput, 'id'> }

export type GetDeletedListenersRegisterQueryVariables = Exact<{
  input: GetDeletedListenersRegisterInput
}>

export type GetDeletedListenersRegisterQuery = { __typename?: 'Query' } & {
  getDeletedListenersRegister: { __typename?: 'GetDeletedListenersRegisterOutput' } & {
    data: Array<
      { __typename?: 'GetListenerRegisterOutput' } & Pick<GetListenerRegisterOutput, 'adsSource' | 'id' | 'reason' | 'status' | 'updatedAt' | 'createdAt'> & {
          user: { __typename?: 'User' } & Pick<User, 'fullName'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetListenersRegisterQueryVariables = Exact<{
  input: GetListenersRegisterInput
}>

export type GetListenersRegisterQuery = { __typename?: 'Query' } & {
  getListenersRegister: { __typename?: 'GetListenersRegisterOutput' } & {
    data: Array<
      { __typename?: 'GetListenerRegisterOutput' } & Pick<GetListenerRegisterOutput, 'adsSource' | 'id' | 'reason' | 'status' | 'updatedAt' | 'createdBy' | 'createdAt'> & {
          user: { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverListenerRegisterMutationVariables = Exact<{
  input: RecoverListenerRegisterInput
}>

export type RecoverListenerRegisterMutation = { __typename?: 'Mutation' } & { recoverListenerRegister: { __typename?: 'RecoverListenerRegisterOutput' } & Pick<RecoverListenerRegisterOutput, 'id'> }

export type UpdateListenerRegisterMutationVariables = Exact<{
  input: UpdateListenerRegisterInput
}>

export type UpdateListenerRegisterMutation = { __typename?: 'Mutation' } & { updateListenerRegister: { __typename?: 'UpdateListenerRegisterOutput' } & Pick<UpdateListenerRegisterOutput, 'id'> }

export type DeleteReportTicketMutationVariables = Exact<{
  input: DeleteReportTicketInput
}>

export type DeleteReportTicketMutation = { __typename?: 'Mutation' } & { deleteReportTicket: { __typename?: 'DeleteReportTicketOutput' } & Pick<DeleteReportTicketOutput, 'id'> }

export type GetDeletedReportTicketsQueryVariables = Exact<{
  input: GetReportTicketsInput
}>

export type GetDeletedReportTicketsQuery = { __typename?: 'Query' } & {
  getDeletedReportTickets: { __typename?: 'GetReportTicketsOutput' } & {
    data: Array<
      { __typename?: 'ReportTicket' } & Pick<ReportTicket, 'id' | 'subject' | 'description' | 'status'> & {
          reportedUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'avatar'>>
          createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'avatar'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetReportTicketsQueryVariables = Exact<{
  input: GetReportTicketsInput
}>

export type GetReportTicketsQuery = { __typename?: 'Query' } & {
  getReportTickets: { __typename?: 'GetReportTicketsOutput' } & {
    data: Array<
      { __typename?: 'ReportTicket' } & Pick<ReportTicket, 'id' | 'subject' | 'description' | 'status'> & {
          reportedUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'avatar'>>
          createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'fullName' | 'email' | 'avatar'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverReportTicketMutationVariables = Exact<{
  input: RecoverReportTicketInput
}>

export type RecoverReportTicketMutation = { __typename?: 'Mutation' } & { recoverReportTicket: { __typename?: 'RecoverReportTicketOutput' } & Pick<RecoverReportTicketOutput, 'id'> }

export type UpdateReportTicketMutationVariables = Exact<{
  input: UpdateReportTicketInput
}>

export type UpdateReportTicketMutation = { __typename?: 'Mutation' } & { updateReportTicket: { __typename?: 'UpdateReportTicketOutput' } & Pick<UpdateReportTicketOutput, 'id'> }

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput
}>

export type CreateRoleMutation = { __typename?: 'Mutation' } & { createRole: { __typename?: 'CreateRoleOutput' } & Pick<CreateRoleOutput, 'id'> }

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput
}>

export type DeleteRoleMutation = { __typename?: 'Mutation' } & { deleteRole: { __typename?: 'DeleteRoleOutput' } & Pick<DeleteRoleOutput, 'id'> }

export type GetDeletedRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetDeletedRolesQuery = { __typename?: 'Query' } & { getDeletedRoles: Array<{ __typename?: 'GetDeletedRolesOutput' } & Pick<GetDeletedRolesOutput, 'id' | 'title' | 'isDefault' | 'scp'>> }

export type PermissionsQueryVariables = Exact<{ [key: string]: never }>

export type PermissionsQuery = { __typename?: 'Query' } & {
  __schema: { __typename?: '__Schema' } & {
    queryType: { __typename?: '__Type' } & { fields?: Maybe<Array<{ __typename?: '__Field' } & Pick<__Field, 'name' | 'description'>>> }
    mutationType?: Maybe<{ __typename?: '__Type' } & { fields?: Maybe<Array<{ __typename?: '__Field' } & Pick<__Field, 'name' | 'description'>>> }>
    subscriptionType?: Maybe<{ __typename?: '__Type' } & { fields?: Maybe<Array<{ __typename?: '__Field' } & Pick<__Field, 'name' | 'description'>>> }>
  }
}

export type GetRoleQueryVariables = Exact<{
  input: GetRoleInput
}>

export type GetRoleQuery = { __typename?: 'Query' } & { getRole: { __typename?: 'GetRoleOutput' } & Pick<GetRoleOutput, 'id' | 'title' | 'isDefault' | 'scp' | 'code'> }

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>

export type GetRolesQuery = { __typename?: 'Query' } & { getRoles: Array<{ __typename?: 'GetRolesOutput' } & Pick<GetRolesOutput, 'id' | 'title' | 'isDefault' | 'scp' | 'code'>> }

export type RecoverRoleMutationVariables = Exact<{
  input: RecoverRoleInput
}>

export type RecoverRoleMutation = { __typename?: 'Mutation' } & { recoverRole: { __typename?: 'RecoverRoleOutput' } & Pick<RecoverRoleOutput, 'id'> }

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput
}>

export type UpdateRoleMutation = { __typename?: 'Mutation' } & { updateRole: { __typename?: 'UpdateRoleOutput' } & Pick<UpdateRoleOutput, 'id'> }

export type CreateRoomInvitationMutationVariables = Exact<{
  input: CreateRoomInvitationInput
}>

export type CreateRoomInvitationMutation = { __typename?: 'Mutation' } & {
  createRoomInvitation: { __typename?: 'CreateRoomInvitationOutput' } & { data: { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id'> }
}

export type DeleteRoomInvitationMutationVariables = Exact<{
  input: DeleteRoomInvitationInput
}>

export type DeleteRoomInvitationMutation = { __typename?: 'Mutation' } & {
  deleteRoomInvitation: { __typename?: 'DeleteRoomInvitationOutput' } & {
    data: { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'ownerRoomId' | 'title' | 'slug' | 'deleted' | 'deletedBy'> & {
        participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email'>>>
      }
  }
}

export type GetDeletedRoomInvitationsQueryVariables = Exact<{
  input: GetRoomInvitationsInput
}>

export type GetDeletedRoomInvitationsQuery = { __typename?: 'Query' } & {
  getDeletedRoomInvitations: { __typename?: 'GetRoomInvitationsOutput' } & {
    data: Array<
      { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'title' | 'slug' | 'roomType' | 'startTime' | 'createdAt'> & {
          participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>>>
          ownerRoom: { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>
          createdBy: { __typename?: 'User' } & Pick<User, 'email' | 'fullName'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetRoomInvitationQueryVariables = Exact<{
  input: GetRoomInvitationInput
}>

export type GetRoomInvitationQuery = { __typename?: 'Query' } & {
  getRoomInvitation: { __typename?: 'GetRoomInvitationOutput' } & {
    data: { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'ownerRoomId' | 'title' | 'slug' | 'roomType' | 'startTime' | 'createdAt'> & {
        participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>>>
        createdBy: { __typename?: 'User' } & Pick<User, 'email' | 'fullName'>
      }
  }
}

export type GetRoomInvitationsQueryVariables = Exact<{
  input: GetRoomInvitationsInput
}>

export type GetRoomInvitationsQuery = { __typename?: 'Query' } & {
  getRoomInvitations: { __typename?: 'GetRoomInvitationsOutput' } & {
    data: Array<
      { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'title' | 'slug' | 'roomType' | 'startTime' | 'createdAt'> & {
          participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>>>
          ownerRoom: { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'>
          createdBy: { __typename?: 'User' } & Pick<User, 'email' | 'fullName'>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverRoomInvitationMutationVariables = Exact<{
  input: RecoverRoomInvitationInput
}>

export type RecoverRoomInvitationMutation = { __typename?: 'Mutation' } & {
  recoverRoomInvitation: { __typename?: 'RecoverRoomInvitationOutput' } & {
    data: { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'ownerRoomId' | 'title' | 'slug' | 'deleted' | 'deletedBy'> & {
        participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email'>>>
      }
  }
}

export type UpdateRoomInvitationMutationVariables = Exact<{
  input: UpdateRoomInvitationInput
}>

export type UpdateRoomInvitationMutation = { __typename?: 'Mutation' } & {
  updateRoomInvitation: { __typename?: 'UpdateRoomInvitationOutput' } & {
    data: { __typename?: 'RoomInvitation' } & Pick<RoomInvitation, 'id' | 'ownerRoomId' | 'title' | 'slug'> & { participants?: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'email'>>> }
  }
}

export type CreateTagMutationVariables = Exact<{
  input: CreateTagInput
}>

export type CreateTagMutation = { __typename?: 'Mutation' } & { createTag: { __typename?: 'CreateTagOutput' } & Pick<CreateTagOutput, 'id'> }

export type DeleteTagMutationVariables = Exact<{
  input: DeleteTagInput
}>

export type DeleteTagMutation = { __typename?: 'Mutation' } & { deleteTag: { __typename?: 'DeleteTagOutput' } & Pick<DeleteTagOutput, 'id'> }

export type GetDeletedTagsQueryVariables = Exact<{
  input: GetTagsInput
}>

export type GetDeletedTagsQuery = { __typename?: 'Query' } & {
  getDeletedTags: { __typename?: 'GetTagsOutput' } & {
    data: Array<{ __typename?: 'TagEntity' } & Pick<TagEntity, 'id' | 'name'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetTagQueryVariables = Exact<{
  input: GetTagInput
}>

export type GetTagQuery = { __typename?: 'Query' } & { getTag: { __typename?: 'GetTagOutput' } & Pick<GetTagOutput, 'id' | 'name'> }

export type GetTagsQueryVariables = Exact<{
  input: GetTagsInput
}>

export type GetTagsQuery = { __typename?: 'Query' } & {
  getTags: { __typename?: 'GetTagsOutput' } & {
    data: Array<{ __typename?: 'TagEntity' } & Pick<TagEntity, 'id' | 'name'>>
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverTagMutationVariables = Exact<{
  input: RecoverTagInput
}>

export type RecoverTagMutation = { __typename?: 'Mutation' } & { recoverTag: { __typename?: 'RecoverTagOutput' } & Pick<RecoverTagOutput, 'id'> }

export type UpdateTagMutationVariables = Exact<{
  input: UpdateTagInput
}>

export type UpdateTagMutation = { __typename?: 'Mutation' } & { updateTag: { __typename?: 'UpdateTagOutput' } & Pick<UpdateTagOutput, 'id'> }

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput
}>

export type DeleteUserMutation = { __typename?: 'Mutation' } & { deleteUser: { __typename?: 'DeleteUserOutput' } & Pick<DeleteUserOutput, 'id'> }

export type GetDeletedUsersQueryVariables = Exact<{
  input: GetUsersInput
}>

export type GetDeletedUsersQuery = { __typename?: 'Query' } & {
  getDeletedUsers: { __typename?: 'GetUsersOutput' } & {
    data: Array<
      { __typename?: 'UserEntity' } & Pick<UserEntity, 'id' | 'email' | 'fullName' | 'createdAt' | 'updatedAt'> & {
          roles?: Maybe<Array<{ __typename?: 'RoleEntity' } & Pick<RoleEntity, 'id' | 'title'>>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type GetUserQueryVariables = Exact<{
  input: GetUserInput
}>

export type GetUserQuery = { __typename?: 'Query' } & {
  getUser: { __typename?: 'GetUserOutput' } & {
    data: { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'fullName'> & { roles?: Maybe<Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'title'>>> }
  }
}

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput
}>

export type GetUsersQuery = { __typename?: 'Query' } & {
  getUsers: { __typename?: 'GetUsersOutput' } & {
    data: Array<
      { __typename?: 'UserEntity' } & Pick<UserEntity, 'id' | 'email' | 'fullName' | 'createdAt' | 'updatedAt'> & {
          roles?: Maybe<Array<{ __typename?: 'RoleEntity' } & Pick<RoleEntity, 'id' | 'title'>>>
        }
    >
    pagination: { __typename?: 'Pagination' } & Pick<Pagination, 'limit' | 'page' | 'totalCount'>
  }
}

export type RecoverUserMutationVariables = Exact<{
  input: RecoverUserInput
}>

export type RecoverUserMutation = { __typename?: 'Mutation' } & { recoverUser: { __typename?: 'RecoverUserOutput' } & Pick<RecoverUserOutput, 'id'> }

export type UpdateUserByAdminMutationVariables = Exact<{
  input: UpdateUserByAdminInput
}>

export type UpdateUserByAdminMutation = { __typename?: 'Mutation' } & { updateUserByAdmin: { __typename?: 'UpdateUserByAdminOutput' } & Pick<UpdateUserByAdminOutput, 'id'> }

export const MeDocument = gql`
  query me {
    me {
      id
      username
      scp
      sub
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const SigninDocument = gql`
  mutation signin($input: SigninInput!) {
    signin(input: $input) {
      id
      token
      refreshToken
      email
      scp
    }
  }
`
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options)
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>
export const SignupDocument = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input) {
      id
      token
      refreshToken
      email
      scp
    }
  }
`
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options)
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>
export const CreateQuestionDocument = gql`
  mutation createQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      data {
        id
        title
        type
        answer {
          id
          isCorrect
          question {
            id
          }
          title
          questionId
        }
        module {
          id
          title
        }
        createdAt
      }
    }
  }
`
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options)
}
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>
export const DeleteQuestionDocument = gql`
  mutation deleteQuestion($input: DeleteQuestionInput!) {
    deleteQuestion(input: $input) {
      data {
        id
      }
    }
  }
`
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options)
}
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>
export const GetQuestionDocument = gql`
  query getQuestion($input: GetQuestionInput!) {
    getQuestion(input: $input) {
      data {
        id
        type
        module {
          id
          title
        }
        answer {
          id
          isCorrect
          question {
            id
            title
          }
          title
        }
        createdAt
      }
    }
  }
`

/**
 * __useGetQuestionQuery__
 *
 * To run a query within a React component, call `useGetQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options)
}
export function useGetQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options)
}
export type GetQuestionQueryHookResult = ReturnType<typeof useGetQuestionQuery>
export type GetQuestionLazyQueryHookResult = ReturnType<typeof useGetQuestionLazyQuery>
export type GetQuestionQueryResult = Apollo.QueryResult<GetQuestionQuery, GetQuestionQueryVariables>
export const GetQuestionsDocument = gql`
  query getQuestions($input: GetQuestionsInput!) {
    getQuestions(input: $input) {
      data {
        id
        type
        module {
          id
          title
        }
        answer {
          id
          isCorrect
          question {
            id
            title
          }
          title
        }
        createdAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetQuestionsQuery__
 *
 * To run a query within a React component, call `useGetQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQuestionsQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options)
}
export function useGetQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsQuery, GetQuestionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetQuestionsQuery, GetQuestionsQueryVariables>(GetQuestionsDocument, options)
}
export type GetQuestionsQueryHookResult = ReturnType<typeof useGetQuestionsQuery>
export type GetQuestionsLazyQueryHookResult = ReturnType<typeof useGetQuestionsLazyQuery>
export type GetQuestionsQueryResult = Apollo.QueryResult<GetQuestionsQuery, GetQuestionsQueryVariables>
export const UpdateQuestionDocument = gql`
  mutation updateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      data {
        id
        title
        type
        answer {
          id
          isCorrect
          question {
            id
          }
          title
          questionId
        }
        updatedAt
      }
    }
  }
`
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options)
}
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>
export const GetBannedUsersDocument = gql`
  query getBannedUsers($input: GetBannedUsersInput!) {
    getBannedUsers(input: $input) {
      data {
        id
        banStartDate
        banEndDate
        reason
        banned {
          id
          avatar
          email
        }
        createdAt
        createdBy {
          id
          avatar
          email
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetBannedUsersQuery__
 *
 * To run a query within a React component, call `useGetBannedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannedUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBannedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetBannedUsersQuery, GetBannedUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBannedUsersQuery, GetBannedUsersQueryVariables>(GetBannedUsersDocument, options)
}
export function useGetBannedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannedUsersQuery, GetBannedUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBannedUsersQuery, GetBannedUsersQueryVariables>(GetBannedUsersDocument, options)
}
export type GetBannedUsersQueryHookResult = ReturnType<typeof useGetBannedUsersQuery>
export type GetBannedUsersLazyQueryHookResult = ReturnType<typeof useGetBannedUsersLazyQuery>
export type GetBannedUsersQueryResult = Apollo.QueryResult<GetBannedUsersQuery, GetBannedUsersQueryVariables>
export const CreateBlogDocument = gql`
  mutation createBlog($input: CreateBlogInput!) {
    createBlog(input: $input) {
      id
    }
  }
`
export type CreateBlogMutationFn = Apollo.MutationFunction<CreateBlogMutation, CreateBlogMutationVariables>

/**
 * __useCreateBlogMutation__
 *
 * To run a mutation, you first call `useCreateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogMutation, { data, loading, error }] = useCreateBlogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlogMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogMutation, CreateBlogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBlogMutation, CreateBlogMutationVariables>(CreateBlogDocument, options)
}
export type CreateBlogMutationHookResult = ReturnType<typeof useCreateBlogMutation>
export type CreateBlogMutationResult = Apollo.MutationResult<CreateBlogMutation>
export type CreateBlogMutationOptions = Apollo.BaseMutationOptions<CreateBlogMutation, CreateBlogMutationVariables>
export const DeleteBlogDocument = gql`
  mutation deleteBlog($input: DeleteBlogInput!) {
    deleteBlog(input: $input) {
      id
    }
  }
`
export type DeleteBlogMutationFn = Apollo.MutationFunction<DeleteBlogMutation, DeleteBlogMutationVariables>

/**
 * __useDeleteBlogMutation__
 *
 * To run a mutation, you first call `useDeleteBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogMutation, { data, loading, error }] = useDeleteBlogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBlogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogMutation, DeleteBlogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBlogMutation, DeleteBlogMutationVariables>(DeleteBlogDocument, options)
}
export type DeleteBlogMutationHookResult = ReturnType<typeof useDeleteBlogMutation>
export type DeleteBlogMutationResult = Apollo.MutationResult<DeleteBlogMutation>
export type DeleteBlogMutationOptions = Apollo.BaseMutationOptions<DeleteBlogMutation, DeleteBlogMutationVariables>
export const GetBlogDocument = gql`
  query getBlog($input: GetBlogInput!) {
    getBlog(input: $input) {
      data {
        id
        title
        content
        createdAt
        publishDate
        description
        slug
        thumbnail
        blogSeo {
          seoTitle
          seoDescription
          seoThumbnailImage
          keyword
          facebookTitle
          facebookDescription
          facebookThumbnailImage
          twitterTitle
          twitterDescription
          twitterThumbnailImage
        }
        status
        tags {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetBlogQuery__
 *
 * To run a query within a React component, call `useGetBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBlogQuery(baseOptions: Apollo.QueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options)
}
export function useGetBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options)
}
export type GetBlogQueryHookResult = ReturnType<typeof useGetBlogQuery>
export type GetBlogLazyQueryHookResult = ReturnType<typeof useGetBlogLazyQuery>
export type GetBlogQueryResult = Apollo.QueryResult<GetBlogQuery, GetBlogQueryVariables>
export const GetBlogsDocument = gql`
  query getBlogs($input: GetBlogsInput!) {
    getBlogs(input: $input) {
      data {
        id
        title
        description
        content
        slug
        createdAt
        publishDate
        tags {
          id
          name
        }
        categories {
          id
          name
        }
        createdBy {
          id
          avatar
          fullName
          email
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetBlogsQuery__
 *
 * To run a query within a React component, call `useGetBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBlogsQuery(baseOptions: Apollo.QueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options)
}
export function useGetBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options)
}
export type GetBlogsQueryHookResult = ReturnType<typeof useGetBlogsQuery>
export type GetBlogsLazyQueryHookResult = ReturnType<typeof useGetBlogsLazyQuery>
export type GetBlogsQueryResult = Apollo.QueryResult<GetBlogsQuery, GetBlogsQueryVariables>
export const GetDeletedBlogsDocument = gql`
  query getDeletedBlogs($input: GetBlogsInput!) {
    getDeletedBlogs(input: $input) {
      data {
        id
        title
        content
        createdAt
        publishDate
        slug
        createdBy {
          id
          avatar
          fullName
          email
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedBlogsQuery__
 *
 * To run a query within a React component, call `useGetDeletedBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedBlogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedBlogsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedBlogsQuery, GetDeletedBlogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedBlogsQuery, GetDeletedBlogsQueryVariables>(GetDeletedBlogsDocument, options)
}
export function useGetDeletedBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedBlogsQuery, GetDeletedBlogsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedBlogsQuery, GetDeletedBlogsQueryVariables>(GetDeletedBlogsDocument, options)
}
export type GetDeletedBlogsQueryHookResult = ReturnType<typeof useGetDeletedBlogsQuery>
export type GetDeletedBlogsLazyQueryHookResult = ReturnType<typeof useGetDeletedBlogsLazyQuery>
export type GetDeletedBlogsQueryResult = Apollo.QueryResult<GetDeletedBlogsQuery, GetDeletedBlogsQueryVariables>
export const RecoverBlogDocument = gql`
  mutation recoverBlog($input: RecoverBlogInput!) {
    recoverBlog(input: $input) {
      id
    }
  }
`
export type RecoverBlogMutationFn = Apollo.MutationFunction<RecoverBlogMutation, RecoverBlogMutationVariables>

/**
 * __useRecoverBlogMutation__
 *
 * To run a mutation, you first call `useRecoverBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverBlogMutation, { data, loading, error }] = useRecoverBlogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverBlogMutation(baseOptions?: Apollo.MutationHookOptions<RecoverBlogMutation, RecoverBlogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverBlogMutation, RecoverBlogMutationVariables>(RecoverBlogDocument, options)
}
export type RecoverBlogMutationHookResult = ReturnType<typeof useRecoverBlogMutation>
export type RecoverBlogMutationResult = Apollo.MutationResult<RecoverBlogMutation>
export type RecoverBlogMutationOptions = Apollo.BaseMutationOptions<RecoverBlogMutation, RecoverBlogMutationVariables>
export const UpdateBlogDocument = gql`
  mutation updateBlog($input: UpdateBlogInput!) {
    updateBlog(input: $input) {
      id
    }
  }
`
export type UpdateBlogMutationFn = Apollo.MutationFunction<UpdateBlogMutation, UpdateBlogMutationVariables>

/**
 * __useUpdateBlogMutation__
 *
 * To run a mutation, you first call `useUpdateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogMutation, { data, loading, error }] = useUpdateBlogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBlogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogMutation, UpdateBlogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBlogMutation, UpdateBlogMutationVariables>(UpdateBlogDocument, options)
}
export type UpdateBlogMutationHookResult = ReturnType<typeof useUpdateBlogMutation>
export type UpdateBlogMutationResult = Apollo.MutationResult<UpdateBlogMutation>
export type UpdateBlogMutationOptions = Apollo.BaseMutationOptions<UpdateBlogMutation, UpdateBlogMutationVariables>
export const CreateCategoryDocument = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
    }
  }
`
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options)
}
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>
export const DeleteCategoryDocument = gql`
  mutation deleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      id
    }
  }
`
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options)
}
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>
export const GetCategoryDocument = gql`
  query getCategory($input: GetCategoryInput!) {
    getCategory(input: $input) {
      id
      name
      slug
      description
      createdAt
      updatedAt
      parentId
    }
  }
`

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options)
}
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options)
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>
export const GetDeletedCategoriesDocument = gql`
  query getDeletedCategories($input: GetCategoriesInput!) {
    getDeletedCategories(input: $input) {
      data {
        id
        name
        slug
        description
        createdAt
        updatedAt
        children {
          id
          name
          slug
          description
          createdAt
          updatedAt
          children {
            id
            name
            slug
            description
            createdAt
            updatedAt
          }
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDeletedCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedCategoriesQuery, GetDeletedCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedCategoriesQuery, GetDeletedCategoriesQueryVariables>(GetDeletedCategoriesDocument, options)
}
export function useGetDeletedCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedCategoriesQuery, GetDeletedCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedCategoriesQuery, GetDeletedCategoriesQueryVariables>(GetDeletedCategoriesDocument, options)
}
export type GetDeletedCategoriesQueryHookResult = ReturnType<typeof useGetDeletedCategoriesQuery>
export type GetDeletedCategoriesLazyQueryHookResult = ReturnType<typeof useGetDeletedCategoriesLazyQuery>
export type GetDeletedCategoriesQueryResult = Apollo.QueryResult<GetDeletedCategoriesQuery, GetDeletedCategoriesQueryVariables>
export const GetCategoriesDocument = gql`
  query getCategories($input: GetCategoriesInput!) {
    getCategories(input: $input) {
      data {
        id
        name
        slug
        description
        createdAt
        updatedAt
        children {
          id
          name
          slug
          description
          createdAt
          updatedAt
          children {
            id
            name
            slug
            description
            createdAt
            updatedAt
          }
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options)
}
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options)
}
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>
export const RecoverCategoryDocument = gql`
  mutation recoverCategory($input: RecoverCategoryInput!) {
    recoverCategory(input: $input) {
      id
    }
  }
`
export type RecoverCategoryMutationFn = Apollo.MutationFunction<RecoverCategoryMutation, RecoverCategoryMutationVariables>

/**
 * __useRecoverCategoryMutation__
 *
 * To run a mutation, you first call `useRecoverCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverCategoryMutation, { data, loading, error }] = useRecoverCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RecoverCategoryMutation, RecoverCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverCategoryMutation, RecoverCategoryMutationVariables>(RecoverCategoryDocument, options)
}
export type RecoverCategoryMutationHookResult = ReturnType<typeof useRecoverCategoryMutation>
export type RecoverCategoryMutationResult = Apollo.MutationResult<RecoverCategoryMutation>
export type RecoverCategoryMutationOptions = Apollo.BaseMutationOptions<RecoverCategoryMutation, RecoverCategoryMutationVariables>
export const UpdateCategoryDocument = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
    }
  }
`
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options)
}
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>
export const DeleteCommentDocument = gql`
  mutation deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
    }
  }
`
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options)
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>
export const GetCommentsDocument = gql`
  query getComments($input: GetCommentsInput!) {
    getComments(input: $input) {
      data {
        id
        content
        status
        blog {
          title
          slug
        }
        user {
          avatar
          email
          fullName
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options)
}
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options)
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>
export const GetDeletedCommentsDocument = gql`
  query getDeletedComments($input: GetCommentsInput!) {
    getDeletedComments(input: $input) {
      data {
        id
        content
        status
        blog {
          title
          slug
        }
        user {
          avatar
          email
          fullName
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedCommentsQuery__
 *
 * To run a query within a React component, call `useGetDeletedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedCommentsQuery, GetDeletedCommentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedCommentsQuery, GetDeletedCommentsQueryVariables>(GetDeletedCommentsDocument, options)
}
export function useGetDeletedCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedCommentsQuery, GetDeletedCommentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedCommentsQuery, GetDeletedCommentsQueryVariables>(GetDeletedCommentsDocument, options)
}
export type GetDeletedCommentsQueryHookResult = ReturnType<typeof useGetDeletedCommentsQuery>
export type GetDeletedCommentsLazyQueryHookResult = ReturnType<typeof useGetDeletedCommentsLazyQuery>
export type GetDeletedCommentsQueryResult = Apollo.QueryResult<GetDeletedCommentsQuery, GetDeletedCommentsQueryVariables>
export const RecoverCommentDocument = gql`
  mutation recoverComment($input: RecoverCommentInput!) {
    recoverComment(input: $input) {
      id
    }
  }
`
export type RecoverCommentMutationFn = Apollo.MutationFunction<RecoverCommentMutation, RecoverCommentMutationVariables>

/**
 * __useRecoverCommentMutation__
 *
 * To run a mutation, you first call `useRecoverCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverCommentMutation, { data, loading, error }] = useRecoverCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverCommentMutation(baseOptions?: Apollo.MutationHookOptions<RecoverCommentMutation, RecoverCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverCommentMutation, RecoverCommentMutationVariables>(RecoverCommentDocument, options)
}
export type RecoverCommentMutationHookResult = ReturnType<typeof useRecoverCommentMutation>
export type RecoverCommentMutationResult = Apollo.MutationResult<RecoverCommentMutation>
export type RecoverCommentMutationOptions = Apollo.BaseMutationOptions<RecoverCommentMutation, RecoverCommentMutationVariables>
export const UpdateCommentDocument = gql`
  mutation updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
    }
  }
`
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options)
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>
export const GetFormRegistersDocument = gql`
  query getFormRegisters($input: GetFormRegisterInput!) {
    getFormRegisters(input: $input) {
      data {
        user {
          fullName
          gender
          email
          city
          phone
        }
        id
        religion
        ethnicity
        familyPosition
        relativePhoneNumber
        issueDescription
        expectResult
        expectedTime
        note
      }
    }
  }
`

/**
 * __useGetFormRegistersQuery__
 *
 * To run a query within a React component, call `useGetFormRegistersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormRegistersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormRegistersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFormRegistersQuery(baseOptions: Apollo.QueryHookOptions<GetFormRegistersQuery, GetFormRegistersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFormRegistersQuery, GetFormRegistersQueryVariables>(GetFormRegistersDocument, options)
}
export function useGetFormRegistersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormRegistersQuery, GetFormRegistersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFormRegistersQuery, GetFormRegistersQueryVariables>(GetFormRegistersDocument, options)
}
export type GetFormRegistersQueryHookResult = ReturnType<typeof useGetFormRegistersQuery>
export type GetFormRegistersLazyQueryHookResult = ReturnType<typeof useGetFormRegistersLazyQuery>
export type GetFormRegistersQueryResult = Apollo.QueryResult<GetFormRegistersQuery, GetFormRegistersQueryVariables>
export const UpdateNoteFormRegisterDocument = gql`
  mutation updateNoteFormRegister($input: UpdateNoteFormRegisterInput!) {
    updateNoteFormRegister(input: $input) {
      data {
        id
      }
    }
  }
`
export type UpdateNoteFormRegisterMutationFn = Apollo.MutationFunction<UpdateNoteFormRegisterMutation, UpdateNoteFormRegisterMutationVariables>

/**
 * __useUpdateNoteFormRegisterMutation__
 *
 * To run a mutation, you first call `useUpdateNoteFormRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteFormRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteFormRegisterMutation, { data, loading, error }] = useUpdateNoteFormRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteFormRegisterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteFormRegisterMutation, UpdateNoteFormRegisterMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateNoteFormRegisterMutation, UpdateNoteFormRegisterMutationVariables>(UpdateNoteFormRegisterDocument, options)
}
export type UpdateNoteFormRegisterMutationHookResult = ReturnType<typeof useUpdateNoteFormRegisterMutation>
export type UpdateNoteFormRegisterMutationResult = Apollo.MutationResult<UpdateNoteFormRegisterMutation>
export type UpdateNoteFormRegisterMutationOptions = Apollo.BaseMutationOptions<UpdateNoteFormRegisterMutation, UpdateNoteFormRegisterMutationVariables>
export const CreateCourseCategoryDocument = gql`
  mutation createCourseCategory($input: CreateCourseCategoryInput!) {
    createCourseCategory(input: $input) {
      id
    }
  }
`
export type CreateCourseCategoryMutationFn = Apollo.MutationFunction<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>

/**
 * __useCreateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseCategoryMutation, { data, loading, error }] = useCreateCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>(CreateCourseCategoryDocument, options)
}
export type CreateCourseCategoryMutationHookResult = ReturnType<typeof useCreateCourseCategoryMutation>
export type CreateCourseCategoryMutationResult = Apollo.MutationResult<CreateCourseCategoryMutation>
export type CreateCourseCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCourseCategoryMutation, CreateCourseCategoryMutationVariables>
export const DeleteCourseCategoryDocument = gql`
  mutation deleteCourseCategory($input: DeleteCourseCategoryInput!) {
    deleteCourseCategory(input: $input) {
      id
    }
  }
`
export type DeleteCourseCategoryMutationFn = Apollo.MutationFunction<DeleteCourseCategoryMutation, DeleteCourseCategoryMutationVariables>

/**
 * __useDeleteCourseCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseCategoryMutation, { data, loading, error }] = useDeleteCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseCategoryMutation, DeleteCourseCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCourseCategoryMutation, DeleteCourseCategoryMutationVariables>(DeleteCourseCategoryDocument, options)
}
export type DeleteCourseCategoryMutationHookResult = ReturnType<typeof useDeleteCourseCategoryMutation>
export type DeleteCourseCategoryMutationResult = Apollo.MutationResult<DeleteCourseCategoryMutation>
export type DeleteCourseCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCourseCategoryMutation, DeleteCourseCategoryMutationVariables>
export const GetCourseCategoriesDocument = gql`
  query getCourseCategories($input: GetCourseCategoriesInput!) {
    getCourseCategories(input: $input) {
      data {
        id
        name
        description
        slug
        image
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, options)
}
export function useGetCourseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>(GetCourseCategoriesDocument, options)
}
export type GetCourseCategoriesQueryHookResult = ReturnType<typeof useGetCourseCategoriesQuery>
export type GetCourseCategoriesLazyQueryHookResult = ReturnType<typeof useGetCourseCategoriesLazyQuery>
export type GetCourseCategoriesQueryResult = Apollo.QueryResult<GetCourseCategoriesQuery, GetCourseCategoriesQueryVariables>
export const GetCourseCategoryDocument = gql`
  query getCourseCategory($input: GetCourseCategoryInput!) {
    getCourseCategory(input: $input) {
      data {
        id
        name
        slug
        description
        image
      }
    }
  }
`

/**
 * __useGetCourseCategoryQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options)
}
export function useGetCourseCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options)
}
export type GetCourseCategoryQueryHookResult = ReturnType<typeof useGetCourseCategoryQuery>
export type GetCourseCategoryLazyQueryHookResult = ReturnType<typeof useGetCourseCategoryLazyQuery>
export type GetCourseCategoryQueryResult = Apollo.QueryResult<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>
export const GetDeletedCourseCategoriesDocument = gql`
  query getDeletedCourseCategories($input: GetCourseCategoriesInput!) {
    getDeletedCourseCategories(input: $input) {
      data {
        id
        name
        description
        slug
        image
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDeletedCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedCourseCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedCourseCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedCourseCategoriesQuery, GetDeletedCourseCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedCourseCategoriesQuery, GetDeletedCourseCategoriesQueryVariables>(GetDeletedCourseCategoriesDocument, options)
}
export function useGetDeletedCourseCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedCourseCategoriesQuery, GetDeletedCourseCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedCourseCategoriesQuery, GetDeletedCourseCategoriesQueryVariables>(GetDeletedCourseCategoriesDocument, options)
}
export type GetDeletedCourseCategoriesQueryHookResult = ReturnType<typeof useGetDeletedCourseCategoriesQuery>
export type GetDeletedCourseCategoriesLazyQueryHookResult = ReturnType<typeof useGetDeletedCourseCategoriesLazyQuery>
export type GetDeletedCourseCategoriesQueryResult = Apollo.QueryResult<GetDeletedCourseCategoriesQuery, GetDeletedCourseCategoriesQueryVariables>
export const RecoverCourseCategoryDocument = gql`
  mutation recoverCourseCategory($input: RecoverCourseCategoryInput!) {
    recoverCourseCategory(input: $input) {
      id
    }
  }
`
export type RecoverCourseCategoryMutationFn = Apollo.MutationFunction<RecoverCourseCategoryMutation, RecoverCourseCategoryMutationVariables>

/**
 * __useRecoverCourseCategoryMutation__
 *
 * To run a mutation, you first call `useRecoverCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverCourseCategoryMutation, { data, loading, error }] = useRecoverCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverCourseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RecoverCourseCategoryMutation, RecoverCourseCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverCourseCategoryMutation, RecoverCourseCategoryMutationVariables>(RecoverCourseCategoryDocument, options)
}
export type RecoverCourseCategoryMutationHookResult = ReturnType<typeof useRecoverCourseCategoryMutation>
export type RecoverCourseCategoryMutationResult = Apollo.MutationResult<RecoverCourseCategoryMutation>
export type RecoverCourseCategoryMutationOptions = Apollo.BaseMutationOptions<RecoverCourseCategoryMutation, RecoverCourseCategoryMutationVariables>
export const UpdateCourseCategoryDocument = gql`
  mutation updateCourseCategory($input: UpdateCourseCategoryInput!) {
    updateCourseCategory(input: $input) {
      id
    }
  }
`
export type UpdateCourseCategoryMutationFn = Apollo.MutationFunction<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>

/**
 * __useUpdateCourseCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCourseCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseCategoryMutation, { data, loading, error }] = useUpdateCourseCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>(UpdateCourseCategoryDocument, options)
}
export type UpdateCourseCategoryMutationHookResult = ReturnType<typeof useUpdateCourseCategoryMutation>
export type UpdateCourseCategoryMutationResult = Apollo.MutationResult<UpdateCourseCategoryMutation>
export type UpdateCourseCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCourseCategoryMutation, UpdateCourseCategoryMutationVariables>
export const CreateCourseDocument = gql`
  mutation createCourse($input: CreateCourseInput!) {
    createCourse(input: $input) {
      id
    }
  }
`
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options)
}
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>
export const DeleteCourseDocument = gql`
  mutation deleteCourse($input: DeleteCourseInput!) {
    deleteCourse(input: $input) {
      id
    }
  }
`
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options)
}
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>
export const GetCourseDocument = gql`
  query getCourse($input: GetCourseInput!) {
    getCourse(input: $input) {
      data {
        id
        title
        description
        thumbnail
        modules {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseQuery(baseOptions: Apollo.QueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options)
}
export function useGetCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options)
}
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>
export type GetCourseQueryResult = Apollo.QueryResult<GetCourseQuery, GetCourseQueryVariables>
export const GetCourseDetailDocument = gql`
  query getCourseDetail($input: GetCourseInput!) {
    getCourse(input: $input) {
      data {
        id
        categories {
          id
        }
        title
        slug
        description
        thumbnail
        price
        publishDate
      }
    }
  }
`

/**
 * __useGetCourseDetailQuery__
 *
 * To run a query within a React component, call `useGetCourseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCourseDetailQuery(baseOptions: Apollo.QueryHookOptions<GetCourseDetailQuery, GetCourseDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCourseDetailQuery, GetCourseDetailQueryVariables>(GetCourseDetailDocument, options)
}
export function useGetCourseDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseDetailQuery, GetCourseDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCourseDetailQuery, GetCourseDetailQueryVariables>(GetCourseDetailDocument, options)
}
export type GetCourseDetailQueryHookResult = ReturnType<typeof useGetCourseDetailQuery>
export type GetCourseDetailLazyQueryHookResult = ReturnType<typeof useGetCourseDetailLazyQuery>
export type GetCourseDetailQueryResult = Apollo.QueryResult<GetCourseDetailQuery, GetCourseDetailQueryVariables>
export const GetCoursesDocument = gql`
  query getCourses($input: GetCoursesInput!) {
    getCourses(input: $input) {
      data {
        id
        title
        slug
        description
        thumbnail
        publishDate
        price
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, options)
}
export function useGetCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, options)
}
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>
export type GetCoursesQueryResult = Apollo.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>
export const GetDeletedCoursesDocument = gql`
  query getDeletedCourses($input: GetCoursesInput!) {
    getDeletedCourses(input: $input) {
      data {
        id
        title
        slug
        description
        thumbnail
        publishDate
        price
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedCoursesQuery__
 *
 * To run a query within a React component, call `useGetDeletedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedCoursesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedCoursesQuery, GetDeletedCoursesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedCoursesQuery, GetDeletedCoursesQueryVariables>(GetDeletedCoursesDocument, options)
}
export function useGetDeletedCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedCoursesQuery, GetDeletedCoursesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedCoursesQuery, GetDeletedCoursesQueryVariables>(GetDeletedCoursesDocument, options)
}
export type GetDeletedCoursesQueryHookResult = ReturnType<typeof useGetDeletedCoursesQuery>
export type GetDeletedCoursesLazyQueryHookResult = ReturnType<typeof useGetDeletedCoursesLazyQuery>
export type GetDeletedCoursesQueryResult = Apollo.QueryResult<GetDeletedCoursesQuery, GetDeletedCoursesQueryVariables>
export const RecoverCourseDocument = gql`
  mutation recoverCourse($input: RecoverCourseInput!) {
    recoverCourse(input: $input) {
      id
    }
  }
`
export type RecoverCourseMutationFn = Apollo.MutationFunction<RecoverCourseMutation, RecoverCourseMutationVariables>

/**
 * __useRecoverCourseMutation__
 *
 * To run a mutation, you first call `useRecoverCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverCourseMutation, { data, loading, error }] = useRecoverCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverCourseMutation(baseOptions?: Apollo.MutationHookOptions<RecoverCourseMutation, RecoverCourseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverCourseMutation, RecoverCourseMutationVariables>(RecoverCourseDocument, options)
}
export type RecoverCourseMutationHookResult = ReturnType<typeof useRecoverCourseMutation>
export type RecoverCourseMutationResult = Apollo.MutationResult<RecoverCourseMutation>
export type RecoverCourseMutationOptions = Apollo.BaseMutationOptions<RecoverCourseMutation, RecoverCourseMutationVariables>
export const UpdateCourseDocument = gql`
  mutation updateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      id
    }
  }
`
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options)
}
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>
export const CreateModuleDocument = gql`
  mutation createModule($input: CreateModuleInput!) {
    createModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options)
}
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>
export const DeleteModuleDocument = gql`
  mutation deleteModule($input: DeleteModuleInput!) {
    deleteModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type DeleteModuleMutationFn = Apollo.MutationFunction<DeleteModuleMutation, DeleteModuleMutationVariables>

/**
 * __useDeleteModuleMutation__
 *
 * To run a mutation, you first call `useDeleteModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleMutation, { data, loading, error }] = useDeleteModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleMutation, DeleteModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteModuleMutation, DeleteModuleMutationVariables>(DeleteModuleDocument, options)
}
export type DeleteModuleMutationHookResult = ReturnType<typeof useDeleteModuleMutation>
export type DeleteModuleMutationResult = Apollo.MutationResult<DeleteModuleMutation>
export type DeleteModuleMutationOptions = Apollo.BaseMutationOptions<DeleteModuleMutation, DeleteModuleMutationVariables>
export const GetModuleDocument = gql`
  query getModule($input: GetModuleInput!) {
    getModule(input: $input) {
      data {
        id
        title
        description
        slug
        status
      }
    }
  }
`

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetModuleQuery(baseOptions: Apollo.QueryHookOptions<GetModuleQuery, GetModuleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetModuleQuery, GetModuleQueryVariables>(GetModuleDocument, options)
}
export function useGetModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleQuery, GetModuleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(GetModuleDocument, options)
}
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>
export type GetModuleLazyQueryHookResult = ReturnType<typeof useGetModuleLazyQuery>
export type GetModuleQueryResult = Apollo.QueryResult<GetModuleQuery, GetModuleQueryVariables>
export const GetModuleDetailDocument = gql`
  query getModuleDetail($input: GetModuleInput!) {
    getModule(input: $input) {
      data {
        id
        title
        description
        subModules {
          id
          type
          title
          description
          order
          module {
            title
          }
          createdAt
          updatedAt
          video {
            id
            title
            duration
            source
            url
          }
          richText {
            id
            title
            content
          }
        }
      }
    }
  }
`

/**
 * __useGetModuleDetailQuery__
 *
 * To run a query within a React component, call `useGetModuleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetModuleDetailQuery(baseOptions: Apollo.QueryHookOptions<GetModuleDetailQuery, GetModuleDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetModuleDetailQuery, GetModuleDetailQueryVariables>(GetModuleDetailDocument, options)
}
export function useGetModuleDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleDetailQuery, GetModuleDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetModuleDetailQuery, GetModuleDetailQueryVariables>(GetModuleDetailDocument, options)
}
export type GetModuleDetailQueryHookResult = ReturnType<typeof useGetModuleDetailQuery>
export type GetModuleDetailLazyQueryHookResult = ReturnType<typeof useGetModuleDetailLazyQuery>
export type GetModuleDetailQueryResult = Apollo.QueryResult<GetModuleDetailQuery, GetModuleDetailQueryVariables>
export const GetModulesDocument = gql`
  query getModules($input: GetModulesInput!) {
    getModules(input: $input) {
      data {
        id
        title
        description
        slug
        status
      }
    }
  }
`

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetModulesQuery(baseOptions: Apollo.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options)
}
export function useGetModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options)
}
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>
export const UpdateModuleDocument = gql`
  mutation updateModule($input: UpdateModuleInput!) {
    updateModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options)
}
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>
export const CreateSubModuleDocument = gql`
  mutation createSubModule($input: CreateSubModuleInput!) {
    createSubModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type CreateSubModuleMutationFn = Apollo.MutationFunction<CreateSubModuleMutation, CreateSubModuleMutationVariables>

/**
 * __useCreateSubModuleMutation__
 *
 * To run a mutation, you first call `useCreateSubModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubModuleMutation, { data, loading, error }] = useCreateSubModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubModuleMutation, CreateSubModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSubModuleMutation, CreateSubModuleMutationVariables>(CreateSubModuleDocument, options)
}
export type CreateSubModuleMutationHookResult = ReturnType<typeof useCreateSubModuleMutation>
export type CreateSubModuleMutationResult = Apollo.MutationResult<CreateSubModuleMutation>
export type CreateSubModuleMutationOptions = Apollo.BaseMutationOptions<CreateSubModuleMutation, CreateSubModuleMutationVariables>
export const DeleteSubModuleDocument = gql`
  mutation deleteSubModule($input: DeleteSubModuleInput!) {
    deleteSubModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type DeleteSubModuleMutationFn = Apollo.MutationFunction<DeleteSubModuleMutation, DeleteSubModuleMutationVariables>

/**
 * __useDeleteSubModuleMutation__
 *
 * To run a mutation, you first call `useDeleteSubModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubModuleMutation, { data, loading, error }] = useDeleteSubModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubModuleMutation, DeleteSubModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSubModuleMutation, DeleteSubModuleMutationVariables>(DeleteSubModuleDocument, options)
}
export type DeleteSubModuleMutationHookResult = ReturnType<typeof useDeleteSubModuleMutation>
export type DeleteSubModuleMutationResult = Apollo.MutationResult<DeleteSubModuleMutation>
export type DeleteSubModuleMutationOptions = Apollo.BaseMutationOptions<DeleteSubModuleMutation, DeleteSubModuleMutationVariables>
export const GetSubModuleDocument = gql`
  query getSubModule($input: GetSubModuleInput!) {
    getSubModule(input: $input) {
      data {
        id
        title
        slug
        description
        type
        order
        moduleId
        richText {
          id
          title
          content
        }
        video {
          id
          title
          duration
          source
          url
        }
      }
    }
  }
`

/**
 * __useGetSubModuleQuery__
 *
 * To run a query within a React component, call `useGetSubModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubModuleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubModuleQuery(baseOptions: Apollo.QueryHookOptions<GetSubModuleQuery, GetSubModuleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSubModuleQuery, GetSubModuleQueryVariables>(GetSubModuleDocument, options)
}
export function useGetSubModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubModuleQuery, GetSubModuleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSubModuleQuery, GetSubModuleQueryVariables>(GetSubModuleDocument, options)
}
export type GetSubModuleQueryHookResult = ReturnType<typeof useGetSubModuleQuery>
export type GetSubModuleLazyQueryHookResult = ReturnType<typeof useGetSubModuleLazyQuery>
export type GetSubModuleQueryResult = Apollo.QueryResult<GetSubModuleQuery, GetSubModuleQueryVariables>
export const RecoverSubModuleDocument = gql`
  mutation recoverSubModule($input: RecoverSubModuleInput!) {
    recoverSubModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type RecoverSubModuleMutationFn = Apollo.MutationFunction<RecoverSubModuleMutation, RecoverSubModuleMutationVariables>

/**
 * __useRecoverSubModuleMutation__
 *
 * To run a mutation, you first call `useRecoverSubModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverSubModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverSubModuleMutation, { data, loading, error }] = useRecoverSubModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverSubModuleMutation(baseOptions?: Apollo.MutationHookOptions<RecoverSubModuleMutation, RecoverSubModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverSubModuleMutation, RecoverSubModuleMutationVariables>(RecoverSubModuleDocument, options)
}
export type RecoverSubModuleMutationHookResult = ReturnType<typeof useRecoverSubModuleMutation>
export type RecoverSubModuleMutationResult = Apollo.MutationResult<RecoverSubModuleMutation>
export type RecoverSubModuleMutationOptions = Apollo.BaseMutationOptions<RecoverSubModuleMutation, RecoverSubModuleMutationVariables>
export const UpdateSubModuleDocument = gql`
  mutation updateSubModule($input: UpdateSubModuleInput!) {
    updateSubModule(input: $input) {
      data {
        id
      }
    }
  }
`
export type UpdateSubModuleMutationFn = Apollo.MutationFunction<UpdateSubModuleMutation, UpdateSubModuleMutationVariables>

/**
 * __useUpdateSubModuleMutation__
 *
 * To run a mutation, you first call `useUpdateSubModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubModuleMutation, { data, loading, error }] = useUpdateSubModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubModuleMutation, UpdateSubModuleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSubModuleMutation, UpdateSubModuleMutationVariables>(UpdateSubModuleDocument, options)
}
export type UpdateSubModuleMutationHookResult = ReturnType<typeof useUpdateSubModuleMutation>
export type UpdateSubModuleMutationResult = Apollo.MutationResult<UpdateSubModuleMutation>
export type UpdateSubModuleMutationOptions = Apollo.BaseMutationOptions<UpdateSubModuleMutation, UpdateSubModuleMutationVariables>
export const DeleteListenerRegisterDocument = gql`
  mutation deleteListenerRegister($input: DeleteListenerRegisterInput!) {
    deleteListenerRegister(input: $input) {
      id
    }
  }
`
export type DeleteListenerRegisterMutationFn = Apollo.MutationFunction<DeleteListenerRegisterMutation, DeleteListenerRegisterMutationVariables>

/**
 * __useDeleteListenerRegisterMutation__
 *
 * To run a mutation, you first call `useDeleteListenerRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListenerRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListenerRegisterMutation, { data, loading, error }] = useDeleteListenerRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteListenerRegisterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteListenerRegisterMutation, DeleteListenerRegisterMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteListenerRegisterMutation, DeleteListenerRegisterMutationVariables>(DeleteListenerRegisterDocument, options)
}
export type DeleteListenerRegisterMutationHookResult = ReturnType<typeof useDeleteListenerRegisterMutation>
export type DeleteListenerRegisterMutationResult = Apollo.MutationResult<DeleteListenerRegisterMutation>
export type DeleteListenerRegisterMutationOptions = Apollo.BaseMutationOptions<DeleteListenerRegisterMutation, DeleteListenerRegisterMutationVariables>
export const GetDeletedListenersRegisterDocument = gql`
  query getDeletedListenersRegister($input: GetDeletedListenersRegisterInput!) {
    getDeletedListenersRegister(input: $input) {
      data {
        adsSource
        id
        reason
        status
        updatedAt
        createdAt
        user {
          fullName
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedListenersRegisterQuery__
 *
 * To run a query within a React component, call `useGetDeletedListenersRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedListenersRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedListenersRegisterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedListenersRegisterQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedListenersRegisterQuery, GetDeletedListenersRegisterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedListenersRegisterQuery, GetDeletedListenersRegisterQueryVariables>(GetDeletedListenersRegisterDocument, options)
}
export function useGetDeletedListenersRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedListenersRegisterQuery, GetDeletedListenersRegisterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedListenersRegisterQuery, GetDeletedListenersRegisterQueryVariables>(GetDeletedListenersRegisterDocument, options)
}
export type GetDeletedListenersRegisterQueryHookResult = ReturnType<typeof useGetDeletedListenersRegisterQuery>
export type GetDeletedListenersRegisterLazyQueryHookResult = ReturnType<typeof useGetDeletedListenersRegisterLazyQuery>
export type GetDeletedListenersRegisterQueryResult = Apollo.QueryResult<GetDeletedListenersRegisterQuery, GetDeletedListenersRegisterQueryVariables>
export const GetListenersRegisterDocument = gql`
  query getListenersRegister($input: GetListenersRegisterInput!) {
    getListenersRegister(input: $input) {
      data {
        adsSource
        id
        reason
        status
        updatedAt
        createdBy
        createdAt
        user {
          id
          email
          fullName
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetListenersRegisterQuery__
 *
 * To run a query within a React component, call `useGetListenersRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListenersRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListenersRegisterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListenersRegisterQuery(baseOptions: Apollo.QueryHookOptions<GetListenersRegisterQuery, GetListenersRegisterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetListenersRegisterQuery, GetListenersRegisterQueryVariables>(GetListenersRegisterDocument, options)
}
export function useGetListenersRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListenersRegisterQuery, GetListenersRegisterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetListenersRegisterQuery, GetListenersRegisterQueryVariables>(GetListenersRegisterDocument, options)
}
export type GetListenersRegisterQueryHookResult = ReturnType<typeof useGetListenersRegisterQuery>
export type GetListenersRegisterLazyQueryHookResult = ReturnType<typeof useGetListenersRegisterLazyQuery>
export type GetListenersRegisterQueryResult = Apollo.QueryResult<GetListenersRegisterQuery, GetListenersRegisterQueryVariables>
export const RecoverListenerRegisterDocument = gql`
  mutation recoverListenerRegister($input: RecoverListenerRegisterInput!) {
    recoverListenerRegister(input: $input) {
      id
    }
  }
`
export type RecoverListenerRegisterMutationFn = Apollo.MutationFunction<RecoverListenerRegisterMutation, RecoverListenerRegisterMutationVariables>

/**
 * __useRecoverListenerRegisterMutation__
 *
 * To run a mutation, you first call `useRecoverListenerRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverListenerRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverListenerRegisterMutation, { data, loading, error }] = useRecoverListenerRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverListenerRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RecoverListenerRegisterMutation, RecoverListenerRegisterMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverListenerRegisterMutation, RecoverListenerRegisterMutationVariables>(RecoverListenerRegisterDocument, options)
}
export type RecoverListenerRegisterMutationHookResult = ReturnType<typeof useRecoverListenerRegisterMutation>
export type RecoverListenerRegisterMutationResult = Apollo.MutationResult<RecoverListenerRegisterMutation>
export type RecoverListenerRegisterMutationOptions = Apollo.BaseMutationOptions<RecoverListenerRegisterMutation, RecoverListenerRegisterMutationVariables>
export const UpdateListenerRegisterDocument = gql`
  mutation updateListenerRegister($input: UpdateListenerRegisterInput!) {
    updateListenerRegister(input: $input) {
      id
    }
  }
`
export type UpdateListenerRegisterMutationFn = Apollo.MutationFunction<UpdateListenerRegisterMutation, UpdateListenerRegisterMutationVariables>

/**
 * __useUpdateListenerRegisterMutation__
 *
 * To run a mutation, you first call `useUpdateListenerRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListenerRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListenerRegisterMutation, { data, loading, error }] = useUpdateListenerRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListenerRegisterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateListenerRegisterMutation, UpdateListenerRegisterMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateListenerRegisterMutation, UpdateListenerRegisterMutationVariables>(UpdateListenerRegisterDocument, options)
}
export type UpdateListenerRegisterMutationHookResult = ReturnType<typeof useUpdateListenerRegisterMutation>
export type UpdateListenerRegisterMutationResult = Apollo.MutationResult<UpdateListenerRegisterMutation>
export type UpdateListenerRegisterMutationOptions = Apollo.BaseMutationOptions<UpdateListenerRegisterMutation, UpdateListenerRegisterMutationVariables>
export const DeleteReportTicketDocument = gql`
  mutation deleteReportTicket($input: DeleteReportTicketInput!) {
    deleteReportTicket(input: $input) {
      id
    }
  }
`
export type DeleteReportTicketMutationFn = Apollo.MutationFunction<DeleteReportTicketMutation, DeleteReportTicketMutationVariables>

/**
 * __useDeleteReportTicketMutation__
 *
 * To run a mutation, you first call `useDeleteReportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportTicketMutation, { data, loading, error }] = useDeleteReportTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReportTicketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportTicketMutation, DeleteReportTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteReportTicketMutation, DeleteReportTicketMutationVariables>(DeleteReportTicketDocument, options)
}
export type DeleteReportTicketMutationHookResult = ReturnType<typeof useDeleteReportTicketMutation>
export type DeleteReportTicketMutationResult = Apollo.MutationResult<DeleteReportTicketMutation>
export type DeleteReportTicketMutationOptions = Apollo.BaseMutationOptions<DeleteReportTicketMutation, DeleteReportTicketMutationVariables>
export const GetDeletedReportTicketsDocument = gql`
  query getDeletedReportTickets($input: GetReportTicketsInput!) {
    getDeletedReportTickets(input: $input) {
      data {
        id
        subject
        description
        status
        reportedUser {
          id
          fullName
          email
          avatar
        }
        createdBy {
          id
          fullName
          email
          avatar
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedReportTicketsQuery__
 *
 * To run a query within a React component, call `useGetDeletedReportTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedReportTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedReportTicketsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedReportTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedReportTicketsQuery, GetDeletedReportTicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedReportTicketsQuery, GetDeletedReportTicketsQueryVariables>(GetDeletedReportTicketsDocument, options)
}
export function useGetDeletedReportTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedReportTicketsQuery, GetDeletedReportTicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedReportTicketsQuery, GetDeletedReportTicketsQueryVariables>(GetDeletedReportTicketsDocument, options)
}
export type GetDeletedReportTicketsQueryHookResult = ReturnType<typeof useGetDeletedReportTicketsQuery>
export type GetDeletedReportTicketsLazyQueryHookResult = ReturnType<typeof useGetDeletedReportTicketsLazyQuery>
export type GetDeletedReportTicketsQueryResult = Apollo.QueryResult<GetDeletedReportTicketsQuery, GetDeletedReportTicketsQueryVariables>
export const GetReportTicketsDocument = gql`
  query getReportTickets($input: GetReportTicketsInput!) {
    getReportTickets(input: $input) {
      data {
        id
        subject
        description
        status
        reportedUser {
          id
          fullName
          email
          avatar
        }
        createdBy {
          id
          fullName
          email
          avatar
        }
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetReportTicketsQuery__
 *
 * To run a query within a React component, call `useGetReportTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportTicketsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetReportTicketsQuery, GetReportTicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportTicketsQuery, GetReportTicketsQueryVariables>(GetReportTicketsDocument, options)
}
export function useGetReportTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportTicketsQuery, GetReportTicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportTicketsQuery, GetReportTicketsQueryVariables>(GetReportTicketsDocument, options)
}
export type GetReportTicketsQueryHookResult = ReturnType<typeof useGetReportTicketsQuery>
export type GetReportTicketsLazyQueryHookResult = ReturnType<typeof useGetReportTicketsLazyQuery>
export type GetReportTicketsQueryResult = Apollo.QueryResult<GetReportTicketsQuery, GetReportTicketsQueryVariables>
export const RecoverReportTicketDocument = gql`
  mutation recoverReportTicket($input: RecoverReportTicketInput!) {
    recoverReportTicket(input: $input) {
      id
    }
  }
`
export type RecoverReportTicketMutationFn = Apollo.MutationFunction<RecoverReportTicketMutation, RecoverReportTicketMutationVariables>

/**
 * __useRecoverReportTicketMutation__
 *
 * To run a mutation, you first call `useRecoverReportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverReportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverReportTicketMutation, { data, loading, error }] = useRecoverReportTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverReportTicketMutation(baseOptions?: Apollo.MutationHookOptions<RecoverReportTicketMutation, RecoverReportTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverReportTicketMutation, RecoverReportTicketMutationVariables>(RecoverReportTicketDocument, options)
}
export type RecoverReportTicketMutationHookResult = ReturnType<typeof useRecoverReportTicketMutation>
export type RecoverReportTicketMutationResult = Apollo.MutationResult<RecoverReportTicketMutation>
export type RecoverReportTicketMutationOptions = Apollo.BaseMutationOptions<RecoverReportTicketMutation, RecoverReportTicketMutationVariables>
export const UpdateReportTicketDocument = gql`
  mutation updateReportTicket($input: UpdateReportTicketInput!) {
    updateReportTicket(input: $input) {
      id
    }
  }
`
export type UpdateReportTicketMutationFn = Apollo.MutationFunction<UpdateReportTicketMutation, UpdateReportTicketMutationVariables>

/**
 * __useUpdateReportTicketMutation__
 *
 * To run a mutation, you first call `useUpdateReportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportTicketMutation, { data, loading, error }] = useUpdateReportTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportTicketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportTicketMutation, UpdateReportTicketMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateReportTicketMutation, UpdateReportTicketMutationVariables>(UpdateReportTicketDocument, options)
}
export type UpdateReportTicketMutationHookResult = ReturnType<typeof useUpdateReportTicketMutation>
export type UpdateReportTicketMutationResult = Apollo.MutationResult<UpdateReportTicketMutation>
export type UpdateReportTicketMutationOptions = Apollo.BaseMutationOptions<UpdateReportTicketMutation, UpdateReportTicketMutationVariables>
export const CreateRoleDocument = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      id
    }
  }
`
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options)
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>
export const DeleteRoleDocument = gql`
  mutation deleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      id
    }
  }
`
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options)
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>
export const GetDeletedRolesDocument = gql`
  query getDeletedRoles {
    getDeletedRoles {
      id
      title
      isDefault
      scp
    }
  }
`

/**
 * __useGetDeletedRolesQuery__
 *
 * To run a query within a React component, call `useGetDeletedRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeletedRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetDeletedRolesQuery, GetDeletedRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedRolesQuery, GetDeletedRolesQueryVariables>(GetDeletedRolesDocument, options)
}
export function useGetDeletedRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedRolesQuery, GetDeletedRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedRolesQuery, GetDeletedRolesQueryVariables>(GetDeletedRolesDocument, options)
}
export type GetDeletedRolesQueryHookResult = ReturnType<typeof useGetDeletedRolesQuery>
export type GetDeletedRolesLazyQueryHookResult = ReturnType<typeof useGetDeletedRolesLazyQuery>
export type GetDeletedRolesQueryResult = Apollo.QueryResult<GetDeletedRolesQuery, GetDeletedRolesQueryVariables>
export const PermissionsDocument = gql`
  query permissions {
    __schema {
      queryType {
        fields {
          name
          description
        }
      }
      mutationType {
        fields {
          name
          description
        }
      }
      subscriptionType {
        fields {
          name
          description
        }
      }
    }
  }
`

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options)
}
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options)
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>
export const GetRoleDocument = gql`
  query getRole($input: GetRoleInput!) {
    getRole(input: $input) {
      id
      title
      isDefault
      scp
      code
    }
  }
`

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options)
}
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options)
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>
export const GetRolesDocument = gql`
  query getRoles {
    getRoles {
      id
      title
      isDefault
      scp
      code
    }
  }
`

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options)
}
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options)
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>
export const RecoverRoleDocument = gql`
  mutation recoverRole($input: RecoverRoleInput!) {
    recoverRole(input: $input) {
      id
    }
  }
`
export type RecoverRoleMutationFn = Apollo.MutationFunction<RecoverRoleMutation, RecoverRoleMutationVariables>

/**
 * __useRecoverRoleMutation__
 *
 * To run a mutation, you first call `useRecoverRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverRoleMutation, { data, loading, error }] = useRecoverRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverRoleMutation(baseOptions?: Apollo.MutationHookOptions<RecoverRoleMutation, RecoverRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverRoleMutation, RecoverRoleMutationVariables>(RecoverRoleDocument, options)
}
export type RecoverRoleMutationHookResult = ReturnType<typeof useRecoverRoleMutation>
export type RecoverRoleMutationResult = Apollo.MutationResult<RecoverRoleMutation>
export type RecoverRoleMutationOptions = Apollo.BaseMutationOptions<RecoverRoleMutation, RecoverRoleMutationVariables>
export const UpdateRoleDocument = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      id
    }
  }
`
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options)
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>
export const CreateRoomInvitationDocument = gql`
  mutation createRoomInvitation($input: CreateRoomInvitationInput!) {
    createRoomInvitation(input: $input) {
      data {
        id
      }
    }
  }
`
export type CreateRoomInvitationMutationFn = Apollo.MutationFunction<CreateRoomInvitationMutation, CreateRoomInvitationMutationVariables>

/**
 * __useCreateRoomInvitationMutation__
 *
 * To run a mutation, you first call `useCreateRoomInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomInvitationMutation, { data, loading, error }] = useCreateRoomInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoomInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoomInvitationMutation, CreateRoomInvitationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateRoomInvitationMutation, CreateRoomInvitationMutationVariables>(CreateRoomInvitationDocument, options)
}
export type CreateRoomInvitationMutationHookResult = ReturnType<typeof useCreateRoomInvitationMutation>
export type CreateRoomInvitationMutationResult = Apollo.MutationResult<CreateRoomInvitationMutation>
export type CreateRoomInvitationMutationOptions = Apollo.BaseMutationOptions<CreateRoomInvitationMutation, CreateRoomInvitationMutationVariables>
export const DeleteRoomInvitationDocument = gql`
  mutation deleteRoomInvitation($input: DeleteRoomInvitationInput!) {
    deleteRoomInvitation(input: $input) {
      data {
        id
        participants {
          id
          email
        }
        ownerRoomId
        title
        slug
        deleted
        deletedBy
      }
    }
  }
`
export type DeleteRoomInvitationMutationFn = Apollo.MutationFunction<DeleteRoomInvitationMutation, DeleteRoomInvitationMutationVariables>

/**
 * __useDeleteRoomInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteRoomInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomInvitationMutation, { data, loading, error }] = useDeleteRoomInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoomInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoomInvitationMutation, DeleteRoomInvitationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRoomInvitationMutation, DeleteRoomInvitationMutationVariables>(DeleteRoomInvitationDocument, options)
}
export type DeleteRoomInvitationMutationHookResult = ReturnType<typeof useDeleteRoomInvitationMutation>
export type DeleteRoomInvitationMutationResult = Apollo.MutationResult<DeleteRoomInvitationMutation>
export type DeleteRoomInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteRoomInvitationMutation, DeleteRoomInvitationMutationVariables>
export const GetDeletedRoomInvitationsDocument = gql`
  query getDeletedRoomInvitations($input: GetRoomInvitationsInput!) {
    getDeletedRoomInvitations(input: $input) {
      data {
        id
        participants {
          id
          email
          fullName
        }
        ownerRoom {
          id
          email
          fullName
        }
        title
        slug
        roomType
        startTime
        createdBy {
          email
          fullName
        }
        createdAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedRoomInvitationsQuery__
 *
 * To run a query within a React component, call `useGetDeletedRoomInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedRoomInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedRoomInvitationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedRoomInvitationsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedRoomInvitationsQuery, GetDeletedRoomInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedRoomInvitationsQuery, GetDeletedRoomInvitationsQueryVariables>(GetDeletedRoomInvitationsDocument, options)
}
export function useGetDeletedRoomInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedRoomInvitationsQuery, GetDeletedRoomInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedRoomInvitationsQuery, GetDeletedRoomInvitationsQueryVariables>(GetDeletedRoomInvitationsDocument, options)
}
export type GetDeletedRoomInvitationsQueryHookResult = ReturnType<typeof useGetDeletedRoomInvitationsQuery>
export type GetDeletedRoomInvitationsLazyQueryHookResult = ReturnType<typeof useGetDeletedRoomInvitationsLazyQuery>
export type GetDeletedRoomInvitationsQueryResult = Apollo.QueryResult<GetDeletedRoomInvitationsQuery, GetDeletedRoomInvitationsQueryVariables>
export const GetRoomInvitationDocument = gql`
  query getRoomInvitation($input: GetRoomInvitationInput!) {
    getRoomInvitation(input: $input) {
      data {
        id
        participants {
          id
          email
          fullName
        }
        ownerRoomId
        title
        slug
        roomType
        startTime
        createdBy {
          email
          fullName
        }
        createdAt
      }
    }
  }
`

/**
 * __useGetRoomInvitationQuery__
 *
 * To run a query within a React component, call `useGetRoomInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomInvitationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoomInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetRoomInvitationQuery, GetRoomInvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRoomInvitationQuery, GetRoomInvitationQueryVariables>(GetRoomInvitationDocument, options)
}
export function useGetRoomInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomInvitationQuery, GetRoomInvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRoomInvitationQuery, GetRoomInvitationQueryVariables>(GetRoomInvitationDocument, options)
}
export type GetRoomInvitationQueryHookResult = ReturnType<typeof useGetRoomInvitationQuery>
export type GetRoomInvitationLazyQueryHookResult = ReturnType<typeof useGetRoomInvitationLazyQuery>
export type GetRoomInvitationQueryResult = Apollo.QueryResult<GetRoomInvitationQuery, GetRoomInvitationQueryVariables>
export const GetRoomInvitationsDocument = gql`
  query getRoomInvitations($input: GetRoomInvitationsInput!) {
    getRoomInvitations(input: $input) {
      data {
        id
        participants {
          id
          email
          fullName
        }
        ownerRoom {
          id
          email
          fullName
        }
        title
        slug
        roomType
        startTime
        createdBy {
          email
          fullName
        }
        createdAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetRoomInvitationsQuery__
 *
 * To run a query within a React component, call `useGetRoomInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomInvitationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoomInvitationsQuery(baseOptions: Apollo.QueryHookOptions<GetRoomInvitationsQuery, GetRoomInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRoomInvitationsQuery, GetRoomInvitationsQueryVariables>(GetRoomInvitationsDocument, options)
}
export function useGetRoomInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomInvitationsQuery, GetRoomInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRoomInvitationsQuery, GetRoomInvitationsQueryVariables>(GetRoomInvitationsDocument, options)
}
export type GetRoomInvitationsQueryHookResult = ReturnType<typeof useGetRoomInvitationsQuery>
export type GetRoomInvitationsLazyQueryHookResult = ReturnType<typeof useGetRoomInvitationsLazyQuery>
export type GetRoomInvitationsQueryResult = Apollo.QueryResult<GetRoomInvitationsQuery, GetRoomInvitationsQueryVariables>
export const RecoverRoomInvitationDocument = gql`
  mutation recoverRoomInvitation($input: RecoverRoomInvitationInput!) {
    recoverRoomInvitation(input: $input) {
      data {
        id
        participants {
          id
          email
        }
        ownerRoomId
        title
        slug
        deleted
        deletedBy
      }
    }
  }
`
export type RecoverRoomInvitationMutationFn = Apollo.MutationFunction<RecoverRoomInvitationMutation, RecoverRoomInvitationMutationVariables>

/**
 * __useRecoverRoomInvitationMutation__
 *
 * To run a mutation, you first call `useRecoverRoomInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverRoomInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverRoomInvitationMutation, { data, loading, error }] = useRecoverRoomInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverRoomInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RecoverRoomInvitationMutation, RecoverRoomInvitationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverRoomInvitationMutation, RecoverRoomInvitationMutationVariables>(RecoverRoomInvitationDocument, options)
}
export type RecoverRoomInvitationMutationHookResult = ReturnType<typeof useRecoverRoomInvitationMutation>
export type RecoverRoomInvitationMutationResult = Apollo.MutationResult<RecoverRoomInvitationMutation>
export type RecoverRoomInvitationMutationOptions = Apollo.BaseMutationOptions<RecoverRoomInvitationMutation, RecoverRoomInvitationMutationVariables>
export const UpdateRoomInvitationDocument = gql`
  mutation updateRoomInvitation($input: UpdateRoomInvitationInput!) {
    updateRoomInvitation(input: $input) {
      data {
        id
        participants {
          id
          email
        }
        ownerRoomId
        title
        slug
      }
    }
  }
`
export type UpdateRoomInvitationMutationFn = Apollo.MutationFunction<UpdateRoomInvitationMutation, UpdateRoomInvitationMutationVariables>

/**
 * __useUpdateRoomInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateRoomInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomInvitationMutation, { data, loading, error }] = useUpdateRoomInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoomInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomInvitationMutation, UpdateRoomInvitationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRoomInvitationMutation, UpdateRoomInvitationMutationVariables>(UpdateRoomInvitationDocument, options)
}
export type UpdateRoomInvitationMutationHookResult = ReturnType<typeof useUpdateRoomInvitationMutation>
export type UpdateRoomInvitationMutationResult = Apollo.MutationResult<UpdateRoomInvitationMutation>
export type UpdateRoomInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateRoomInvitationMutation, UpdateRoomInvitationMutationVariables>
export const CreateTagDocument = gql`
  mutation createTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
    }
  }
`
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options)
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>
export const DeleteTagDocument = gql`
  mutation deleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
    }
  }
`
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options)
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>
export const GetDeletedTagsDocument = gql`
  query getDeletedTags($input: GetTagsInput!) {
    getDeletedTags(input: $input) {
      data {
        id
        name
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedTagsQuery__
 *
 * To run a query within a React component, call `useGetDeletedTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedTagsQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedTagsQuery, GetDeletedTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedTagsQuery, GetDeletedTagsQueryVariables>(GetDeletedTagsDocument, options)
}
export function useGetDeletedTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedTagsQuery, GetDeletedTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedTagsQuery, GetDeletedTagsQueryVariables>(GetDeletedTagsDocument, options)
}
export type GetDeletedTagsQueryHookResult = ReturnType<typeof useGetDeletedTagsQuery>
export type GetDeletedTagsLazyQueryHookResult = ReturnType<typeof useGetDeletedTagsLazyQuery>
export type GetDeletedTagsQueryResult = Apollo.QueryResult<GetDeletedTagsQuery, GetDeletedTagsQueryVariables>
export const GetTagDocument = gql`
  query getTag($input: GetTagInput!) {
    getTag(input: $input) {
      id
      name
    }
  }
`

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions: Apollo.QueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options)
}
export function useGetTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options)
}
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>
export type GetTagQueryResult = Apollo.QueryResult<GetTagQuery, GetTagQueryVariables>
export const GetTagsDocument = gql`
  query getTags($input: GetTagsInput!) {
    getTags(input: $input) {
      data {
        id
        name
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options)
}
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options)
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>
export const RecoverTagDocument = gql`
  mutation recoverTag($input: RecoverTagInput!) {
    recoverTag(input: $input) {
      id
    }
  }
`
export type RecoverTagMutationFn = Apollo.MutationFunction<RecoverTagMutation, RecoverTagMutationVariables>

/**
 * __useRecoverTagMutation__
 *
 * To run a mutation, you first call `useRecoverTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverTagMutation, { data, loading, error }] = useRecoverTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverTagMutation(baseOptions?: Apollo.MutationHookOptions<RecoverTagMutation, RecoverTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverTagMutation, RecoverTagMutationVariables>(RecoverTagDocument, options)
}
export type RecoverTagMutationHookResult = ReturnType<typeof useRecoverTagMutation>
export type RecoverTagMutationResult = Apollo.MutationResult<RecoverTagMutation>
export type RecoverTagMutationOptions = Apollo.BaseMutationOptions<RecoverTagMutation, RecoverTagMutationVariables>
export const UpdateTagDocument = gql`
  mutation updateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
    }
  }
`
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options)
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>
export const DeleteUserDocument = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options)
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>
export const GetDeletedUsersDocument = gql`
  query getDeletedUsers($input: GetUsersInput!) {
    getDeletedUsers(input: $input) {
      data {
        id
        email
        fullName
        roles {
          id
          title
        }
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetDeletedUsersQuery__
 *
 * To run a query within a React component, call `useGetDeletedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeletedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetDeletedUsersQuery, GetDeletedUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeletedUsersQuery, GetDeletedUsersQueryVariables>(GetDeletedUsersDocument, options)
}
export function useGetDeletedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedUsersQuery, GetDeletedUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeletedUsersQuery, GetDeletedUsersQueryVariables>(GetDeletedUsersDocument, options)
}
export type GetDeletedUsersQueryHookResult = ReturnType<typeof useGetDeletedUsersQuery>
export type GetDeletedUsersLazyQueryHookResult = ReturnType<typeof useGetDeletedUsersLazyQuery>
export type GetDeletedUsersQueryResult = Apollo.QueryResult<GetDeletedUsersQuery, GetDeletedUsersQueryVariables>
export const GetUserDocument = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      data {
        id
        email
        fullName
        roles {
          id
          title
        }
      }
    }
  }
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options)
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options)
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>
export const GetUsersDocument = gql`
  query getUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      data {
        id
        email
        fullName
        roles {
          id
          title
        }
        createdAt
        updatedAt
      }
      pagination {
        limit
        page
        totalCount
      }
    }
  }
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options)
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options)
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>
export const RecoverUserDocument = gql`
  mutation recoverUser($input: RecoverUserInput!) {
    recoverUser(input: $input) {
      id
    }
  }
`
export type RecoverUserMutationFn = Apollo.MutationFunction<RecoverUserMutation, RecoverUserMutationVariables>

/**
 * __useRecoverUserMutation__
 *
 * To run a mutation, you first call `useRecoverUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverUserMutation, { data, loading, error }] = useRecoverUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecoverUserMutation(baseOptions?: Apollo.MutationHookOptions<RecoverUserMutation, RecoverUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RecoverUserMutation, RecoverUserMutationVariables>(RecoverUserDocument, options)
}
export type RecoverUserMutationHookResult = ReturnType<typeof useRecoverUserMutation>
export type RecoverUserMutationResult = Apollo.MutationResult<RecoverUserMutation>
export type RecoverUserMutationOptions = Apollo.BaseMutationOptions<RecoverUserMutation, RecoverUserMutationVariables>
export const UpdateUserByAdminDocument = gql`
  mutation updateUserByAdmin($input: UpdateUserByAdminInput!) {
    updateUserByAdmin(input: $input) {
      id
    }
  }
`
export type UpdateUserByAdminMutationFn = Apollo.MutationFunction<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>

/**
 * __useUpdateUserByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByAdminMutation, { data, loading, error }] = useUpdateUserByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserByAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>(UpdateUserByAdminDocument, options)
}
export type UpdateUserByAdminMutationHookResult = ReturnType<typeof useUpdateUserByAdminMutation>
export type UpdateUserByAdminMutationResult = Apollo.MutationResult<UpdateUserByAdminMutation>
export type UpdateUserByAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>
